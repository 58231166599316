// Creating a simple Modal Directive
angular.module("mineXplore")
  .directive('modalDocumentView', ['$uibModal',
    function ($uibModal) {
      return {
        restrict: 'AE',
        controller: 'ModalDocumentViewController',

        scope: {
          url: "@url",
          title: "@title",
        },
        link: function (scope, element, attributes) {
          element.bind('click', function (e) {
            console.log('click e');
            console.log(e);
            scope.open_modal(scope.url);
          });
        }
      }
    }
  ])
//   Restrict allowed values
// E for Element name
// A for Attribute
// C for Class
// M for Comment

// ‘@’ – Text binding / one - way binding
// ‘=’ – Direct model binding / two - way binding
// ‘&’ – Behavior binding / Method binding

