angular.module('mineXplore')
  .factory("ProcessDocument", ['$resource', 'config', function ($resource, config) {
    var obj = $resource(config.apiUrl + config.baseUrl + "/user/projects/:project_id/dashboard/monitored_area/:monitored_area_id/:number/:year/documents/:id.json",
      { id: "@id", project_id: "@project_id", monitored_area_id: "@monitored_area_id", year: '@year', number: '@number' },
      {
        get: { method: 'GET', isArray: false },
      }
    );

    obj.prototype.destroy = function (cb) {
      return Process.remove({
        id: this.id
      }, cb);
    };


    return obj;
  }
  ]);
