'use strict';
angular.module('mineXplore')
  .controller('GlobalTeamManagmentController',
    ['$scope',
      '$uibModal',
      'Members',
      'EnterpriseHeader',
      'Notification',
      function ($scope, $uibModal, Members, Header, Notification) {
        console.log('GlobalTeamManagmentController...');
        $scope.new_user = { privileges: {} };


        var modalInstance;
        $scope.open_modal = function () {
          Members.get(function (response) {
            console.log(response);
            $scope.data = response.data
          }, function (error) {
            console.log(error);
          })

          Header.get({ id: 'header' }, function (response) {
            $scope.company = response.data.attributes;
          });
          modalInstance = $uibModal.open({
            animation: true,
            backdrop: false,
            templateUrl: "dashboard/global/global-modal-team.html",
            scope: $scope
          })
            .result.catch(function (res) {
              if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                console.log(res);
                throw res;
              }
            }
            );
        }

        var modalInstancePrivileges;
        $scope.open_modal_privileges = function (user) {
          $scope.selected_user = user;
          modalInstancePrivileges = $uibModal.open({
            animation: true,
            backdrop: false,
            templateUrl: "dashboard/global/global-modal-team-privilege.html",
            scope: $scope
          });
          modalInstancePrivileges.result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
              console.log(res);
              throw res;
            }
          });
        };
        $scope.assign_privileges = function () {
          console.log($scope.selected_user);
          $scope.save($scope.selected_user);
          modalInstancePrivileges.close();
        }

        $scope.cancel = function () {
          this.$close()
        }
        $scope.append_user = function () {
          $scope.errors = null;
          console.log('append_user');
          Members.create({ user: $scope.new_user }, function (response) {
            console.log(response);
            $scope.data.push(response.data);
            Notification.success("Membro adicionado à empresa");
          }, function (error) {
            console.log(error);
            Notification.error(error.data.message)
          });
        }
        $scope.destroy = function (user) {
          $scope.errors = null;
          console.log(user);

          Members.delete(user, function (success) {
            console.log(success);
            $scope.data.splice($scope.data.findIndex(dt => dt.id == user.id), 1)
            Notification.success("Membro removido da empresa!");
          }, function (error) {
            console.log(error);
            Notification.error(error.data.message);
          })
        }
        $scope.save = function (user) {
          $scope.errors = null;
          Members.save(
            user,
            function (result) {
              console.log('sucesso ao salvar'); console.log(result);
            },
            function (error) {
              console.log(error)
              Notification.error(error.data.message);
            }
          );

        }
      }
    ]
  )
  ;
