'use strict';
angular.module('mineXplore')
  .controller('PrjDashboardController',
    ['$scope',
      '$state',
      'Project',
      'ProjectsDashboardChartDouScm',
      'ProjectsDashboardChartTerms',
      'ProjectsDashboardChartDocuments',
      'ProjectsDashboardFeedDouScm',
      'ProjectsDashboardFeedSicop',
      'CalendarHelper',
      function ($scope, $state, Project, DouScm, Terms, Documents, FeedDouScm, FeedTerms, cal) {
        console.log("PrjDashboardController");
        Project.get({ id: $state.params.id },
          function (response) {
            console.log(response);
            $scope.project = response.data;
          },
          function (error) {
            console.log(error);
          });

        var load_data = function () {
          console.log('load data')
          $scope.project = { id: $state.params.id };
          var params = { id: $state.params.id, dt_ini: cal.dt_ini.get(), dt_end: cal.dt_end.get() };
          Terms.query(params, function (response) {
            console.log(response);
            $scope.chart_prazos = response;
            var terms_click = function (e, item) {
              $state.go('prj_prazos', { id: $state.params.id })
            };
            response.options['onClick'] = terms_click;
          }, function (error) {
            console.log(error);
          })
          Documents.query(params, function (response) {
            console.log(response);
            $scope.chart_documents = response;
            var doc_click = function (e, item) {
              $state.go('prj_documentos', { id: $state.params.id })
            };
            response.options['onClick'] = doc_click;
          }, function (error) {
            console.log(error);
          });
          DouScm.query(params, function (response) {
            console.log(response);
            $scope.chart_dou_miner = response;
            var dou_scm_click = function (event, point) {

              var chart = point[0]._chart.controller;
              var activePoint = chart.getElementAtEvent(event);
              if (activePoint.length > 0) {
                  var model = activePoint[0]._model;
                  var series =  model.datasetLabel;
                  if(series=='DOU'){
                    $state.go('prj_dou', { id: $state.params.id })
                  }else if(series == 'SCM' || series == 'Cadastro Mineiro'){
                    $state.go('prj_eventos', { id: $state.params.id })
                  }
              }
            };
            response.options['onClick'] = dou_scm_click;
          }, function (error) {
            console.log(error);
          });

          FeedDouScm.query(params, function (response) {
            console.log(response);
            $scope.prj_dou_scm_feed = response.data;
          }, function (error) {
            console.log(error);
          });
          FeedTerms.query(params, function (response) {
            console.log(response);
            $scope.prj_sicop_feed = response.data;
          }, function (error) {
            console.log(error);
          })
        }
        $scope.load_data = load_data;


      }
    ]
  )
  ;
