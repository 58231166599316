'use strict';
angular.module('mineXplore')
  .controller('ProfileController', ['$scope', '$rootScope', 'Profile', 'User', 'AccountQuota', 'Notification', 'Stats', 'Privileges', function ($scope, $rootScope, Profile, User, Quota, Notification, Stats, Privileges) {
    console.log('ProfileController');
    console.log('Load profile');
    $scope.current_user = $rootScope.user;
    $scope.preferedTime = { value: 9, options: { floor: 2, ceil: 22 } }

    Quota.fetch({ id: 'quotas' },
      function (result) {
        $scope.quotas = result;
      },
      function (error) {
        //console.log('falha ao carregar ');
        Notification.error("Falha ao carregar cotas")
      }
    );


    Profile.fetch({ id: 'me' },
      function (data) {
        $scope.profile = data.data;
        $scope.setModel();
      },
      function (error) {
        console.log('falha ao carregar ');
        Notification.error("Falha ao carregar perfil") ;
      }
    );
    if(Privileges.check('Stats', 'read')){
      Stats.fetch( function(result){
        console.log('stats: ',result);
        $scope.stats = result;
      }, function(error){
        console.log(error);
        Notification.info('falha ao carregar taxa de atualização:')
      })
     }

    const getPicture = function (profile) {
      if (profile.attributes.picture != null) {
        return profile.attributes.picture.urls.thumb;
      } else if (angular.isDefined(profile.image)) {
        return profile.image;
      }
    };
    $scope.setModel = function () {
      $scope.model = {
        id: $scope.profile.id,
        name: $scope.profile.attributes.name,
        nickname: $scope.profile.attributes.nickname,
        picture: getPicture($scope.profile),
        picture_attributes: {},
        enterprise_attributes:{
          name: $scope.profile.attributes.enterprise.name,
          id: $scope.profile.attributes.enterprise.id,
        },
        preferences: (typeof $scope.profile.attributes.preferences === 'string' ? {} : $scope.profile.attributes.preferences),
        provider: $scope.profile.attributes.provider,
        email: $scope.profile.attributes.email
      }
    }

    $scope.uploadImage = function (change) {
      let event = change.target;
      if (event.files && event.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $('#image-preview').attr('src', e.target.result);
          $scope.model.picture_attributes.image_data = e.target.result;
        }
        reader.readAsDataURL(event.files[0]);
      }
    };

    $scope.submitProfile = function () {
      console.log('eita');
      if($scope.model.picture_attributes == {}){
        delete($scope.model.picture_attributes)
      }
      Profile.update({ id: 'me' }, $scope.model,
        function (result) {
          Notification.success("Seu perfil foi salvo com sucesso!");
        },
        function (error) {
          var msg_error = error.data.message || "Ocorreu um erro ao salvar perfil";
          Notification.error(msg_error);
        });
    }
    $scope.provider_icon = function(){
      if($scope.model == undefined || $scope.model.provider == undefined){
        return '';
      }
      if($scope.model.provider == 'email'){
        return 'fa-envelope'
      }else {
        return 'fa-' + $scope.model.provider;
      }
    }
  }]);
