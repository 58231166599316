angular.module('mineXplore')
.factory("MapTools", [ 'Substance', 'config', function(Substance, config) {
  var substances ;  
  var MapTools ;

    MapTools.prototype.destroy = function(cb) {
    return MapTools.remove({
      id: this.id
    }, cb);
  };
    

    return MapTools;
  }
]);
