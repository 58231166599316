/*
http://tombatossals.github.io/angular-openlayers-directive/#/events/event-vector-layer
*/
"use strict";
angular.module("mineXplore").service("olDraw", [
  "olStyles",
  "olDrawReq",
  function (olStyles, olDrawReq) {
    var _draw; // global so we can remove it later
    var _map;
    var _source = new ol.source.Vector({ wrapX: false });
    var _feature;
    var _callback;
    var addInteraction = function (drawType, callback) {
      _callback = callback;
      stop();
      if (drawType != undefined) {
        console.log(ol.interaction.Draw.createBox());
        if (drawType == "Box") {
          _draw = new ol.interaction.Draw({
            source: _source,
            type: "Circle",
            geometryFunction: ol.interaction.Draw.createBox(),
          });
          }
        else if (drawType == "DrawRequirement")
          {
            _draw = new ol.interaction.Draw({
            source: _source,
            type: "LineString",
            geometryFunction: olDrawReq.geometryFunction
          });
        } else {
          _draw = new ol.interaction.Draw({
            source: _source,
            type: drawType,
          });
        }
        _map.addInteraction(_draw);
        return _draw
      }
    }
    var stop = function () {
      if (_map != undefined) {
        _map.removeInteraction(_draw);
        disable_modifier_interaction(_map);
      }

    }

    var invoke_callback = function (data) {
      _callback(data)
    }
    var addEvent = function (draw) {
      draw.on('drawend', function (e) {
        console.log('drawend')
        _feature = e.feature;//this is the feature fired the event
        // var restOfFeats = _source.getFeatures();//rest of feats
        // var allFeats = restOfFeats.concat(_feature);//concatenate the event feat to the array of source feats
        console.log(_feature.getGeometry().getType());
        if (_feature.getGeometry().getType() == 'Circle') {
          console.log(_feature.getGeometry().getCenter());
          console.log(_feature.getGeometry().getRadius());
          //callback(_feature.getGeometry());
          invoke_callback(e);
        } else if (_feature.getGeometry().getType() == 'Polygon') {
          console.log(_feature.getGeometry().getCoordinates());
          //invoke_callback(_feature.getGeometry().getCoordinates());
          invoke_callback(e);
        }else if (_feature.getGeometry().getType() == "MultiLineString") {
          //console.log(_feature.getGeometry().getCoordinates());
          //invoke_callback(_feature.getGeometry().getCoordinates());
          console.log('eita porra, aqui tem q add o elemento no mapa?');
          invoke_callback(e);
        }
        stop();
      });
    }

    function selectFeature(layer) {
      var selectFeatureControl = new ol.interaction.Select();
      selectFeatureControl.on('select', function (e) {
        if (e.target.getFeatures().getLength() == 0)
          return;
        console.log('asdf');
      })
      _map.addInteraction(selectFeatureControl);
    }

    /**
     * Modify a Feature
     */

    var modifier_click_interaction = new ol.interaction.Select();
    var selected_features = modifier_click_interaction.getFeatures();
    var modify = new ol.interaction.Modify({
      features: modifier_click_interaction.getFeatures()
    });

    let click_modifier_interaction = function (map) {
      map.addInteraction(modifier_click_interaction);
      modifier_click_interaction.on('select', function (e) {
        modify.features = e.target.getFeatures();
        console.log('select interaction');
        if (angular.isDefined(e.deselected)) {
          console.log('removendo elemento.. espero q seja as geometrias');
          deselected_feature = e.deselected[0];
          console.log('slicer');
          if (deselected_feature) {
            deselected_feature.setStyle(deselected_feature.getStyle().slice(0, -1));
          }
        }

        if (e.target.getFeatures().getArray().length > 0) {
          feature = e.target.getFeatures().getArray()[0]
          feature.setProperties({ type: 'editable' });
          feature.setStyle(olStyles.modifierStyle(feature));
        }
        map.addInteraction(modify);
      });

      var dirty = {};

      selected_features.on('add', function (evt) {
        var feature = evt.element;
        var fid = feature.getId();
        feature.on('change', function (evt) {
          dirty[evt.target.getId()] = true;
        });
      });

      selected_features.on('remove', function (evt) {
        var feature = evt.element;
        var fid = feature.getId();
        if (dirty[fid]) {
          console.log('changed');
        }
      });
      console.log('now you should edit');
    }

    let disable_modifier_interaction = function (map, feature) {
      if (feature !== undefined && feature.get('type') == 'editable') {
        console.log('aaa');
        feature.setStyle(feature.getStyle().slice(0, 1));
        feature.setProperties({ type: null });
      }
      modifier_click_interaction.getFeatures().clear();
      //modifier_click_interaction.dispatchEvent('select');
      map.removeInteraction(modifier_click_interaction);
    }

    let select_feature = function (selected_feature) {
      if (selected_feature !== undefined) {
        modifier_click_interaction.getFeatures().clear();
        modifier_click_interaction.getFeatures().push(selected_feature);
        modifier_click_interaction.dispatchEvent('select');
      }
    }

    // Create the graticule component
    var graticule = new ol.Graticule({
      // the style to use for the lines, optional.
      strokeStyle: new ol.style.Stroke({
        color: 'rgba(255,120,0,0.9)',
        width: 2,
        lineDash: [0.5, 4]
      }),
      showLabels: true
    });


    return {
      draw: function (map, drawType, callback) {
        _map = map;
        _draw = addInteraction(drawType, callback);
        addEvent(_draw);
      },
      draw_requirement: function (map, callback) {
        _map = map;
        _draw = addInteraction('DrawRequirement', olDrawReq.ortho_drawend);
        addEvent(_draw);
      },
      'stop': function () {
        console.log('stop');
        stop();
      },
      'selector': function (map, layer) {
        _map = map;
        _draw = selectFeature(layer);
      },
      'enable_modifier': function (map, selected_feature) {
        disable_modifier_interaction(map);
        click_modifier_interaction(map);
        select_feature(selected_feature);
      },
      'disable_modifier': function (map, feature) {
        disable_modifier_interaction(map, feature);
      },
      'enable_gridline': function (map) {
        console.log('enabled');
        graticule.setMap(map);
      },
      'disable_gridline': function () {
        console.log('disabled');
        graticule.setMap(null);
      },
    }
  }
  ]);
