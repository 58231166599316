angular.module('mineXplore')
.factory("Action", ['$resource', 'config', function($resource, config) {
  var Action = $resource(config.apiUrl + config.baseUrl + "/areas/:id/actions",
    { id: "@id" },
    {
      update: { method: "PUT" },
      save: {method: 'POST'},
      create:{ method: "POST"},
      fetch: {method: 'GET', isArray: false }
    }
  );

  Action.prototype.destroy = function(cb) {
    return Action.remove({ id: this.id }, cb);
  };
  return Action;
}]);
