angular.module('mineXplore')
    .factory("EnterpriseHeader", ['$resource', 'config', function($resource, config) {
        var obj;
        obj = $resource(config.apiUrl + config.baseUrl + "/common/enterprise_header/:id.json", {
            id: "@id"
        }, {
            get: {
                method: 'GET',
                isArray: false,
                cache: true
            },
        });
        obj.prototype.destroy = function(cb) {
            return obj.remove({
                id: this.id
            }, cb);
        };
        return obj;
    }]);