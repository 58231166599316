angular.module('mineXplore')
.config(function ($authProvider, config) {
  var isMob = window.cordova !== undefined;
  $authProvider.configure({
    apiUrl: (config.apiUrl + config.baseUrl),
    tokenValidationPath:     '/auth/validate_token',
    storage:                 'cookies',
    omniauthWindowType:      'newWindow',
    omniauthWindowType: isMob ? 'inAppBrowser' : 'newWindow',
    storage: isMob ? 'localStorage' : 'cookies',

    authProviderPaths: {
      github:   '/auth/github',
      facebook: ('/auth/facebook'),
      google:   '/auth/google_oauth2',
      linkedin:   '/auth/linkedin'
    },
      handleLoginResponse: function(response) {
        return response.data;
      },
      handleTokenValidationResponse: function(response) {
        return response.data;
      },
      handleAccountUpdateResponse: function(response) {
        return response.data;
      }
    });
})


.run(['$rootScope', '$location', '$state', '$http', '$stateParams', function($rootScope, $location, $state, $http, $stateParams) {

  $rootScope.$on('auth:logout-success', function(/* ev , reason */) {
    $state.go('root.home');
  });

  $rootScope.$on('auth:login-success', function(env, response) {
    $rootScope.user = response.attributes;
  });

  $rootScope.$on('auth:validation-success', function(ev, reason) {
    if(!$rootScope.user){
      //console.log($rootScope.user);
    } else {
      // Set userID for analytics tracking
      //ga('set', 'userId', $rootScope.user.id);
      //$analytics.pageTrack('/reload/' + $rootScope.user.id + '/' + $rootScope.user.name);
      //$analytics.eventTrack('/reload/' + $rootScope.user.id + '/' + $rootScope.user.name);
    }
  });

  $rootScope.$on('auth:validation-error', function(/* ev, reason */) {
    //$state.go('root.home');
    //$rootScope.$emit('authenticate', {});
  });
  if($stateParams.reset_password_token && $stateParams.reset_password_token.length > 0){
    console.log('show showPasswordReset');
    $scope.showPasswordReset($stateParams.reset_password_token);
  } else if($stateParams.confirmed && $stateParams.confirmed.length > 0){
    console.log('show confirmedPopup');
    $scope.confirmedPopup($stateParams.confirmed);
  }


}]);
