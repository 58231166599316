
'use strict';
angular.module('ngSweetAlert2', [])
.factory('SweetAlert', ['$rootScope', '$window', function ($rootScope, $window) {

    var swal = $window.swal;

    var self = function (arg1, arg2) {
        $rootScope.$evalAsync(function () {
            if (typeof (arg2) === 'function') {
                var functionAsStr = String(arg2).replace(/\s/g, '');
                var functionHandlesCancel = functionAsStr.substring(0, 9) === "function(" && functionAsStr.substring(9, 10) !== ")";

                var doneFn;
                if (functionHandlesCancel) {
                    doneFn = function (isConfirm) {
                        $rootScope.$evalAsync(function () {
                            arg2(isConfirm);
                        });
                    };
                } else {
                    doneFn = function () {
                        $rootScope.$evalAsync(function () {
                            arg2();
                        });
                    };
                }
                swal(arg1).then(doneFn);
            } else {
                swal(arg1, arg2);
            }
        });
    };

    var props = {
        swal: self,
        success: function (title, message) {
            $rootScope.$evalAsync(function () {
                swal(title, message, 'success');
            });
        },
        error: function (title, message) {
            $rootScope.$evalAsync(function () {
                swal(title, message, 'error');
            });
        },
        warning: function (title, message) {
            $rootScope.$evalAsync(function () {
                swal(title, message, 'warning');
            });
        },
        info: function (title, message) {
            $rootScope.$evalAsync(function () {
                swal(title, message, 'info');
            });
        },
        showInputError: function (message) {
            $rootScope.$evalAsync(function () {
                swal.showInputError(message);
            });
        },
        close: function () {
            $rootScope.$evalAsync(function () {
                swal.close();
            });
        }
    };

    angular.extend(self, props);

    return self;
}]);
