'use strict';
angular.module('mineXplore')
  .service('menu_resize', ['$window', function ($window) {
    var menu_helper ={};
    menu_helper['init'] = function(element_name){
            var appWindow = angular.element($window);
            appWindow.bind('resize', function () {
                resize_callback();
            });

            const menuOption = document.querySelector(".nav");
            const openHamburguer = document.getElementById("openHamb");
            const closeHamburguer = document.getElementById("closeHamb");
            const allTitles = document.querySelectorAll(element_name);
            var mouseover_handler_lg = function(e){
                var targetLink = e.target.closest("a");
                if (!targetLink) return;
                var titleOption = targetLink.querySelector(element_name);
                $(titleOption).stop(true, false).fadeToggle(600);
            }
            var mouseout_handler_lg = function(e){
                var targetLink = e.target.closest("a");
                if (!targetLink) return;
                var titleOption = targetLink.querySelector(element_name);
                $(titleOption).stop(true, false).fadeToggle(200);
            }
            var click_handler_sm = function(){
                $(menuOption).stop(true, false).fadeToggle(400);
                openHamburguer.classList.add("hide");
                closeHamburguer.classList.remove("hide");
            }

            var clickout_handler_sm = function(){
                $(menuOption).stop(true, false).fadeToggle(200);
                closeHamburguer.classList.add("hide");
                openHamburguer.classList.remove("hide");
            }

            var resize_callback = function(){
                menuOption.removeEventListener("mouseover", mouseover_handler_lg)
                menuOption.removeEventListener("mouseout", mouseout_handler_lg)
                openHamburguer.removeEventListener('click', click_handler_sm);
                closeHamburguer.removeEventListener('click', clickout_handler_sm);

                if(window.innerWidth > 980){
                    document.querySelector(".nav").style.display= '';
                    menuOption.addEventListener("mouseover", mouseover_handler_lg)
                    menuOption.addEventListener("mouseout", mouseout_handler_lg)
                    allTitles.forEach(function(oneTitle){
                        oneTitle.style.display = "none";
                    })
                } else {

                    allTitles.forEach(function(oneTitle){
                        oneTitle.style.display = "block";
                    })
                    openHamburguer.addEventListener('click', click_handler_sm);
                    closeHamburguer.addEventListener('click', clickout_handler_sm);
                }
            }
            resize_callback();

    }

    return menu_helper;
  }
  ]);