/////////////////////
angular.module("mineXplore", [
  "mineXplore.controllers.Main",
  "mobile-angular-ui",
  "ng-token-auth",
  "ui.router",
  "ngAnimate",
  "ngResource",
  "ngSanitize",
  "ipCookie",
  "angulartics",
  "ui.bootstrap",
  "ui.bootstrap.showErrors",
  "uiGmapgoogle-maps",
  "ui-notification", // Angular notify
  "ngMessages",
  "angular-flot", // Flot chart
  "angles", // Chart.js
  "angular-peity", // Peity (small) charts
  //'templates',
  "ui.map", // Ui Map for Google maps
  "ui.calendar", // UI Calendar
  "summernote", // Summernote plugin
  "ngGrid", // Angular ng Grid
  "ui.tree", // Angular ui Tree
  //'bm.bsTour',                // Angular bootstrap tour
  "datatables", // Angular datatables plugin
  "xeditable", // Angular-xeditable
  "ui.select", // AngularJS ui-select
  //'datatables.buttons',       // Datatables Buttons
  "angular-ladda", // Ladda - loading buttons
  "ngSweetAlert2",
  // /'seo',
  "ngTouch",
  "angularSpinner",
  "angular-timeline",
  "angular-google-analytics",
  "ngCsv",
  "openlayers-directive",
  "ui.select",
  "720kb.datepicker",
  "chart.js",
  "ui.utils.masks",
  "rzSlider",
]);

//alternative to config env variables: https://www.jvandemo.com/how-to-configure-your-angularjs-application-using-environment-variables/
