'use strict';
angular.module('mineXplore')
  .controller('DashboardProjectsController',
    ['$scope', 'GlobalProject', '$uibModal', 'Project', 'Notification', "SweetAlert", function ($scope, GlobalProject, $uibModal, Project, Notification, SweetAlert) {
      console.log('DashboardProjectsController')
      GlobalProject.query(function (response) {
        console.log(response);
        $scope.data = response.data;
      }, function (error) {
        console.log(error);
      });
      // create project
      var modalInstance;
      $scope.createProject = function () {
        console.log('cerate project');
        $scope.loading = true;
        modalInstance = $uibModal.open({
          templateUrl: 'project/new_project_modal.html',
          controller: 'NewProjectModalController',
          resolve: {
            data: function () {
              return;
            }
          }
        });
        modalInstance.result.then(function (newProject) {
          if (newProject) {
            Notification.info("Atualizando lista de projetos...")
            $scope.data.push(newProject);
          } else {
            Notification.info("nao retornou?")
          }
        }, function (err) {
          console.log(err);
        });

      };
      //destroy with confirmation
      $scope.destroy = function (project) {
        var original;
        $scope.loading = true;
        SweetAlert.swal({
          title: "Confirmação",
          text: "Deseja excluir o projeto " + project.attributes.name + " ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
          cancelButtonText: "Não",
          closeOnConfirm: false,
          closeOnCancel: false
        },
          function (isConfirm) {
            if (isConfirm) {
              original = $scope.data.find(x => x.id === project.id);
              Project.delete({ id: project.id },
                function (result) {
                  $scope.data = _.without($scope.data, original);
                  $scope.loading = false;
                  Notification.success("Processo foi removido com sucesso!")
                },
                function (error) {
                  console.log("erro ao excluir processo");
                  console.log(error);
                  Notification.error(error.data.message);
                  $scope.loading = false;
                })

            } else {
              console.log('cancelado');
              Notification.info("Este processo continuará enviando alertas para você");
            }
          });

      };
    }



    ]
  )
  ;
