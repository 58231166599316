'use strict';
angular.module('mineXplore')
  .factory('Storage', function () {
    return {
      set: function (key, value, json) {
        if (json) {
          return localStorage.setItem(key, JSON.stringify(value));
        } else {
          return localStorage.setItem(key, value);
        }
      },

      get: function (key, parse) {
        if (parse) {
          return JSON.parse(localStorage.getItem(key));
        } else {
          return localStorage.getItem(key);
        }
      },

      find_key: function (key, parse) {
        items = [];
        for (var i = 0, len = localStorage.length; i < len; ++i) {
          item_key = localStorage.key(i);
          item = localStorage.getItem(item_key);
          if (item_key.indexOf(key) !== -1) {
            //localStorage.removeItem(item_key);
            if (parse) {
              items.push(JSON.parse(item));
            } else {
              items.push(item);
            }
          }
        }
        return items;
      },

      remove: function (key) {
        localStorage.removeItem(key);
      },

      clear: function (key) {
        localStorage.clear();
      }
    };
  });
