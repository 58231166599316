'use strict';
angular.module('mineXplore')
  .controller('PrjMovimentacoesController',
    ['$scope', '$state', 'ProjectsSicop', 'ProjectsTracking', 'Project', 'CalendarHelper', function ($scope, $state, Sicop, ProjectsTracking, Project, cal) {
      $scope.project_id = $state.params.id;
      Project.get({ id: $state.params.id },
        function (response) {
          console.log(response);
          $scope.project = response.data;
        },
        function (error) {
          console.log(error);
        });
      var load_data = function () {
        $scope.project = { id: $state.params.id };
        var params = { id: $state.params.id, dt_ini: cal.dt_ini.get(), dt_end: cal.dt_end.get() };
        ProjectsTracking.query(params, function (response) {
          console.log('aaaa');
          console.log(response);
          $scope.data = response.data;
        }, function (error) {
          console.log('bbbb');
          console.log(error);
        });

        // Sicop.query(params, function (response) {
        //   console.log('aaaa');
        //   console.log(response);
        //   $scope.data = response.data;
        // }, function (error) {
        //   console.log('bbbb');
        //   console.log(error);
        // });


      };
      $scope.load_data = load_data;
    }
    ]
  )
  ;
