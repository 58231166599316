'use strict';
angular.module('mineXplore')
  .service('exportGeoJSON', ['olExporter', function (olExporter) {
    function serialize(feature, filename) {
      var formatter = new ol.format.GeoJSON();
      olExporter.export(formatter, [feature], "data:text/plain;charset=utf-8,", filename)
    }

    return {
      'export': function (feature, filename) {
        serialize(feature, filename)
      }
    };
  }
  ]);
