'use strict';
angular.module('mineXplore')
  .service('exportCsv', ['ddToDms', 'SweetAlert', 'Notification', 'Privileges', function (ddToDms, SweetAlert, Notification, Privileges) {

    build_content = function (map_type, poly) {
      let csvContent = "data:text/csv;charset=utf-8,";
      if (map_type == 'ol') {
        var src = 'EPSG:3857', dest = 'EPSG:4326';
        var geometry = poly.getGeometry().transform(src, dest);
        //var coords = geometry.getCoordinates()[0]
        var coords = geometry.getCoordinates().length == 1 ?  geometry.getCoordinates()[0] : geometry.getCoordinates()[0][0] ;
        coords.forEach(function (rowArray) {
          var vertex = [ddToDms.ddToDms(rowArray[1], rowArray[0])]
          let row = vertex.join(",");
          csvContent += row + "\r\n";
        });
        poly.getGeometry().transform(dest, src); // it prevent feature to desappear
      } else if (map_type == 'gm') {
        var coords = poly.getPath().getArray();
        coords.forEach(function (rowArray) {
          var vertex = [ddToDms.ddToDms(rowArray.lng(), rowArray.lat())]
          let row = vertex.join(",");
          csvContent += row + "\r\n";
        });
      }
      return csvContent;
    }

    return {
      // This function returns the coordinate
      // conversion string in DD to DMS.
      'export_csv': function (map_type, poly, file_name) {
        csvContent = build_content(map_type, poly)

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", file_name);
        link.innerHTML = "Download polygon csv";
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
      },

      // requer user confirmation to export csv
      'export': function (map_type, poly, file_name) {
        _this = this;
        swal({
          title: 'Exportar',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: 'blue',
          cancelButtonColor: 'red',
          html: 'Click em OK para exportar o CSV',
        }).then(function (isConfirm) {
          if (isConfirm) {
            if(Privileges.check('minexplore.frontend.map', 'exportCSV')){
              _this.export_csv(map_type, poly, file_name)
            }else{
              Notification.info('Acesso negado. Seu plano não possui o privilégio para exportar CSV');
            }
          }
          else {
            Notification.info("Exportação cancelada");
          }
        });
      },

    }

  }
  ]);
