angular.module('mineXplore')
  .controller('AuthController',
    ['$rootScope', '$scope', '$uibModal', '$state', '$auth', '$stateParams', 'Subscription', '$timeout', '$location', 'Notification', 'Invitation',
      function ($rootScope, $scope, $uibModal, $state, $auth, $stateParams, Subscription, $timeout, $location, Notification, Invitation) {

        let modalInstance = null;
        // let u = User.prototype.getUser();
        $scope.$state = $state;
        $scope.invitation_token = $stateParams.invitation_token;
        $scope.$watch('user', function () {
          if ($scope.user && $scope.user.name) {
            $scope.userinitials = $scope.user.name.match(/\b(\w)/g).join('').substr(0, 2);
          }
        }, true);


        // Handle sign outs
        $scope.signOut = function () {
          console.log('signOut')
          $auth.signOut();
        };
        $rootScope.$on('auth:logout-success', function (ev, data) {
          console.log('signOut success - redirecting to landpage')
          $state.go('root');
        });

        $rootScope.$on('auth:logout-error', function (ev, data) {
          console.log('signOut error - what can i do?')
        });

        $scope.submitLogin = function (loginForm) {
          $auth.submitLogin({
            email: loginForm.email,
            password: loginForm.password
          });
        };
        $scope.submitRegister = function () {
          $scope.registering = true;
          $scope.registrationForm.referal = $stateParams.referal;
          $auth.submitRegistration($scope.registrationForm)
            .then(function (response) {
            })
            .catch(function (err) {
              $scope.registering = false;
              let msg = (err.data != null) ? err.data.errors : ['Erro não esperado.'];
              $scope.errors = msg;
            });
        };
        $scope.sendingReset = false;

        $scope.sendResetLogin = function (resetForm) {
          $scope.sendingReset = true;
          $scope.email = resetForm.email;
          $auth.requestPasswordReset(resetForm)
            .then(function (/* resp */) {
              $scope.state = 'sent';
            })
            .catch(function (resp) {
              $scope.sendingReset = false;
              let msg = (resp != null && resp.data != null && resp.data.errors != null) ? resp.data.errors[0] : ['Erro não esperado.'];
              $scope.error = msg;
            });
        };
        $scope.$on('auth:login-error', function (ev, reason) {
          let msg
          if (reason == null || reason == undefined) {
            msg = 'Erro não esperado. servidor inacessível';
          } else if (reason.errors != undefined) {
            msg = reason.errors[0]
          } else if (reason != "") {
            msg = reason
          }
          Notification.error(msg);
          $scope.error = msg;
        });


        $scope.$on('auth:registration-email-success', function (ev, message) {
          $scope.showThankyou(message);
        });


        $scope.hasSearch = false;

        $scope.registerPassword = false;
        $scope.registeredPassword = false;
        $scope.registeredPassword = true;



        $scope.changePassword = function () {
          $auth.updatePassword({
            'password': $scope.passwordResetForm.password,
            'password_confirmation': $scope.passwordResetForm.password_confirmation,
            'reset_password_token': $scope.token
          }).then(function (resp) {
            $scope.resetPassword = false;
            $scope.resetedPassword = true;
            //$uibModalInstance.close();
          }).catch(function (error) {
            // handle error response
            console.log('changePassword error', error);
            err_msg = error.data.errors.full_messages[0] || 'Não foi possível atualizar a senha.';
            if (err_msg == "Email não pode ficar em branco") {
              err_msg = "Certifique-se que este token de recuperação de senha não foi utilizado anteriormente"
            }
            Notification.error(err_msg);
            console.log(error.data.errors.full_messages[0]);
            //$scope.errors = error.data.errors;
          });
        };

        $scope.accept_invitation = function () {
          Invitation.update({accept_invitation: {
            invitation_token: $scope.invitation_token,
            password: $scope.invitation_form.password,
            name: $scope.invitation_form.name,
          }
        }, function (result) {
            Notification.success('Convite foi aceito com sucesso');
            //$stateParams.invitation_token = null;
            $state.go('root',{invitation_token: null},{reload:true});
          }, function (error) {
            Notification.error(error.data.message);
          }
          )
        }
        $scope.resetPassword = false;
        $scope.showPasswordReset = function (token) {
          $scope.token = token;
          $scope.resetPassword = true;
          $scope.resetedPassword = false;
        };

        $scope.passwordChangedPopup = function () {
          modalInstance = $uibModal.open({
            templateUrl: 'passwordChangedPopup.html'
          }).result.then(function () {
            $scope.resetPassword = false;
          });
        };

        $scope.confirmed = false;
        $scope.confirmedPopup = function (status) {
          $scope.confirmedStatus = status;
          $scope.confirmed = true;
        };
        $scope.registered = false;
        $scope.showThankyou = function (data) {
          $scope.email = data.email;
          $scope.registered = true;
        };
        $scope.subscriptionConfirmation = function (data) {
          modalInstance = $uibModal.open({
            templateUrl: 'subscriptionConfirmation.html',
            resolve: {
              email: function () {
                return data.email;
              }
            },
            controller: function ($scope, email) {
              $scope.email = email;
            }
          }).closed.then(function () {
            $state.go('map');
          });
        };

        $scope.authenticate = function (provider) {
          //authenticate('facebook', {params: oAuthForm, config: 'evilUser'})
          $auth.authenticate(provider, { params: { resource_class: 'User' } });
          //$auth.authenticate(provider);
        };

        $rootScope.$on("authenticate", function () {
          $scope.showLogin();
        });

        $rootScope.$on('auth:validation-success', function (ev, data) {
          $timeout(function () {
            if ($location.path() == '/') {
              $state.go('map')
            }
          }, 1500);
        });

        $rootScope.$on('auth:validation-error', function (ev, data) {
        });

        $rootScope.$on('auth:session-expired', function (ev, data) {
        });

        if ($stateParams.reset_password_token && $stateParams.reset_password_token.length > 0) {
          $scope.showPasswordReset($stateParams.reset_password_token);
        } else if ($stateParams.confirmed && $stateParams.confirmed.length > 0) {
          $scope.confirmedPopup($stateParams.confirmed);
        } else if ($stateParams.payment_confirmation_success
          && $stateParams.payment_confirmation_success.length > 0
          && $stateParams.code
          && $stateParams.code.length > 0) {
          let cb_success = function (data) {
            $scope.subscriptionConfirmation($stateParams.code);
          };
          let cb_fail = function (error) {
            console.log(error)
          };
          Subscription.create({ code: $stateParams.code }, cb_success, cb_fail)
        }
      }]);
