'use strict';
angular.module('mineXplore')
  .controller('DashboardDouController',
    ['$scope' ,'$window', 'EnterpriseHeader', 'GlobalDou', 'CalendarHelper', 'DouModal', function ($scope, $window, Header, GlobalDou, cal, Dou) {
      console.log('DashboardDouController')
      Header.get({ id: 'header' }, function (response) {
        $scope.company = response.data.attributes;
      });
      var load_data = function () {
        var dt_param = { dt_ini: cal.dt_ini.get(), dt_end: cal.dt_end.get() };
        GlobalDou.query(dt_param, function (response) {
          console.log(response);
          $scope.data = response.data;
        }, function (error) {
          console.log(error);
        })
      }
      $scope.load_data = load_data;

      $scope.open_dou = function (event) {
        Dou.open($scope, event.date);
      }
      $scope.has_data = function(){
        dt = []
        if($scope.data==undefined){ return false; }
        $scope.data.map(function(m){ dt.push(m.attributes.processes) })
        if(dt.length > 0 && dt[0].length > 0 ){ return true; }
      }
    }]
  );