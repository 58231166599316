"use strict";
angular.module("mineXplore").service("olDrawReq",  ["olStyles",
  function (olStyles) {
    _map = null;

    var draw_style = new ol.style.Style({
                      fill: new ol.style.Fill({
                        color: 'rgba(255, 255, 255, 0.2)',
                      }),
                      stroke: new ol.style.Stroke({
                        color: '#ffcc33',
                        width: 2,
                      }),
                      image: new ol.style.Circle({
                        radius: 7,
                        fill: new ol.style.Fill({
                          color: '#ffcc33',
                        }),
                      }),
                    });
    ;
    free_drawend = function(e){
      // console.log('free_drawend');
      addToLayer(e, [e.feature]);
    }

    ortho_drawend = function(e){
      // console.log('olDrawReq.drawend');
      var lg = null; angular.forEach(e.target.getMap().getLayers(), function(l){ if(l.get('name') == 'UserFeatures' ){ lg = l } } )

      coordinates = e.feature.getGeometry().getCoordinates();

      new_coord = [
        coordinates[0][0][0], coordinates[0][coordinates[0].length-1][1]
      ];
      coordinates[0].push(new_coord);

      const polygonFeature = new ol.Feature( new ol.geom.Polygon(coordinates) );
      return addToLayer(e, [polygonFeature]);
    }

    addToLayer = function(e, features){
      var lg = null; angular.forEach(e.target.getMap().getLayers(), function(l){ if(l.get('name') == 'UserFeatures' ){ lg = l } } )
      var source = new ol.source.Vector({
        features: features
      });
      var layer = new ol.layer.Vector({
        source: source,
        style: draw_style,
        title: 'novo requerimento'
      });
      lg.getLayers().getArray().push(layer);
    }

    var lat_changed = function(coord){
      if(coord.type=='drawend'){
        coord = coord.feature.getGeometry().getCoordinates()
      }
      return(Math.abs(coord[coord.length-2][0]-coord[coord.length-1][0]));
    }

    var long_changed = function(coord){
      if(coord.type=='drawend'){
        coord = coord.feature.getGeometry().getCoordinates()
      }
      return(Math.abs(coord[coord.length-2][1]-coord[coord.length-1][1]));
    }

    var new_end = function(coordinates){
      var start = coordinates[coordinates.length-2];
      var end = coordinates[coordinates.length-1];
      if(long_changed(coordinates) > lat_changed(coordinates)){
        end[0] = start[0];
      }else{
        end[1] = start[1];
      }
      return [ start, end,  ]
    }

    var geometryFunction= function(coordinates, geometry) {
      if (!geometry) {
        geometry = new ol.geom.MultiLineString(null);
      }
      geometry.getCoordinates().push(new_end(coordinates));;
      geometry.setCoordinates( [coordinates] );
      return geometry;
    }
    return{
      setMap: function(map){
        this._map = map;
      },
      geometryFunction: geometryFunction,
      ortho_drawend: ortho_drawend,
      free_drawend: free_drawend,
    }
  }
  ]);