angular.module('mineXplore')
    .factory("ProcessStage", ['$resource', 'config', function($resource, config) {
        var ProcessStage;
        ProcessStage = $resource(config.apiUrl + config.baseUrl + "/process_stage/:id.json",
            { id: "@id" },
            {
                update: { method: "PUT" },
                destroy: { method: "DELETE" },
                //save: {method: 'POST'},
                //create:{ method: "POST"},
                query: {method: 'GET', isArray: false, cache: true },
                fetch: {method: 'GET', isArray: false, cache: true }
            }
        );
        ProcessStage.prototype.destroy = function(cb) {
            return ProcessStage.remove({
                id: this.id
            }, cb);
        };
        return ProcessStage;
    }]);
