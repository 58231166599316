'use strict';
angular.module('mineXplore')
  .service('gMapHelper', [function () {
    var current_buffer;
    let gmaps_init = function (uiGmapIsReady, $scope, refresh_query) {
      uiGmapIsReady.promise().then(function (maps) {
        // This line returns: "TypeError: undefined is not a function"
        console.log('MAPA PRONTO, agora vou adicinar os eventos');
        if ($scope.drawingEnabled) {
          google.maps.event.addListener($scope.drawingManagerControl.getDrawingManager(), 'overlaycomplete', function (event) {
            console.log("add listener no drawingManager ");
            if (current_buffer) {
              console.log('removendo overlay');
              current_buffer.setMap(null);
            }
            current_buffer = event.overlay;
            $scope.search_overlay = event;
            event.overlay.enableCoordinatesChangedEvent(event.type);
            google.maps.event.addListener(event.overlay, 'coordinates_changed', function (index, obj) {
              console.log('coordinates_changed');
              refresh_query($scope.search_overlay);
            });
            refresh_query(event);
            if ($scope.drawingManagerControl) {
              // desativandod draw mode
              $scope.drawingEnabled = true;
              $scope.drawingManagerControl.getDrawingManager().drawingMode = null;
              var dm = $scope.drawingManagerControl.getDrawingManager();
              dm.setOptions($scope.drawingManagerOptions);
            }
          });
        }
      });
      return current_buffer;
    }

    let gmaps_events = function ($scope, exportCsv) {
      return {
        click: function (gPoly, event, polyModel) {
          var centerPos = getPolyCenter(gPoly);
          $scope.polywindow.coords = centerPos;
          $scope.map.center = centerPos;
          $scope.showDetails(polyModel.id);
          $scope.polywindow.model = polyModel;
          angular.forEach($scope.processes.features, function (v, l) { v.selected = false; });
          $scope.array_path = gPoly.getPath().getArray();
        },
        dblclick: function (gPoly, event, polyModel) {
          gPoly.set('editable', !gPoly.editable);
        },
        rightclick: function (gPoly, event, polyModel) {
          exportCsv.export('gm', gPoly, 'poligono.csv');
        }
      };
    };

    let gmaps_drawing_manger_options = function (drawOptions) {
      return {
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            //google.maps.drawing.OverlayType.MARKER,
            //google.maps.drawing.OverlayType.LINE,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.RECTANGLE
          ]
        },
        circleOptions: drawOptions,
        rectangleOptions: drawOptions,
        polygonOptions: drawOptions,
      };
    }

    let gmaps_draw_options = function gmaps_draw_options() {
      return {
        fillColor: '#fff000',
        fillOpacity: 0.2,
        strokeWeight: 1,
        clickable: false,
        editable: true,
        zIndex: -1
      };
    }

    let gmaps_options = function gmaps_options() {
      return {
        scrollwheel: true,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        scaleControl: true,
        streetViewControl: true,
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        zoomControl: true,
        rotateControl: true,
      };
    }



    return {
      'zoom': function (gPoly, $window) {
        $window.scrollTo(0, 0);
        google.maps.event.trigger(gPoly.gObject, 'click', { stop: null, latLng: new google.maps.LatLng(0, 0), edge: 0, path: 0, vertex: 0 });
        gPoly.gObject.editable = true;
      },
      'getPolyCenter': function (gPoly) {
        var bounds = new google.maps.LatLngBounds();
        gPoly.getPath().forEach(function (latLng) { bounds.extend(latLng); });
        var gCenter = bounds.getCenter();
        return { latitude: gCenter.lat(), longitude: gCenter.lng() }
      },
      'extendGeoJsonObject': function (callback) {
        var geo = { "type": "FeatureCollection", "features": [] }, fx = function (g, t) {
          var that = [], arr, f = {
            MultiLineString: 'LineString',
            LineString: 'Point',
            MultiPolygon: 'Polygon',
            Polygon: 'LinearRing',
            LinearRing: 'Point',
            MultiPoint: 'Point'
          };
          switch (t) {
            case 'Point':
              g = (g.get) ? g.get() : g;
              return ([g.lng(), g.lat()]);
              break;
            default:
              arr = g.getArray();
              for (var i = 0; i < arr.count; ++i) {
                that.push(fx(arr[i], f[t]));
              }
              if (t == 'LinearRing'
                &&
                that[0] !== that[that.count - 1]) {
                that.push([that[0][0], that[0][1]]);
              }
              return that;
          }
        };
        this.data.forEach(function (feature) {
          var _feature = { type: 'Feature', properties: {} };
          _id = feature.getId(),
            _geometry = feature.getGeometry(),
            _type = _geometry.getType(),
            _coordinates = fx(_geometry, _type);
          _feature.geometry = { type: _type, coordinates: _coordinates };
          if (typeof _id === 'string') {
            _feature.id = _id;
          }
          geo.features.push(_feature);
          feature.forEachProperty(function (v, k) {
            _feature.properties[k] = v;
          });
        });
        if (typeof callback === 'function') {
          callback(geo);
        }
        return geo;
      },
      'coordinatesChangedEvent': function (type) {

        var me = this, isBeingDragged = false;
        var triggerCoordinatesChanged = function () {
          // Broadcast normalized event
          google.maps.event.trigger(me, 'coordinates_changed');
        };

        // If  the overlay is being dragged, set_at gets called repeatedly,
        // so either we can debounce that or igore while dragging,
        // ignoring is more efficient
        google.maps.event.addListener(me, 'dragstart', function () {
          isBeingDragged = true;
        });

        // If the overlay is dragged
        google.maps.event.addListener(me, 'dragend', function () {
          triggerCoordinatesChanged();
          isBeingDragged = false;
        });

        // Or vertices are added to any of the possible paths, or deleted
        if (type == google.maps.drawing.OverlayType.POLYGON || type == google.maps.drawing.OverlayType.POLYLINE) {
          var paths = me.getPaths();
          paths.forEach(function (path, i) {
            google.maps.event.addListener(path, "insert_at", function () {
              triggerCoordinatesChanged();
            });
            google.maps.event.addListener(path, "set_at", function () {
              if (!isBeingDragged) {
                triggerCoordinatesChanged();
              }
            });
            google.maps.event.addListener(path, "remove_at", function () {
              triggerCoordinatesChanged();
            });
          });
        } else if (type == 'Circle') {

        } else if (type == 'Rectangle') {

        }

      },
      'gmaps_init': function (uiGmapIsReady, $scope, callback) {
        gmaps_init(uiGmapIsReady, $scope, callback);
      },
      'gmaps_drawing_manger_options': function (drawOptions) {
        gmaps_drawing_manger_options(drawOptions);
      },
      'gmaps_options': function () {
        gmaps_options();
      },
      'gmaps_draw_options': function () {
        gmaps_draw_options();
      },
      'gmaps_events': function ($scope, exportCsv) {
        gmaps_events($scope, exportCsv);
      }

    }
  }
  ]);
