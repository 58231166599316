angular.module('mineXplore')
  .factory("Process", ['$resource', 'config', function ($resource, config) {
    var Process = $resource(config.apiUrl + config.baseUrl + "/areas/:id.json",
      { id: "@id" },
      {
        update: { method: "PUT" },
        destroy: { method: "DELETE" },
        //save: {method: 'POST'},
        //create:{ method: "POST"},
        project: { method: 'GET', isArray: false, url: config.apiUrl + config.baseUrl + "/projects/:id/processes.json", },
        projectByNumber: { method: 'GET', isArray: false, url: config.apiUrl + config.baseUrl + "/projects/:number/:year/processes.json", },
        details: { method: 'GET', isArray: false, url: config.apiUrl + config.baseUrl + "/areas/:id/details", },
        sicop: { method: 'GET', isArray: true, url: config.apiUrl + config.baseUrl + "/areas/:id/transitions", },
        query: { method: 'GET', isArray: false },
        search: { method: 'POST', isArray: false, url: config.apiUrl + config.baseUrl + "/areas/0/search", },
        fetch: { method: 'GET', isArray: false, hasBody: true, params: { circle: '@circle', rectangle: '@rectangle', polygon: '@polygon' } }
      }
    );

    Process.prototype.destroy = function (cb) {
      return Process.remove({
        id: this.id
      }, cb);
    };


    return Process;
  }
  ]);
