'use strict';

/**
 * @ngdoc function
 * @name mineXplore.controller:UserSessionsCtrl
 * @description
 * # UserSubscription
 * Controller of the mineXplore
 */
angular.module('mineXplore')
  .controller('UserSubscription', ['$rootScope', '$scope', 'Notification', 'Plan', 'Account', 'Subscription', 'PostalCode', function ($rootScope, $scope, Notification, Plan, Account, Subscription, PostalCode) {
    console.log('UserSubscriptionController')
    $scope.subscriptionPlan = false;
    account = Account.get({ id: 'me' }).$promise.then(function (account) {
      $scope.data = account;
      add_callbacks();
    });
    // initial state
    $scope.state = { current: 'plan' }

    $scope.$watch('data', function () {
      console.log('account data updated', $scope.data);
      load_subscription(account.id);
    })

    var cotar_plano = function () {
      if ($scope.data.account.new_max_team == 0) {
        $scope.data.account.new_account_type = 'basic';
      } else {
        $scope.data.account.new_account_type = 'enterprise';
      }


      $rootScope.$broadcast('rzSliderForceRender');

      $scope.state = { current: 'plan' }
      Plan.search({
        id: 'search',
        payment_term: $scope.data.account.new_payment_term,
        max_monitoring: $scope.data.account.new_max_monitoring,
        max_team: $scope.data.account.new_max_team,
        account_type: $scope.data.account.new_account_type
      }).$promise.then(function (result) {
        if (result.data) {
          console.log('Plan with result data', result.data)
          $scope.plan = result.data;
          //console.log(result.data.attributes);
        } else {
          console.log('Plan WITHOUT result data')
          $scope.plan = null;
        }
      }, function (error) {
        $scope.plan = null;
        console.log('erro');
        console.log(error);
        $scope.cotation_value = "N/A"
        $scope.error = error;
        Notification.info({
          delay: 1500,
          message: error.data.message,
          replaceMessage: true
        })
      })
    }

    $scope.cotar_plano = cotar_plano;

    add_callbacks = function () {
      var callback_plan_cotation = function (sliderId, modelValue, highValue, pointerType) {
        cotar_plano();
      }
      $scope.data.parameters.process.onChange = callback_plan_cotation;
      $scope.data.parameters.team.onChange = callback_plan_cotation;
      $scope.data.parameters.terms.onChange = callback_plan_cotation;
      $scope.data.parameters.terms['translate'] = translate_terms;
      $scope.data.parameters.terms['getLegend'] = translate_terms;
      if ($scope.data.account.new_payment_method == undefined) {
        $scope.data.account.new_payment_method = '0';
      }
      $scope.view_bank_split = false;
    };

    var translate_terms = function (value, sliderId, label) {
      switch (value) {
        case "weekly":
          return "Semanal"
        case "monthly":
          return 'Mensal';
        case "quarter":
          return "Trimestral";
        case "trimonthly":
          return "Trimestral";
        case 'half':
          return "Semestral";
        case 'yearly':
          return "Anual";
        default:
          return value;
      }
    };

    $scope.translate_status = function (status) {
      switch (status) {
        case "active":
          return "Ativo"
        case "inactive":
          return 'Inativo';
        case "canceled":
          return "Cancelado";
        case 'pending':
          return "Pendente";
        case 'payment_pending':
          return "Pagamento Pendente";
        case 'payment_pending':
          return "Pagamento Pendente";
        case 'processing':
          return "Em processamento"
        case 'paid':
          return "Pago";
        case 'available':
          return 'Disponível';
        case 'dispute':
          return 'Em disputa';
        case 'returned':
          return 'Estornado'
        default:
          return "Desconhecido"
      }
    }

    error = function (error) {
      console.log(error.message);
      Notification.warning(error.data.message);
    };

    // $scope.save_billing = function(action){
    //   console.log('save_billing');
    //   $scope.save(action);
    //   $scope.state.current = 'payment';
    // };

    $scope.save = function (action) {
      console.log('save me');
      $scope.data['update_action'] = action;
      if ($scope.data.id == undefined || $scope.data.id == undefined) {
        console.log('how to save me');
        $scope.data.$save().then(
          function (data) {
            console.log('sucessoooo');
            console.log(data);
            Notification.success('Dados de faturamento registrados com sucesso');
            add_callbacks();
            $scope.state.current = 'payment';
          }
          , error);
      }
      else {
        console.log('update me');
        $scope.data.$update().then(
          result => {
            console.log(result);
            Notification.success('Dados de faturamento atualizados com sucesso');
            add_callbacks();
            $scope.state.current = 'payment';
          },
          function (error) {
            console.log('error', error);
            var msg_error;
            if (error.data)
              msg_error = error.data.message || 'Erro ao atualizar'
            else
              msg_error = 'Erro ao atualizar faturamento'
            Notification.error(msg_error);
          }
        );
      }
    }

    $scope.bank_split = function () {
      console.log('AAAAAA')
      if ($scope.data != undefined && $scope.data.id != undefined) {
        $scope.data['update_action'] = 'bank_split_update';
      } else {
        $scope.data['update_action'] = 'bank_split';
      }
      console.log('BBBBBB');
      //$scope.save($scope.data['update_action']);
      //return ;


      if ($scope.data.id == undefined || $scope.data.id == undefined) {
        console.log('how to save me');
        $scope.data.$save().then(
          function (data) {
            console.log('sucessoooo');
            console.log(data);
            Notification.success('Boleto gerado com sucesso');
            $socpe.state.current = 'view_bank_split';
            load_subscription(data.id)
          }
          , error);
      }
      else {
        console.log('update me');
        $scope.data.$update().then(
          function (result) {
            console.log(result);
            Notification.success('Novo boleto gerado');
            $scope.state.current = 'view_bank_split';
            load_subscription(result.id)
          },
          function (error) {
            console.log('error', error);
            var msg_error;
            if (error.data)
              msg_error = error.data.message || 'Erro ao atualizar inscrição'
            else
              msg_error = 'Erro ao atualizar inscrição'
            Notification.error(msg_error);
          }
        );
      }

    }
    var load_subscription = function (id) {
      Subscription.get({ id: id }, function (result) {
        // agora devo apresentar o link do boleto pendente gerado neste plano
        console.log('+++++++ d')
        $scope.subscriptions = result.data;
        $scope.view_bank_split = true;
      }, function (error) {
        Notification.info('Erro ao Recuperar dados da assinatura para imprimir o boleto. por favor acesso o Menu Perfil/Extrato para imprimir.')
        Notification.error(error.data.message);
      })
    }

    // Auto-fill Address using Postal Code
    $scope.update_address = function () {
      PostalCode.get({ postal_code: $scope.data.address.postal_code },
        function (result) {
          console.log('ENDEREÇO ENCONTRADO', result)
          $scope.data.address.address_1 = result.logradouro;
          $scope.data.address.neighborhood = result.bairro;
          $scope.data.address.city = result.cidade;
          $scope.data.address.region = result.estado
        },
        function (error) {
          console.log(error)
          //Notification.warning("falha ao recuperar o e")
        }
      )
    }

    $scope.goto_billing = function () {
      console.log('plano selecionado, solicitando dados de faturamento')
      $scope.state.current = 'billing'
      document.querySelector("#cnpj_cpf").focus();
    }

    $scope.goto_payment = function () {
      console.log('goto_payment');
      if ($scope.save('address')) {
        $scope.state.current = 'payment';
        document.querySelector("#payment_method_boleto").focus();

      }
    }

    $scope.goto_plan = function () {
      $scope.state.current = 'plan';
    }


  }]);