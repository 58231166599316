'use strict';
angular.module('mineXplore')
  .service('GeoIp', ['$resource', function ($resource) {
    var geoip;

    geoip = $resource('https://ipinfo.io/json',
      {},
      {
        query: { method: 'GET', isArray: false }
      }
    );
    return geoip;
  }
  ]);
