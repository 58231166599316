angular.module('mineXplore')
    .controller('ProcessController', [
        '$scope',
        '$stateParams',
        '$rootScope',
        '$location',
        'Process',
        'Action',
        '$uibModal',
        function ($scope, $stateParams, $rootScope, $location, Process, Action, $uibModal) {
            console.log('ProcessController');
            //load data
            $scope.messageTest = "sera?";
            $scope.process = {};
            if ($stateParams.id && !$location.$$path.includes('projects')) {
                Process.get({ id: $stateParams.id },
                    function (data) {
                        $scope.process = data;
                    });
                Process.details({ id: $stateParams.id },
                    function (result) {
                        $scope.details = result.data;
                        $scope.details.attributes.selected = true;
                        //list_actions($stateParams.id);
                    });
            }
            //destroy with confirmation
            $scope.destroy = function () {
                var original;
                if (confirm("Tem certeza que deseja excluir?")) {
                    original = this.process;
                    return this.process.destroy(function () {
                        return $scope.processes = _.without($scope.processes, original);
                    });
                }
            };
            var list_actions = function (process_id) {
                Action.fetch(
                    { id: process_id },
                    function (response) {
                        $scope.process['actions'] = response.data;
                    },
                    function (error) { alert(error.message) }
                );
            }
            //create resource
            $scope.create = function (form) {
                return Process.save(this.process, function (process) {
                    return $location.path('/processes');
                });
            };
            //update resource
            $scope.update = function () {
                return Process.update(this.process, function (process) {
                    return $location.path('/processes');
                });
            };
            //edit resource
            $scope.edit = function (id) {
                return $location.path("/processes/" + id + "/edit");
            }
            $scope.newProcess = function () {
            }
            /**
             * Open Modal for Project Process Association
             */
            $scope.openModal = function (process) {
                var selectedProcesses = [];

                // angular.forEach($scope.process.features, function (v, k) {
                selectedProcesses.push(process.attributes.processo);
                // });

                var modalInstance = $uibModal.open({
                    templateUrl: 'project/modal_project_add.html',
                    controller: 'ProjectModalController',
                    resolve: { data: function () { return selectedProcesses; } }
                })
                    .closed.then(function () {
                        Process.details({ id: process.id },
                            function (result) {
                                $scope.details = result.data;
                            });
                    });
            };
        }]);
