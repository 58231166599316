/**
 User Seaches - Manage Seach parameters
 */
angular.module('mineXplore')
    .factory("Search", ['$resource', 'config', function($resource, config) {
        var preferences;
        preferences = $resource(config.apiUrl + config.baseUrl + "/user/searches/:id.json",
            { id: "@id" },
            {
                update: { method: "PUT" },
                destroy: { method: "DELETE" },
                save: {method: 'POST'},
                create:{ method: "POST"},
                get: {method: 'GET', isArray: false },
                query: {method: 'GET', isArray: true }
            }
        );
        return preferences;
    }
    ]);