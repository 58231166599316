'use strict';
angular.module('mineXplore')
  .service('ddToDms', [function () {
    return {
      'ddToDms': function (lat, lng) {
        var lat = lat;
        var lng = lng;
        var latResult, lngResult, dmsResult;
        lat = parseFloat(lat);
        lng = parseFloat(lng);
        latResult = (lat >= 0) ? '+;' : '-;';
        // Call to getDms(lat) function for the coordinates of Latitude in DMS.
        // The result is stored in latResult variable.
        latResult += this.getDms(lat);
        lngResult = (lng >= 0) ? '+;' : '-;';
        // Call to getDms(lng) function for the coordinates of Longitude in DMS.
        // The result is stored in lngResult variable.
        lngResult += this.getDms(lng);
        // Joining both variables and separate them with a space.
        dmsResult = latResult + ';' + lngResult;
        // Return the resultant string
        return dmsResult;
      },
      // This function returns the coordinate
      // conversion string in DD to DMS.
      'getDms': function (val) {
        var valDeg, valMin, valSec, result;
        val = Math.abs(val);
        valDeg = Math.floor(val);
        result = valDeg + ";";
        strl = 3 - String(valDeg).length;
        for (i = 0; i < strl; i++) { result = ('0' + result) }
        valMin = Math.floor((val - valDeg) * 60);
        result += valMin + ";";
        valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000;
        result += String(valSec).replace('.', ';');
        return result;
      }

    }

  }
  ]);
