angular.module('mineXplore')
.factory("SubstanceGroup", ['$resource', 'config', function($resource, config) {
  var SubstanceGroup;
  SubstanceGroup = $resource(config.apiUrl  + config.baseUrl + "/common/substance_groups/:id.json",
    { id: "@id" },
    {
      update: { method: "PUT" },
      destroy: { method: "DELETE" },
      //save: {method: 'POST'},
      //create:{ method: "POST"},
      query: {method: 'GET', isArray: false, cache: true },
      fetch: {method: 'GET', isArray: false, cache: true }
    }
    );
  SubstanceGroup.prototype.destroy = function(cb) {
    return SubstanceGroup.remove({
      id: this.id
    }, cb);
  };
  return SubstanceGroup;
}]);
