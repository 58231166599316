'use strict';
angular.module('mineXplore')
  .controller('ModalDocumentViewController',
    ['$scope',
      '$uibModal',
      'SweetAlert',
      'Notification',
      function ($scope, $uibModal, SweetAlert, Notification) {
        // console.log('ModalDocumentViewController...');
        $scope.data = {};
        var modalInstance;
        $scope.open_modal = function (url) {
          console.log(url);
          modalInstance = $uibModal.open({
            animation: true,
            backdrop: false,
            templateUrl: "common/doc_view_modal.html",
            scope: $scope
          });
          modalInstance.result.catch(function (res) {
              if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                console.log(res);
                throw res;
              }
            }
            );
        }
        $scope.cancel = function () {
          //modalInstance.cancel();
          modalInstance.close()
        }
        //
      }
    ]
  )
  ;
