angular.module('mineXplore')
  .factory("Invitation", ['$resource', 'config', function ($resource, config) {
    var Obj;
    Obj = $resource(config.apiUrl + config.baseUrl + "/users/invitation/:id.json",
      { id: "@id" },
      {
        update: { method: "PUT" },
        // destroy: { method: "DELETE" },
        // save: { method: 'PATCH' },
        // //create:{ method: "POST"},
        // query: { method: 'GET', isArray: false }
      }
    );
    return Obj;
  }
  ]);
