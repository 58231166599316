angular.module('mineXplore')
.factory("SubstanceCategory", ['$resource', 'config', function($resource, config) {
  var SubstanceCategory;
  var items = [];
  SubstanceCategory = $resource(config.apiUrl + config.baseUrl + "/common/substance_categories/:id.json",
    { id: "@id" },
    {
      update: { method: "PUT" },
      destroy: { method: "DELETE" },
      //save: {method: 'POST'},
      //create:{ method: "POST"},
      query: {method: 'GET', isArray: false, cache: true },
      fetch: {method: 'GET', isArray: false, cache: true }
    }
    );
  SubstanceCategory.prototype.destroy = function(cb) {
    return SubstanceCategory.remove({
      id: this.id
    }, cb);
  };
  SubstanceCategory.cache = function(){
    if(items.length == 0 ){
       return SubstanceCategory.query(
        function (result) {
          console.log(' substance_categories api data returned');
          items = result;
        }
        );
    }else{
      console.log(' substance_categories data cached returned');
      return items;
    }
  }
  return SubstanceCategory;
}]);
