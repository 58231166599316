'use strict';
angular.module('mineXplore')
.service('PostalCode', ['$resource', function($resource) {
    var PostalCode;
    PostalCode = $resource('https://cors-anywhere.herokuapp.com/https://api.postmon.com.br/v1/cep/:postal_code',
      { postal_code: '@postal_code' },
      {
        query: {method: 'GET', isArray: false }
      }
    );
    PostalCode.prototype.update_address = function(postalCode, objAddress){
	    var zipcode = postalCode.replace('-','');
	    // PostalCode.get({postal_code: zipcode}, function(r){
	    //   $scope.user.subscriber_attributes.address_attributes.address1 = r['logradouro']
	    //   $scope.user.subscriber_attributes.address_attributes.neighborhood = r['bairro']
	    //   $scope.user.subscriber_attributes.address_attributes.city = r['cidade']
	    //   $scope.user.subscriber_attributes.address_attributes.region = r['estado']
	    // });

    };
    return PostalCode;
  }
]);
