/**
  User Seaches - Manage Seach parameters
*/
angular.module('mineXplore')
.factory("CalendarHelper", ['config', function( config) {
  var dt_ini;
  var dt_end;
  return {
    dt_ini:{
      get: function(){
        return this.dt_ini;
      },
      set: function(dt_ini){
        console.log( "set dt_ini");
        this.dt_ini = dt_ini;
      }
    },
    dt_end:{
      get: function(){
        return this.dt_end;
      },
      set: function(dt_end){
        console.log( "set dt_end");
        this.dt_end = dt_end;
      }
    },
    calendarOpts: function(){
      return {
          formatYear: 'yy',
          maxDate: new Date(2020, 12, 31),
          minDate: new Date(2019, 1, 1),
          startingDay: 1,
          showWeeks: true,
      };
    }

  }
}
]);