angular.module('mineXplore')

    .config(['$locationProvider', function ($locationProvider) {
        $locationProvider.hashPrefix('');
        $locationProvider.html5Mode(false);
    }])

    .config(['uiGmapGoogleMapApiProvider', "$httpProvider", function (GoogleMapApi, $httpProvider) {
        GoogleMapApi.configure({
            key: 'AIzaSyDzyTGxqIELjxhMqQ10RvlDRrhjjSrKw8E',
            v: '3.18',
            libraries: 'weather,geometry,visualization,places,drawing'
        });
        $httpProvider.defaults.headers.common['X-Requested-With'] = 'AngularXMLHttpRequest';
    }])
    .service('APIInterceptor', [function() {
        var service = this;
        service.request = function(config) {
          config.headers.SameSite = "None";
          return config;
      };
    }])
    .config(function ($httpProvider) {
        //$httpProvider.interceptors.push('httpInterceptor');
        $httpProvider.interceptors.push('httpLoading');
    })
    .config(function (NotificationProvider) {
        NotificationProvider.setOptions({
            delay: 5000,
            startTop: 30,
            startRight: 40,
            verticalSpacing: 20,
            horizontalSpacing: 40,
            positionX: 'center',
            positionY: 'top'
        });
    });