'use strict';
angular.module('mineXplore')
  .service('gridHelper', [function () {

    return {
      'animate': function ($scope, $rootScope) {
        if ($scope.gridCollapsed) {
          $scope.gridCollapsed = !$scope.gridCollapsed;
          $("#map_mal").fadeOut(1000);
          $("#map").fadeOut(1000);

          $(".hide-scroll").first().animate({
            "margin-left": "30%",
            "width": "70%"
          });
          $(".header-spans").first().animate({
            "width": "69%"
          });
          $(".containers").first().animate({
            "margin-top": "10%"
          });
          $("#grid-view").animate({
            "width": "90%",
            "margin-top": "30px",
            "z-index": 1
          }, 500);
          $(".filters").animate({ "margin-top": "30px" }, 500);
          $(".header-process-b-container").css("width", "");
          $(".process-containner").css("width", "");
          $(".header-process").each(function (index, element) {
            // console.log("css antees",  $(element));
            $(element).css("width", "");
          });
          $(".process-containner").each(function (index, element) {
            // console.log("containeer");
            // console.log("css antees",  $(element).css());
            $(element).css("width", "100%");
          });
          $(".header-process").each(function (index, element) {
            // console.log("containeer");
            $(element).css("width", "45%");
          });
          $(".header-process-b-container").each(function (index, element) {
            // $(element).css("width", "");
            $(element).css("width", "45%");
            // $(element).css("mrgin-left", "20%");
          });
          $(".header-process-b-container").each(function (index, element) {
            // $(element).css("width", "");
            $(element).css("width", "45%");
            // $(element).css("mrgin-left", "20%");
          });
          $(".header-process-b-container").each(function (index, element) {
            // console.log("cai", element);
            $(element).css("width", "45%");
            // $(element).css("mrgin-left", "20%");
          });
        }
        else {
          $scope.gridCollapsed = !$scope.gridCollapsed;
          $("#map_mal").fadeIn(1000);
          // $(".app-content").fadeOut(1000, function() {
          //         $(this).css("background-color", "#F1B33C").fadeIn(1000);});
          // })​
          $("#grid-view").prop("style", "");
          $(".filters").prop("style", "");
          $(".hide-scroll").first().prop("style", "");
          $(".containers").first().prop("style", "");
          $(".header-spans").first().prop("style", "");
          $(".header-process-b-container").css("width", "");
          $(".process-containner").css("width", "");
          $(".header-process").each(function (index, element) {
            // console.log("css antees",  $(element));
            $(element).prop("style", "");
          });
          $(".process-containner").each(function (index, element) {
            // console.log("containeer");
            // console.log("css antees",  $(element).css());
            $(element).prop("style", "");
          });
          $(".header-process").each(function (index, element) {
            // console.log("containeer");
            $(element).prop("style", "");
          });
          $(".header-process-b-container").each(function (index, element) {
            // $(element).css("width", "");
            $(element).prop("style", "");
            // $(element).css("mrgin-left", "20%");
          });
          $(".header-process-b-container").each(function (index, element) {
            // $(element).css("width", "");
            $(element).prop("style", "");
            // $(element).css("mrgin-left", "20%");
          });
          $(".header-process-b-container").each(function (index, element) {
            $(element).prop("style", "");
          });
        }
      }
    }
  }
  ]);
