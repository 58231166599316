angular.module('mineXplore')
  .controller('ProjectModalController', function ($scope, $uibModalInstance, data, Project, SweetAlert, Notification) {
    $scope.project = { processes: [{ id: '', processo: '' }] };
    $scope.processes = data;
    $scope.processesList = '';
    Project.query(function (result) {
      $scope.projects = result.data;
    });

    var count = 0;
    var limit = 15;
    angular.forEach($scope.processes, function (v, k) {
      if (count < limit) {
        if (((count + 1) == $scope.processes.length) || (count + 1) == limit) {
          $scope.processesList += v;
        } else {
          $scope.processesList += v + ', ';
        }
      }
      count++;
    });
    if ($scope.processes.length > limit) {
      var countHide = $scope.processes.length - limit;
      var strProc = " processos";
      if (countHide == 1) {
        strProc = " processo";
      }
      $scope.processesList += " e mais " + countHide + strProc;
    }

    $scope.ok = function () {
      //obtem projetos selecionados a grava o id do projeto
      var selectedProjects = [];
      angular.forEach($scope.projects, function (v, k) {
        if (v.selected == true) {
          selectedProjects.push({ 'id': v.id, 'processes': [] });
        }
      });

      //insere processos para cada projeto selecionado
      if (selectedProjects.length > 0) {
        $uibModalInstance.close();
        angular.forEach(selectedProjects, function (v, k) {
          angular.forEach($scope.processes, function (value, key) {
            selectedProjects[k].processes.push({ "processo": value });
          });
          return Project.update({ id: selectedProjects[k].id, attributes: selectedProjects[k] }).$promise.then(
            function (response) {
              Notification.success("A partir de agora você receberá alertas sobre os processos selecionados")
              return true;
            },
            function (error) {
              console.log(error);
              msg_error = "Ocorreu um erro ao monitorar os processos";
              if (error.data && error.data.message) {
                msg_error = error.data.message;
              }
              $scope.project.name = null;
              Notification.error(msg_error);
            }
          );;
        });

      } else {
        Notification.info("Você precisa associar os processos a pelo menos 1 projeto.")
      }
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.addProject = function () {
      if ($scope.project.name === undefined) {
        Notification.info("Nome do projeto nao pode ficar em branco!")
      } else {
        return Project.save({ attributes: $scope.project },
          function (result) {
            //mark as 'selected before'
            result.data.selected = true;
            $scope.projects.push(result.data);

            if ($scope.ok()) {
              Notification.success("A partir de agora você receberá alertas sobre os processos selecionados");
              $scope.project.name = "";
            }
          }, function (error) {
            msg_error = "Ocorreu um erro ao monitorar os processos";
            if (error.data && error.data.message) {
              msg_error = error.data.message;
            }
            Notification.error(msg_error);
          });
      }
    };
  });


