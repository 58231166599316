angular.module('mineXplore')
.factory("Event", ['$resource', 'config', function($resource, config) {
  var Event;
  Event = $resource(config.apiUrl + config.baseUrl + "/common/events/:id",
    { id: "@id" },
    {
      get: {method: 'GET', isArray: false },
      query: {method: 'GET', isArray: false }
    }
    );
  return Event;
}
]);