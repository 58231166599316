'use strict';
angular.module('mineXplore')
  .controller('PrjDouController',
    ['$scope', '$window', 'Project', 'ProjectsDou', '$state', 'CalendarHelper', 'DouModal', function ($scope, $window, Project, Dou, $state, cal, DouPdf) {
      $scope.project_id = $state.params.id;
      Project.get({ id: $state.params.id },
        function (response) {
          console.log(response);
          $scope.project = response.data;
        },
        function (error) {
          console.log(error);
        });
      var load_data = function () {
        $scope.project = { id: $state.params.id };
        var params = { id: $state.params.id, dt_ini: cal.dt_ini.get(), dt_end: cal.dt_end.get() };
        Dou.query(params,
          function (response) {
            console.log(response);
            $scope.data = response.data;
          },
          function (error) {
            console.log(error);
          });
      };
      $scope.load_data = load_data;
      $scope.open_dou = function (event) {
        DouPdf.open($scope, event.date);
      };

    }
    ]
  )
  ;
