'use strict';
angular.module('mineXplore')
  .controller('DashboardMovimentacoesController',
    ['$scope',
    'EnterpriseHeader',
    'GlobalSicop',
    'GlobalTracking',
    'CalendarHelper',
    'Notification',
    function ($scope, Header, GlobalSicop, GlobalTracking, cal, Notification) {
      Header.get({id: 'header'}, function(response){
        $scope.company = response.data.attributes;
      });

      // card helper

      var sicop_card_origin =function (data){
        console.log(data);
      }
      var sicop_card_destination = function(data){
        console.log(data);
      }
      var sei_card = function(data){
        console.log(data);
      }
      var to_card = function(data){
        if(data['origin_department']==undefined){
          $scope.data.push(sei_card(data));
        }else{
          $scope.data.push(sicop_card_origin(data));
          $scope.data.push(sicop_card_destination(data));
        }


      }
      //
      var load_data = function(){
        //$scope.data = undefined;
        var dt_param = { dt_ini: cal.dt_ini.get(), dt_end: cal.dt_end.get() };
        $scope.data_cards = [];
        // GlobalSicop.query(dt_param, function(response){
        //   console.log(response);
        //   $scope.data_sicop = response.data;
        //   angular.forEach($scope.data_sicop, data=> to_card(data))
        // }, function(error){
        //   Notification.error('erro inexperado ao carregar movimentações sicop');
        // });
        GlobalTracking.query(dt_param, function(response){
          console.log(response);
          $scope.data = response.data;
        }, function(error){
          Notification.error('erro inexperado ao carregar movimentações (SICOP + SEI)');
        });
      }
      $scope.load_data = load_data;
      $scope.has_data = function(){
        dt = []
        if($scope.data==undefined){ return false; }
        $scope.data.map(function(m){ dt.push(m.attributes.processes) })
        if(dt.length > 0 && dt[0].length > 0 ){ return true; }
      }
    }
    ]
  )
  ;
