'use strict';
angular.module('mineXplore')
  .controller('GlobalDocumentsModalController',
    ['$scope',
      '$uibModal',
      'Project',
      'DocumentType',
      'ProjectDocument',
      'AreaProject',
      'DouModal',
      'Notification',
      function ($scope, $uibModal, Project, DocumentType, Document, AreaProject, DocModal, Notification) {
        $scope.data = {};
        console.log('GlobalDocumentsModalController...');
        $scope.update_areas = function () {
          console.log('update_areas');
          AreaProject.get({ project_id: $scope.data.project_id }, function (response) {
            console.log('area project');
            $scope.areas = response.data;
            console.log(response);
          }, function (error) {
            console.log('error load areas projects');
            console.log(error);
          });
        }
        $scope.complete = function (string) {
          var output = [];
          angular.forEach($scope.areas, function (monit) {
            if (monit.attributes.area.processo.toLowerCase().indexOf(string.toLowerCase()) >= 0) {
              output.push(monit);
            }
          });
          $scope.filterArea = output;
        }
        $scope.fillTextbox = function (string) {
          $scope.data.process = string;
          $scope.filterArea = null;
        }

        var modalInstance;
        $scope.open_modal = function () {
          Project.query(function (result) {
            console.log('project list result');
            $scope.projects = result.data;
          });
          DocumentType.get(function (response) {
            console.log('document type');
            $scope.document_types = response
            console.log(response);
          }, function (error) {
            console.log('error on document type');
            console.log(error);
          });

          modalInstance = $uibModal.open({
            animation: true,
            backdrop: false,
            templateUrl: "dashboard/global/global-documentos-modal.html",
            scope: $scope
          });
          modalInstance.result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
              console.log(res);
              throw res;
            }
          });
        }
        $scope.cancel = function () {
          modalInstance.close();
        }
        $scope.uploadImage = function (change) {
          console.log('uploadImage');
          let event = change.target;
          $scope.data['file_name'] = event.files[0].name;
          if (event.files && event.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
              console.log('onload file');
              $scope.data['file'] = e.target.result;
            }
            reader.readAsDataURL(event.files[0]);
          }
        }

        $scope.save = function (add_another) {
          $scope.errors = null;
          console.log('save document');
          console.log($scope.data);
          doc = new Document({ project_id: $scope.data.project_id, document: $scope.data, file: $scope.fd });
          savePromisse = doc.$save();
          Notification.info("Salvando arquivo!");
          savePromisse.then(function (response) {

            angular.forEach($scope.$parent.data, function (prj) {
              if (prj.id == $scope.data.project_id)
                prj.attributes.areas.push(response.document);
            });
            Notification.success("O arquivo foi salvo na núvem mineXplore com sucesso!");
            if (add_another != true) {
              $scope.data = {};
              modalInstance.close();
              if($scope.$parent.load_data != undefined){
                $scope.$parent.load_data();
              }
            }
            else {
              $scope.data.description = '';
              $scope.data.title = '';
            }

          }, function (error) {
            $scope.errors = error;
          })
        }

        $scope.view_file = function (file) {
          DocModal.open(file.link)
        }

      }
    ]
  )
  ;
