angular.module('mineXplore')
.factory("Subscription", ['$resource', 'config', function($resource, config) {
  var Subscription;
  Subscription = $resource(config.apiUrl  + config.baseUrl + "/common/payment_gateway/subscriptions/:id",
    { id: "@id" },
    {
      update: { method: "PUT" },
      destroy: { method: "DELETE" },
      save: {method: 'POST'},
      create:{ method: "POST"},
      get: {method: 'GET', isArray: false, cache: false },
      query: {method: 'GET', isArray: false, cache: false },
      fetch: {method: 'GET', isArray: false, cache: false }
    }
    );
  return Subscription;
}]);
