'use strict';
angular.module('mineXplore')
  .controller('PrjModalMonitorarController',
    ['$scope',
      '$uibModal',
      '$state',
      'Project',
      'SweetAlert',
      'Notification',
      function ($scope, $uibModal, $state, Project, SweetAlert, Notification) {
        console.log('PrjModalMonitorarController...');
        $scope.data = {};
        var msg_success = {
          title: "Sucesso!",
          text: "A partir de agora você receberá alertas sobre os processos selecionados",
          type: "success"
        }
        var msg_error_monitoring = {
          title: "Ops!",
          text: "Ocorreu um erro ao monitorar os processos",
          type: "error"
        }
        var modalInstance;
        $scope.open_modal = function () {
          modalInstance = $uibModal.open({
            animation: true,
            backdrop: false,
            templateUrl: "dashboard/project/prj-modal-monitorar.html",
            scope: $scope
          });
          modalInstance.result.catch(function (res) {
              if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                console.log(res);
                throw res;
              }
          });
        }
        $scope.cancel = function () {
          modalInstance.close();
        }

        $scope.monitorar = function(){
          $scope.data['processes'] = [];
            angular.forEach($scope.data.input_processes.match(/((\d{6}|\d{3}\.\d{3})\/\d{4})+/gm), function (value, key) {
              $scope.data.processes.push({ "processo": value });
            });
          $scope.data['id'] = $state.params.id;
          return Project.update({
            id: $state.params.id,
            attributes: $scope.data
          }
            ).$promise.then(
            function (response) {
              delete $scope.data.input_processes;
              modalInstance.close();
              Notification.success("A partir de agora você receberá alertas sobre os processos selecionados");
              return true;
            },
            function (error) {
              console.log(error);
              var msg_error = error.data.message || "Ocorreu um erro ao monitorar os processos";
              Notification.error(msg_error)
            }
          );
        }
      }
    ]
  )
  ;
