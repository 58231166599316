angular.module('mineXplore')
  .factory("Stats", ['$resource', 'config', function ($resource, config) {
    var Dou = $resource(config.apiUrl + config.baseUrl + "/common/stats/:id.json",
      { id: "@id" },
      {
        get: { method: 'GET', isArray: false },
        fetch: { method: 'GET', isArray: true },
      }
    );
    return Dou;
  }
  ]);
