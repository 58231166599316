'use strict';
angular.module('mineXplore')
  .controller('DashboardController',
    ['$scope', '$window', '$state',
      'GlobalDashboardChartDouScm',
      'GlobalDashboardChartTerms',
      'GlobalDashboardChartSicop',
      'GlobalDashboardChartDocuments',
      'EnterpriseHeader',
      'CalendarHelper',
      function ($scope, $window, $state, DouScm, Terms, Sicop, Documents, Header, cal) {

        Header.get({ id: 'header' }, function (response) {
          $scope.company = response.data.attributes;
        });

        var load_data = function () {
          var dt_param = { dt_ini: cal.dt_ini.get(), dt_end: cal.dt_end.get() };
          DouScm.query(dt_param, function (response) {
            console.log(response);
            $scope.chart_miner_actions = response;
            var get_clicked_serie = function (points, event) {
              var chart = event[0]._chart.controller;
              var activePoint = chart.getElementAtEvent(points);
              console.log('achar o activePoint')
              if (activePoint.length > 0) {
                  var dataset = activePoint[0]._datasetIndex;
                  var serie = $scope.chart_miner_actions.series[dataset];
                  return(serie);
              }
            };
            var dou_scm_click = function (e, item) {
              if(item != undefined && item[0] != undefined){
                obj_id = $scope.chart_miner_actions.options.ids[item[0]._index]
                if (obj_id != undefined) {
                  clicked_serie = get_clicked_serie(e,item);
                  if(clicked_serie == 'DOU'){
                    $state.go('prj_dou', { id: obj_id })
                  }else if(clicked_serie == 'SCM')
                    $state.go('prj_eventos', { id: obj_id })
                }
              }
            };
            response.options['onClick'] = dou_scm_click;

          });
          Terms.query(dt_param, function (response) {
            $scope.sla_panel_chart = response;
            var terms_click = function (e, item) {
              if(item != undefined && item[0] != undefined){
                obj_id = $scope.sla_panel_chart.options.ids[item[0]._index]
                if (obj_id != undefined) {
                  $state.go('prj_prazos', { id: obj_id })
                }
              }
            };
            response.options['onClick'] = terms_click;
          });
          Sicop.query(dt_param, function (response) {
            console.log('Sicop data: ', response);
            $scope.sicop_panel_chart = response;
            var tracking_click = function (e, item) {
              if(item != undefined && item[0] != undefined){
                obj_id = $scope.sicop_panel_chart.options.ids[item[0]._index]
                if (obj_id != undefined) {
                  $state.go('prj_movimentacoes', { id: obj_id })
                }
              }
            };
            response.options['onClick'] = tracking_click;
          });
          Documents.query(dt_param, function (response) {
            $scope.chart_documents = response;
            var doc_click = function (e, item) {
              if(item != undefined && item[0] != undefined){
                obj_id = $scope.chart_documents.options.ids[item[0]._index]
                if (obj_id != undefined) {
                  $state.go('prj_documentos', { id: obj_id })
                }
              }
            };
            response.options['onClick'] = doc_click;

          });
        }
        $scope.load_data = load_data;
      }
    ]
  );
