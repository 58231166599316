'use strict';
angular.module('mineXplore')
  .service('olFeatureImport', ['$resource', function ($resource) {
    var _map;
    var dragAndDropInteraction = new ol.interaction.DragAndDrop({
      formatConstructors: [
        ol.format.GPX,
        ol.format.GeoJSON,
        ol.format.IGC,
        ol.format.KML,
        ol.format.TopoJSON
      ]
    });

    var init = function (map) {
      _map = map;
      _map.addInteraction(dragAndDropInteraction);
    }

    var setEvents = function () {
      dragAndDropInteraction.on('addfeatures', function (event) {
        var vectorSource = new ol.source.Vector({
          features: event.features
        });
        _map.addLayer(new ol.layer.Vector({
          source: vectorSource,
          title: 'Camada temporária'
        }));
        _map.getView().fit(vectorSource.getExtent());
      });
      _map.on('pointermove', function (evt) {
        if (evt.dragging) {
          return;
        }
        //var pixel = _map.getEventPixel(evt.originalEvent);
        //displayFeatureInfo(pixel);
      });

      _map.on('click', function (evt) {
        displayFeatureInfo(evt.pixel);
      });

    }

    var displayFeatureInfo = function (pixel) {
      var features = [];
      _map.forEachFeatureAtPixel(pixel, function (feature) {
        features.push(feature);
      });
      if (features.length > 0) {
        var info = [];
        var i, ii;
        for (i = 0, ii = features.length; i < ii; ++i) {
          info.push(features[i].get('name'));
        }
        if (document.getElementById('info') != null) {
          document.getElementById('info').innerHTML = info.join(', ') || '&nbsp';
        }

      } else {
        if (document.getElementById('info') != null) {
          document.getElementById('info').innerHTML = '&nbsp;';
        }

      }
    };

    return {
      'init': function (map) {
        init(map)
        setEvents();
      },
      'removeInteraction': function () {
        _map.removeInteraction(dragAndDropInteraction);
      }
    }
  }
  ]);
