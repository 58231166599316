angular.module('mineXplore')
  .factory("CustomMessage", ['$resource', '$uibModal', 'config', function ($resource, $uibModal, config) {
    var Obj = $resource(config.apiUrl + config.baseUrl + "/common/custom_messages/:id.json",
      { id: "@id" },
      {
        get: { method: 'GET', isArray: true },
      }
    );

    Obj.show_modal = function (title, url) {
      modalInstance = $uibModal.open({
        animation: true,
        backdrop: false,
        templateUrl: "common/doc_view_modal.html",
        resolve: {
          url: function () {
            return url;
          },
          title: function () {
            return title;
          },
        },
        controller: ['$scope', '$sce', function ($scope, $sce) {
          $scope.title = $sce.trustAsResourceUrl($scope.$resolve.title)
          $scope.url = $sce.trustAsResourceUrl($scope.$resolve.url);
          $scope.cancel = function () {
            modalInstance.close()
          };
        }]
        ,
      });
    };

    Obj.display_messages = function () {
      Obj.get(function (result) {
        angular.forEach(result, function (msg) {
          Obj.show_modal(msg.message, msg.url);
        })
      });
    }

    return Obj;
  }
  ]);
