angular.module('mineXplore')
.factory("Stage", ['$resource', 'config', function($resource, config) {
  	var Obj;
  	Obj = $resource(config.apiUrl  + config.baseUrl + "/common/stages/:id",
    	{ id: "@id" },
    	{
      		query: {method: 'GET',cache: false, isArray: false },
          get: {method: 'GET',cache: false },
      		update: {method: 'PUT'},
    	});
  	return Obj;
}]);
