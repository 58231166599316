/*
  https://embed.plnkr.co/bLzRvXvOeW6hHC2jsaX1/
  http://embed.plnkr.co/defFbc/
*/
'use strict';
angular.module('mineXplore')
  .service('olHelper', ['LayerGroups', 'olStyles', 'olDraw', 'UserBuffer', 'olFeatureImport', '$http', 'config', function (LayerGroups, olStyles, olDraw, UserBuffer, olFeatureImport, $http, config) {
    var COORD_SYSTEM_GPS = 'EPSG:4326';  // gps (long/lat) coord system..
    var COORD_SYSTEM_OSM = 'EPSG:3857';  // SphericalMercatorCoords - google and OSM's coord system..
    var pinIcon = '/images/ctx_menu/maps-and-flags.png';
    let vectorSource = [];
    let vectorLayer = [];
    let layerGroup = [];

    //remove all layers
    let cleanUpLayers = function (map) {
      vectorLayer.forEach(function (layer) {
        layer.getSource().clear()
        map.removeLayer(layer);
      });
    }

    function zoomToFeatureId(map, id) {
      map.getLayers().forEach(function (layer) {
        if (layer instanceof ol.layer.Group) {
          layer.getLayers().forEach(function (layer) {
            if (layer.getSource && layer.getSource() instanceof ol.source.Vector) {
              layer.getSource().forEachFeature(function (feature) {
                if (feature.getProperties('data').id == id) {
                  map.getView().fit(feature.getGeometry().getExtent(), map.getSize());
                }
              });
            }
          });
        }
      });
    }
    function addLayerGroups(map) {
      LayerGroups.query({}, function (result) {
        console.log('groups');
        angular.forEach(result.data, function (group) {
          var layer_group = new ol.layer.Group({ title: group.attributes.title, name: group.attributes.name });
          addLayers(map, layer_group, group.attributes.layers);
        });
      })
    }
    function dynamic_src(data) {
      if (data.source_type == 'TileWMS') {
        return new ol.source.TileWMS({
          url: data.url,
          preload: Infinity,
          projection: data.projection,
          params: {
            LAYERS: data.layers,
            TRANSPARENT: data.transparent,
            FORMAT: data.format,
            VERSION: data.version || "1.3.0",
            TILED: data.tiled,
            STYLES: data.styles,
            INFO_FORMAT: data.info_format,
          },
          serverType: data.server_type,
        });
        return layer;
      } else if (data.source_type == 'KML') {

        return new ol.source.Vector({
          url: data.url,
          format: new ol.format.KML(),
        });
      } else if (data.source_type == 'GeoJSON') {
        return new ol.source.Vector({
          //url: 'http://arcgisserver.cprm.gov.br:6080/arcgis/rest/services/Geofisica/Geofisica_UTh/ImageServer',
          crossOrigin: 'anonymous',
          url: function (extent) {
            return 'https://ahocevar.com/geoserver/wfs?service=WFS&' +
              'version=1.1.0&request=GetFeature&typename=osm:water_areas&' +
              'outputFormat=application/json&srsname=EPSG:3857&' +
              'bbox=' + extent.join(',') + ',EPSG:3857';
          },

          format: new ol.format.GeoJSON(),
          strategy: ol.loadingstrategy.bbox
        })
      }
    }

    let load_user_buffer = function (map) {

      UserBuffer.query({}, function (data) {
        layerGroup = new ol.layer.Group({
          title: 'Minhas Marcações',
          name: 'UserFeatures'
        });
        angular.forEach(data.features, function (ft) {
          if (ft.geometry != null) {

            var niconStyle = new ol.style.Style({
                image: new ol.style.Icon({ scale: .6, src: pinIcon }),
                text: new ol.style.Text({
                  offsetY: 25,
                  text: ft.properties.name,
                  font: '15px Open Sans,sans-serif',
                  fill: new ol.style.Fill({ color: '#111' }),
                  stroke: new ol.style.Stroke({ color: '#eee', width: 1 })
                })
            })
            vectorSource = new ol.source.Vector({});
            var label = olStyles.text_label(ft.properties.name);
            let layerStyle = olStyles.setStyle(ft, label);
            console.log('check HERE');
            if(ft.geometry.type=='Point'){
              vectorSource.addFeature(json_to_feature(ft, niconStyle, label));
            }else{
              vectorSource.addFeature(json_to_feature(ft, layerStyle, label));
            }

            vlayer = new ol.layer.Vector({
              source: vectorSource,
              title: ft.properties.name,
              visible: true,
              style: niconStyle,
            });
            layerGroup.getLayers().getArray().push(vlayer);
          }
        })
        map.addLayer(layerGroup);
      }, function (error) {
        console.log(error)
      })
    }

    function create_layer(data) {
      layer_params = {
        title: data.title,
        source: dynamic_src(data),
        opacity: data.opacity,
        visible: data.visible
      };
      if (data.source_type == 'KML') {
        return new ol.layer.Vector(layer_params);
      } else {
        return new ol.layer.Tile(layer_params);
      }
    }
    function addLayers(map, layer_group, layers) {
      console.log('addLayers');

      var add_layer_callback = function (data) {
        if (data.source_type == 'KML') {
          layer_group.getLayers().push(create_layer(data));
        } else {
          layer_group.getLayers().push(create_layer(data));
        }

      }
      angular.forEach(layers, add_layer_callback);
      map.addLayer(layer_group);
    }

    /**
     * Click Info - Get Layer Info
     */
    var info_click_interaction = new ol.interaction.Select();
    let click_info = function (map, callback_fn) {
      if (info_click_interaction.getMap() == null) {
        map.addInteraction(info_click_interaction);
        info_click_interaction.on('select', callback_fn);
      }
      info_click_interaction.setActive(true);
    }
    let disable_info_click_interaction = function (map) {
      console.log("disable_info_click_interaction");
      //map.removeInteraction(info_click_interaction);
      info_click_interaction.setActive(false)
    }


    var pointer_move_interaction = new ol.interaction.Select({ condition: ol.events.condition.pointerMove });;
    let pointer_move = function (map, callback_fn) {
      console.log('pointer_move');
      if (pointer_move_interaction.getMap() == null) {
        map.addInteraction(pointer_move_interaction);
        pointer_move_interaction.on('select', callback_fn);
      }
      pointer_move_interaction.setActive(true);
    }
    let disable_pointer_move_interaction = function (map) {
      console.log("disable_pointer_move_interaction");
      //map.removeInteraction(info_click_interaction);
      pointer_move_interaction.setActive(false)
    }


    let json_to_feature = function (featureJson, layerStyle) {
      if (featureJson.geometry != undefined) {
        let geom;
        if (featureJson.geometry.type == 'Polygon') {
          geom = (new ol.geom.Polygon(featureJson.geometry.coordinates)).transform(COORD_SYSTEM_GPS, COORD_SYSTEM_OSM);
        } else if (featureJson.geometry.type == 'Point') {
          geom = (new ol.geom.Point(featureJson.geometry.coordinates)).transform(COORD_SYSTEM_GPS, COORD_SYSTEM_OSM);
        } else if (featureJson.geometry.type == 'Circle') {
          geom = (new ol.geom.Circle(featureJson.geometry.coordinates)).transform(COORD_SYSTEM_GPS, COORD_SYSTEM_OSM);
        } else if (featureJson.geometry.type == 'MultiPolygon') {
          geom = (new ol.geom.MultiPolygon(featureJson.geometry.coordinates)).transform(COORD_SYSTEM_GPS, COORD_SYSTEM_OSM);
        }

        var feature = new ol.Feature({
          geometry: geom,
          data: featureJson.properties,
          processo_id: featureJson.id,
          id: featureJson.id
        });
        feature.setStyle(layerStyle);
        return feature;
      }
    }

    let featureInfo = function (evt, _map) {
      if (angular.isUndefined(document.getElementById('l_l_accordion')) || document.getElementById('l_l_accordion') == null) {
        return false;
      }
      document.getElementById('l_l_accordion').style.display = "block";
      document.getElementById('info').innerHTML = '';
      document.getElementById('layers_legend').innerHTML = '';
      const ajax_request = function (url, layer) {
        $http.get(url)
          .then(function (response) {
            // console.log('layer',layer);
            // console.log('response',response);
            /**
             * A informação do layer (response.data), que é recebida em text/html, varia dependendo do seu tipo, já que cada grupo possui origem diferentes
             * Um exemplo é o grupo 'Diversos', o qual a chamada não é compativel com o tipo text/html, retornando como data uma mensagem de erro
             * Como a informação vem em texto, se um padrão não for respeitado para todos os layers, se torna difícil transformar esse texto em objeto
             * Uma alternativa interessante seria utilizar a imagem da legenda ( layers_legend / legendImg ), apesar de algumas legendas não possuirem, há a possibilidade de fazer as nossas próprias
             * !!Discutir viabilidade das duas opções!!
             **/
            document.getElementById('info').innerHTML += '<div class="layers-legend-title">' + decodeURIComponent(layer.getProperties()['title']) + '</div>';
            document.getElementById('info').innerHTML += '<div class="layers-legend-data">' + decodeURIComponent(response.data) + '</div>';
          });
      }
      const legend_request = function (wmsSource, layer) {
        var url_legend = wmsSource.getUrls();
        var legendImg = document.createElement('img');
        var url_join = url_legend[0].match(/\?/) ? '&' : '?';


        legendImg.src = url_legend + url_join + 'request=GetLegendGraphic&version=' + layer.getProperties().source.getParams().VERSION + '&sld_version=1.1.0&layer=' + layer.getProperties().source.getParams().LAYERS + '&format=image/png&STYLE=default';
        // $http.get(legendImg.src, responseType = 'arraybuffer')
        //   .then(function (response) {
        //     //console.log(response.data);
        //     legendImg.src = 'data:image/png;base64,' + btoa(unescape(encodeURIComponent(response.data)));

        //     document.getElementById('layers_legend').appendChild(legendImg);

        //   });
        document.getElementById('layers_legend').appendChild(legendImg);
      }

      const getWmsInfo = function (layer) {
        console.log('getWmsInfo');
        if (layer.get('visible') == true && layer.get('type') != 'base') {
          wmsSource = layer.getSource();
          var viewResolution = /** @type {number} */ (_map.getView().getResolution());
          if ((wmsSource instanceof ol.source.TileWMS)) {
            //console.log('not vector layer');
            var format = layer.getSource().getParams()['INFO_FORMAT'];
            //console.log('info_format: ' + format);
            var url = wmsSource.getGetFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', { 'INFO_FORMAT': format });
            if (url) {
              //console.log('add html info');
              ajax_request(url, layer);
              //TODO: Move the following code to an separated function in order to show up the map legends
              legend_request(wmsSource, layer);
            }
          }
        }
      };
      _map.getLayers().forEach(function (a, b) {
        if (a instanceof ol.layer.Group) {
          angular.forEach(a.getLayers().getArray(), getWmsInfo, function (error) { console.log(error) });
        }
      });
    }

    return {
      'static_layers': function () {
        var digital_globe = new ol.layer.Tile({
          title: 'DigitalGlobe',
          source: new ol.source.XYZ({
            url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token='+config.mapbox_api_key,
            tileSize: 256,
            attribution: "MapBox",
            params: { transparent: 'false' },
          }),
          opacity: '0.9',
          type: 'base',
          visible: true,
        });

        var osm = new ol.layer.Tile({
          title: 'OpenStreetMaps',
          source: new ol.source.OSM({ params: { transparent: 'true' } }),
          opacity: '0.7',
          type: 'base',
          visible: false,
        });

        var bing = new ol.layer.Tile({
          title: 'Bing',
          source: new ol.source.BingMaps(
            {
              key: 'Amya9HHJAIRMB5huMdmfDCIwCxJvLsLs1hvd--4-UBiOidVGv8OLl0A0nSpDlvux',
              imagerySet: 'AerialWithLabels',
              attribution: "Bing Maps",
            }
          ),
          opacity: '1',
          type: 'base',
          visible: false,
          preload: Infinity
        });

        return [
          new ol.layer.Group({
            title: 'Base maps',
            layers: [digital_globe, osm, bing]
          }),
        ]
      },
      'mapControls': function () {
        return ol.control.defaults({
          attributionOptions: ({
            collapsible: true,
            attribution: false,
            zoom: false,
          })
        }).extend([
          new ol.control.ZoomSlider(),
          new ol.control.ScaleLine(),
          new ol.control.FullScreen(),
          new ol.control.OverviewMap(),
          new ol.control.MousePosition({
            coordinateFormat: ol.coordinate.createStringXY(5),
            projection: 'EPSG:4326'
          }),
          new ol.control.LayerSwitcher({
            tipLabel: 'Controle e Camadas',
            enableOpacitySliders: true,
            enableLayerOrder: true,
          }),
        ])
      },
      'doIt': function (map, geojson) {
        // Create new layer/s
        cleanUpLayers(map);
        var vectorSource;
        var vectorLayer;

        var lg = map.getLayers().getArray().filter(group => group.getProperties().name == 'minexplore_search');
        var layerGroup;
        if (lg.length > 0) {
          layerGroup = lg[0];
          layerGroup.getLayers().forEach(l => l.setVisible(false));
          vectorSource = new ol.source.Vector({});
          var str_title = 'Resultado da busca: ' + layerGroup.getLayers().getArray().length;
          vectorLayer = new ol.layer.Vector({
            source: vectorSource,
            title: str_title
          });
          layerGroup.getLayers().getArray().push(vectorLayer);
        } else {
          vectorSource = new ol.source.Vector({});
          vectorLayer = new ol.layer.Vector({
            source: vectorSource,
            title: 'Resultado da busca'
          });
          layerGroup = new ol.layer.Group({
            title: 'Histórico de Buscas',
            name: 'minexplore_search',
            layers: [vectorLayer]
          });
          map.addLayer(layerGroup);
        }

        var extent;

        if (geojson == undefined || geojson.features == undefined || geojson.features.length == 0) {
          return false;
        }
        geojson.features.forEach(function (featureJson) {

          try {
            let label = olStyles.text_label(featureJson.properties.processo)
            let layerStyle = olStyles.setStyle(featureJson, label);
            ft = json_to_feature(featureJson, layerStyle);
            vectorSource.addFeature(ft);
          } catch (err) {
            console.log(err)
          }

        });
        extent = vectorSource.getExtent();
        if (extent && geojson.features.length > 0)
          map.getView().fit(extent, map.getSize());
      },
      'zoomToFeatureId': function (map, feature_id) {
        zoomToFeatureId(map, feature_id);
      },
      'addLayers': function (map) {
        addLayerGroups(map);
        load_user_buffer(map);
      },
      'click_info': function (map, callback) {
        click_info(map, callback);
      },
      'disable_info': function (map) {
        disable_info_click_interaction(map);
      },
      'enable_feature_edit': function (map, callback) {
        disable_info_click_interaction(map);
        olDraw.disable_modifier(map);
        olDraw.enable_modifier(map, callback);
      },
      'enable_import': function (map) {
        olFeatureImport.init(map)
      },
      'disable_import': function () {
        olFeatureImport.removeInteraction()
      },
      'featureInfo': function (evt, map) {
        featureInfo(evt, map);
      },
      'pointer_move': function(map, callback){
        pointer_move(map, callback);
      },
      'disable_pointer_move': function(map, callback){
        disable_pointer_move();
      },
    }

  }
  ]);

