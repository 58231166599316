angular.module('mineXplore')
.factory("Plan", ['$resource', 'config', function($resource, config) {
  var Plan;
  Plan = $resource(config.apiUrl  + config.baseUrl + "/common/payment_gateway/plans/:id",
    { id: "@id" },

    {
      query: {method: 'GET', isArray: false, cache: true },
      fetch: {method: 'GET', isArray: false, cache: true },
      search: {
        method: 'GET',
        isArray: false,
        cache: true,
        params: {
          account_type: '@account_type',
          max_monitoring: '@max_monitoring',
          payment_term: '@payment_term',
          max_team: '@max_team'
        },
      },
    }
    );
  return Plan;
}]);
