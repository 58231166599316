angular.module("mineXplore")
  .directive('projectAccordionHeader', [function () {
    return {
      restrict: 'EA',
      //scope: true,
      scope: {
        id: "@id",
        numero: '@numero',
        ano: '@ano',
        processo: '@processo',
        titular: "@titular",
        cover_image: "@image",
        posivite_badges: "=posivite_badges",
        negative_bagdes: "=negative_bagdes",
        url: "=url"
      },
      //bindToController: true,
      templateUrl: 'dashboard/project/prj-accordion.html',
      controller: 'ProjectAccordionController'
    };
  }]);

//   Restrict allowed values
// E for Element name
// A for Attribute
// C for Class
// M for Comment
