'use strict';
angular.module('mineXplore')
    .service('CpfCnpj', ['$resource', function($resource) {

        // PostalCode = $resource('http://api.postmon.com.br/v1/cep/:postal_code',
        //   { postal_code: '@postal_code' },
        //   {
        //     query: {method: 'GET', isArray: false }
        //   }
        // );
        // PostalCode.prototype.update_address = function(postalCode, objAddress){
        //     var zipcode = postalCode.replace('-','');
        //     // PostalCode.get({postal_code: zipcode}, function(r){
        //     //   $scope.user.subscriber_attributes.address_attributes.address1 = r['logradouro']
        //     //   $scope.user.subscriber_attributes.address_attributes.neighborhood = r['bairro']
        //     //   $scope.user.subscriber_attributes.address_attributes.city = r['cidade']
        //     //   $scope.user.subscriber_attributes.address_attributes.region = r['estado']
        //     // });
        //
        //
        // };
        var CpfCnpj = {}
        CpfCnpj.validate = function(cpf_cnpj){return validate_cnpj(cpf_cnpj) || validate_cpf(cpf_cnpj)};

        let validate_cnpj = function(cnpj) {

            cnpj = cnpj.replace(/[^\d]+/g,'');

            if(cnpj == '') return false;

            if (cnpj.length != 14)
                return false;

            // Elimina CNPJs invalidos conhecidos
            if (cnpj == "00000000000000" ||
                cnpj == "11111111111111" ||
                cnpj == "22222222222222" ||
                cnpj == "33333333333333" ||
                cnpj == "44444444444444" ||
                cnpj == "55555555555555" ||
                cnpj == "66666666666666" ||
                cnpj == "77777777777777" ||
                cnpj == "88888888888888" ||
                cnpj == "99999999999999")
                return false;

            // Valida DVs
            let tamanho = cnpj.length - 2
            let numeros = cnpj.substring(0,tamanho);
            let digitos = cnpj.substring(tamanho);
            let soma = 0;
            let pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                return false;

            tamanho = tamanho + 1;
            numeros = cnpj.substring(0,tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1))
                return false;

            return true;

        };

        let validate_cpf = function (strCPF) {
            strCPF = strCPF.replace(/\D/g, '')
            var Soma;
            var Resto;
            Soma = 0;
            if (strCPF.length !=11) return false;
            if (strCPF == "00000000000") return false;

            for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto == 10) || (Resto == 11))  Resto = 0;
            if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

            Soma = 0;
            for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto == 10) || (Resto == 11))  Resto = 0;
            if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
            return true;
        }
        return CpfCnpj;
    }
    ]);
