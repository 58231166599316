/**
  User Buffers - Manage Seach parameters
*/
angular.module('mineXplore')
.factory("UserBuffer", ['$resource', 'config', function($resource, config) {
  var user_buffers;
  user_buffers = $resource(config.apiUrl + config.baseUrl + "/user/buffers/:id.json",
    { id: "@id" },
    {
      update: { method: "PUT" },
      destroy: { method: "DELETE" },
      save: {method: 'POST'},
      create:{ method: "POST"},
      get: {method: 'GET', isArray: true },
      query: {method: 'GET', isArray: false }
    }
    );
  return user_buffers;
}
]);
