angular.module('mineXplore')
	.factory("Profile", ['$resource', 'config', function ($resource, config) {
		var Profile;
		Profile = $resource(config.apiUrl + config.baseUrl + "/user/info/:id",
			{ id: "@id" },
			{
				fetch: { method: 'GET', cache: false },
				update: { method: 'PUT' },

			});
		return Profile;
	}]);
