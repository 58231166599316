angular.module('mineXplore')
  .factory("DocumentType", ['$resource', 'config', function ($resource, config) {
    var obj = $resource(config.apiUrl + config.baseUrl + "/common/document_type",
      { id: "@id" },
      {
        get: { method: 'GET', isArray: true },
      }
    );

    obj.prototype.destroy = function (cb) {
      return Process.remove({
        id: this.id
      }, cb);
    };


    return obj;
  }
  ]);
