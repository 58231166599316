// Creating a simple Modal Directive
angular.module("mineXplore")
  .directive('globalDocumentsModal', ['$uibModal',
    function ($uibModal) {
      return {
        restrict: 'AE',
        controller: 'GlobalDocumentsModalController',

        scope: {
          title: "=title",
        },
        link: function (scope, element, attributes) {
          element.bind('click', function (e) {
            scope.open_modal();
          });
        }
      }
    }
  ])
//   Restrict allowed values
// E for Element name
// A for Attribute
// C for Class
// M for Comment

// ‘@’ – Text binding / one - way binding
// ‘=’ – Direct model binding / two - way binding
// ‘&’ – Behavior binding / Method binding

