'use strict';
angular.module('mineXplore')
  .service('olContextMenu', ['$q', '$timeout', 'UserBuffer', 'SweetAlert', 'Notification', 'olDraw', 'exportCsv', 'exportGPX', 'exportKML', 'exportGeoJSON', 'olDrawReq',
    function ($q, $timeout, UserBuffer, SweetAlert, Notification, olDraw, exportCsv, exportGPX, exportKML, exportGeoJSON,olDrawReq) {
      var _map;
      var pinIcon = '/images/ctx_menu/maps-and-flags.png';
      var centerIcon = 'https://cdn.jsdelivr.net/gh/jonataswalker/ol-contextmenu@604befc46d737d814505b5d90fc171932f747043/examples/img/center.png';
      var listIcon = 'https://cdn.jsdelivr.net/gh/jonataswalker/ol-contextmenu@604befc46d737d814505b5d90fc171932f747043/examples/img/view_list.png';
      var infoIcon = 'https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.4.6/collection/icon/svg/md-information.svg';
      var csvIcon = "https://freeiconshop.com/wp-content/uploads/edd/csv-outline.png";
      var editIcon = "https://image.flaticon.com/icons/png/128/1014/1014377.png";
      var floppyIcon = "/images/ctx_menu/floppy-icon.png";
      var kmlIcon = "/images/ctx_menu/kml-file-format-variant.png"
      var jsonIcon = "/images/ctx_menu/json-file.png";
      var gpxIcon = "/images/ctx_menu/gpx-file-format-variant.png";
      var gridIcon = "/images/ctx_menu/global-grid-icon.png"
      var vectorLayer = new ol.layer.Vector({ source: new ol.source.Vector(), title: 'Em edição' });
      var grid_enabled = false;
      var contextmenuItems = [
        {
          text: 'Centralizar aqui',
          classname: 'bold',
          icon: centerIcon,
          callback: center
        },
        {
          text: 'Outros recursos',
          icon: listIcon,
          items: [
            {
              text: 'Ativar/Desativar Gride',
              icon: gridIcon,
              callback: enable_gridline
            },
            {
              text: 'Adicionar Ponto',
              icon: pinIcon,
              callback: marker
            }
          ]
        },

        '-' // this is a separator
        ,
        {
          text: 'Novo Requerimento',
          items: [
              {
                text: 'Ortogonal',
                callback: function(){
                  olDraw.draw_requirement(_map, olDrawReq.geometryFunction);
                }
              },
              {
                text: 'Livre',
                callback: function(){
                  olDraw.draw(_map, 'Polygon', olDrawReq.free_drawend);
                }
              }
          ]
        }
      ];

      var contextmenu = new ContextMenu({
        width: 180,
        items: contextmenuItems
      });

      var addMarkerItem = {
        text: 'Adicionar Ponto',
        icon: pinIcon,
        callback: marker
      }

      var removeMarkerItem = {
        text: 'Remover este Ponto',
        classname: 'marker',
        callback: removeMarker
      };

      var saveMarkerItem = {
        text: 'Salvar este pino',
        classname: 'marker',
        callback: saveGeom
      };

      var saveAsItem = {
        text: 'Salvar como',
        icon: floppyIcon,
        callback: saveAs
      };

      var exportItem = {
        text: 'Exportar CSV ANM',
        icon: csvIcon,
        callback: export_csv
      };

      var exportItemGPX = {
        text: 'Exportar GPX',
        icon: gpxIcon,
        callback: export_gpx
      };

      var exportItemKML = {
        text: 'Exportar KML',
        icon: kmlIcon,
        callback: export_kml
      };

      var exportItemGeoJSON = {
        text: 'Exportar GeoJSON',
        icon: jsonIcon,
        callback: export_geoJSON
      };

      var export_submenu = {
        text: 'Exportar',
        icon: listIcon,
        items: [
          saveAsItem,
          exportItem,
          exportItemGPX,
          exportItemKML,
          exportItemGeoJSON,
        ]
      };

      var disableEditItem = {
        text: 'Concluir edição',
        icon: editIcon,
        callback: featureFinishEdit
      };
      var editItem = {
        text: 'Editar',
        icon: editIcon,
        callback: featureEdit
      };
      var infoItem = {
        text: 'Info',
        icon: infoIcon,
        callback: featureInfo
      };

      function export_csv(evt, map) {
        var pixel = map.getPixelFromCoordinate(evt.coordinate);
        var hit = map.hasFeatureAtPixel(pixel);
        if (hit) {
          var feature = _map.forEachFeatureAtPixel(pixel, ft => ft);
          exportCsv.export('ol', feature, build_filename(feature, 'csv'));
        }
      }

      function export_gpx(evt, map) {
        console.log('pare');
        var pixel = map.getPixelFromCoordinate(evt.coordinate);
        var hit = map.hasFeatureAtPixel(pixel);
        if (hit) {
          var feature = _map.forEachFeatureAtPixel(pixel, ft => ft);
          exportGPX.export(feature, build_filename(feature, 'gpx'));
        }
      }

      function export_kml(evt, map) {
        console.log('pare');
        var pixel = map.getPixelFromCoordinate(evt.coordinate);
        var hit = map.hasFeatureAtPixel(pixel);
        if (hit) {
          console.log('aqui');
          var feature = _map.forEachFeatureAtPixel(pixel, ft => ft);
          exportKML.export(feature, build_filename(feature, 'kml'));
        }
      }

      function export_geoJSON(evt, map) {
        console.log('pare');
        var pixel = map.getPixelFromCoordinate(evt.coordinate);
        var hit = map.hasFeatureAtPixel(pixel);
        if (hit) {
          var feature = _map.forEachFeatureAtPixel(pixel, ft => ft);
          exportGeoJSON.export(feature, build_filename(feature, 'gpx'));
        }
      }

      function build_filename(feature, file_extension) {
        if ((feature.get('data') && feature.get('data').processo)) {
          return feature.get('data') && feature.get('data').processo.replace('/', '-') + '.' + file_extension
        } else {
          return 'exported_feature.' + file_extension;
        }
      }
      function featureEdit(evt, map) {
        var pixel = map.getPixelFromCoordinate(evt.coordinate);
        var hit = map.hasFeatureAtPixel(pixel);
        if (hit) {
          var feature = _map.forEachFeatureAtPixel(pixel, ft => ft);
          olDraw.enable_modifier(map, feature);
        }
      }
      function featureFinishEdit(evt, map) {
        var pixel = map.getPixelFromCoordinate(evt.coordinate);
        var feature = _map.forEachFeatureAtPixel(pixel, ft => ft);
        olDraw.disable_modifier(map, feature);
      }

      function featureInfo(evt, map) {
        document.getElementById('info').innerHTML = '';
        const getWmsInfo = function (layer) {
          if (layer.get('visible') == true && layer.get('type') != 'base') {
            wmsSource = layer.getSource();
            var viewResolution = /** @type {number} */ (_map.getView().getResolution());
            var format = layer.get('info_format');
            if (!wmsSource instanceof ol.source.Vector) {
              var url = wmsSource.getGetFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', { 'INFO_FORMAT': format });
              if (url) {
                document.getElementById('info').innerHTML += '<iframe seamless src="' + url + '"></iframe>';
              }
            }
          }
        };
        _map.getLayers().forEach(function (a, b) {
          if (a instanceof ol.layer.Group) {
            angular.forEach(a.getLayers().getArray(), getWmsInfo, function (error) { console.log(error) });
          }
        });
      }

      function enable_gridline(evt, map) {
        console.log(grid_enabled);
        if(grid_enabled){
          olDraw.disable_gridline();
        }else olDraw.enable_gridline(map);
        grid_enabled = !grid_enabled;
      }


      // from https://github.com/DmitryBaranovskiy/raphael
      function elastic(t) {
        return Math.pow(2, -10 * t) * Math.sin((t - 0.075) * (2 * Math.PI) / 0.3) + 1;
      }

      function center(obj) {
        _map.getView().animate({
          duration: 700,
          easing: elastic,
          center: obj.coordinate
        });
      }

      function removeMarker(obj) {
        vectorLayer.getSource().removeFeature(obj.data.feature);
      }

      function saveAs(obj) {
        var obj_label = prompt('Informe um rótulo para este ponto.');
        obj.data.feature.setProperties({ label: obj_label })
        saveGeom(obj);
      }
      function saveGeom(obj) {
        //vectorLayer.getSource().removeFeature(obj.data.marker);
        console.log(obj);
        var save_it = function (obj) {

          var marker_label = obj.data.feature.getProperties()['label']
          var geojson = new ol.format.GeoJSON();
          //var ft = new ol.Feature({ name: marker_label, geometry: new ol.geom.Point(marker.coordinate) });
          ft = obj.data.feature;
          var as_geojson = geojson.writeFeature(ft, {
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:4326'
          });
          var data_new = { name: marker_label, geom: as_geojson };
          UserBuffer.create(data_new, function (response) {
            response.$promise.then(function (data) {
              Notification.success('Buffer/Ponto/Desenho Salvo com sucesso')
            }, function (error) {
              console.log(error);
              Notification.error("Ocorreu um erro ao salvar Bufer/Ponto/Desenho");
            })
          });
        };
        save_it(obj);
      }

      function marker_label(prompt) {
        swal({
          title: prompt,
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Salvar',
          showLoaderOnConfirm: true,
          preConfirm: function (name) {
            return name;
          },
          allowOutsideClick: false
        });
      }
      function marker(obj, label) {
        console.log(label);
        if (typeof (label) == String) {
          var marker_label = label;
        } else {
          var marker_label = prompt('Informe um rótulo para este objeto.');
          if (marker_label == undefined) {
            return false;
          }
        }

        var coord4326 = ol.proj.transform(obj.coordinate, 'EPSG:3857', 'EPSG:4326'),
          template = marker_label + ' ({x}, {y})',
          iconStyle = new ol.style.Style({
            image: new ol.style.Icon({ scale: .6, src: pinIcon }),
            text: new ol.style.Text({
              offsetY: 25,
              text: ol.coordinate.format(coord4326, template, 2),
              font: '15px Open Sans,sans-serif',
              fill: new ol.style.Fill({ color: '#111' }),
              stroke: new ol.style.Stroke({ color: '#eee', width: 2 })
            })
          }),
          feature = new ol.Feature({
            type: 'removable',
            label: marker_label,
            geometry: new ol.geom.Point(obj.coordinate)
          });

        feature.setStyle(iconStyle);
        vectorLayer.getSource().addFeature(feature);
      }

      return {
        'init': function (olmap) {
          _map = olmap
          console.log('show labels');
          _map.addLayer(vectorLayer);
          _map.addControl(contextmenu);
          /////////////////////////////////////
          _map.on('pointermove', function (e) {
            if (e.dragging) return;

            var pixel = _map.getEventPixel(e.originalEvent);
            var hit = _map.hasFeatureAtPixel(pixel);

            _map.getTargetElement().style.cursor = hit ? 'pointer' : '';
          });
          ////////////////////////////////////
          contextmenu.on('open', function (evt) {
            var feature = _map.forEachFeatureAtPixel(evt.pixel, ft => ft);
            contextmenu.clear();
            if (feature && feature.get('type') === 'removable') {
              removeMarkerItem.data = { feature: feature };
              saveMarkerItem.data = { feature: feature };

              contextmenu.push(removeMarkerItem);
              contextmenu.push(saveMarkerItem);
            } else if (feature && feature.get('type') === 'editable') {
              saveAsItem.data = { feature: feature };
              contextmenu.push(exportItem);
              contextmenu.push(disableEditItem);
              contextmenu.push(saveAsItem);
            } else if (feature && feature.get('data')) {

              exportItem.data = { feature: feature };
              exportItemGPX.data = { feature: feature };
              exportItemKML.data = { feature: feature };
              infoItem.data = { feature: feature };
              editItem.data = { feature: feature };

              contextmenu.push(addMarkerItem);
              contextmenu.push(infoItem);
              contextmenu.push(editItem);
              contextmenu.push(export_submenu);
            } else if (feature) {

              exportItem.data = { feature: feature };
              exportItemGPX.data = { feature: feature };
              exportItemKML.data = { feature: feature };
              infoItem.data = { feature: feature };
              editItem.data = { feature: feature };

              contextmenu.push(addMarkerItem);
              contextmenu.push(infoItem);
              contextmenu.push(editItem);
              contextmenu.push(export_submenu);
            } else {
              contextmenu.extend(contextmenuItems);
              contextmenu.extend(contextmenu.getDefaultItems());
            }
          });

        }
      }

    }
  ]);

