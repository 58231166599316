'use strict';
angular.module('mineXplore')
  .controller('PrjTeamManagmentController',
    ['$scope',
      '$uibModal',
      'ProjectMembers',
      'Project',
      'Notification',
      function ($scope, $uibModal, Member, Project, Notification) {
        console.log('GlobalTeamManagmentController...');
        $scope.new_member = new Member();

        var refresh_available_users = function () {
          Member.availables({ project_id: $scope.project_id }, function (response) {
            console.log('loading available_users');
            console.log(response.data);
            $scope.available_users = response.data;
          }, function (error) {
            console.log('error loading available_users');
            console.log(error);
          });
        }
        //refresh_available_users();
        $scope.refresh_available_users = refresh_available_users;
        var modalInstance;
        $scope.open_modal = function () {
          $scope.refresh_available_users();
          console.log('id', $scope.id);
          console.log('project_id', $scope.project_id);
          Project.get({ id: $scope.project_id },
            function (response) {
              console.log(response);
              $scope.project = response.data;
            },
            function (error) {
              console.log(error);
            }
          );

          Member.get({ project_id: $scope.project_id }, function (response) {
            console.log('loading team members');
            console.log(response.data);
            $scope.data = response.data;
          }, function (error) {
            console.log('error loading team members');
            console.log(error);
          })
          modalInstance = $uibModal.open({
            animation: true,
            backdrop: false,
            templateUrl: "dashboard/project/proj-modal-team.html",
            scope: $scope
          });
          modalInstance.result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
              console.log(res);
              throw res;
            }
          }
          );
        };

        var modalInstancePrivileges;
        $scope.open_modal_privileges = function (user) {
          $scope.selected_user = user;
          modalInstancePrivileges = $uibModal.open({
            animation: true,
            backdrop: true,
            templateUrl: "dashboard/project/proj-modal-team-privilege.html",
            scope: $scope
          });
          modalInstancePrivileges.result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
              console.log(res);
              throw res;
            }
          });
        };

        $scope.cancel = function () {
          modalInstance.close();
        }
        $scope.close_privileges_modal = function () {
          modalInstancePrivileges.close();
        }

        $scope.assign_privileges = function (user) {
          modalInstancePrivileges.close();
          $scope.save(user);
        }

        $scope.save = function (user) {
          $scope.errors = null;
          console.log('save member');
          console.log(user);
          member = new Member(user);
          promise = member.$update({ project_id: $scope.project_id, id: user.id });
          promise.then(function (response) {
            console.log(response);
            Notification.success("Privilégio atualizado");
          }, function (error) {
            console.log(error);
            //$scope.errors = error.data;
            Notification.error(error.data.message);
          })
        }

        $scope.destroy = function (user) {
          console.log('destroy member');
          $scope.errors = null;
          promise = new Member(user).$delete({ project_id: $scope.project_id });
          promise.then(function (response) {
            $scope.data.splice($scope.data.findIndex(dt => dt.id == response.data.id), 1)
            refresh_available_users();
            Notification.success("Privilégio revogado!");
          }, function (error) {
            console.log(error);
            //$scope.errors = error.data;
            Notification.error(error.data.message);
          })
        }

        $scope.add_member = function () {
          console.log('add_member');
          $scope.errors = null;
          $scope.new_member.member['enabled'] = true;
          promise = $scope.new_member.$save({ project_id: $scope.project_id });
          promise.then(function (response) {
            console.log(response);
            $scope.data.push(response.data)
            refresh_available_users()
            Notification.success("Usuário adicionado ao projeto");
          }, function (error) {
            console.log(error);
            //$scope.errors = error.data;
            Notification.error(error.data.message);
          })
        }
      }
    ]
  )
  ;
