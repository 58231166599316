/*
http://tombatossals.github.io/angular-openlayers-directive/#/events/event-vector-layer
*/
'use strict';
angular.module('mineXplore')
  .service('olStyles', [function () {
    var _draw; // global so we can remove it later
    let hexToRgbA = function (hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
      }
      throw new Error('Bad Hex');
    };

    let modifierStyle = function (feature) {
      styles = feature.getStyle();

      var vertex_circle = new ol.style.Style({
        image: new ol.style.Circle({
          radius: 5,
          fill: new ol.style.Fill({
            color: 'blue'
          })
        }),
        geometry: function (feature) {
          var geom;
          //console.log('modifierStyle styleOf ', feature.getGeometry().getType());
          var coordinates = feature.getGeometry().getCoordinates()
          if (feature.getGeometry().getType() == 'Polygon') {
            var coordinates = feature.getGeometry().getCoordinates()[0];
            return new ol.geom.MultiPoint(coordinates);
          }
          if (feature.getGeometry().getType() == 'Point') {
            return new ol.geom.MultiPoint(coordinates)
          }
          if (feature.getGeometry().getType() == 'LineString') {
            return new ol.geom.LineString(coordinates)
          }
          if (feature.getGeometry().getType() == 'Circle') {
            return new ol.geom.Circle(coordinates)
          }
        }
      });
      if (angular.isDefined(styles) && styles != null) {
        styles.push(vertex_circle)
        return styles;
      } else {
        return [vertex_circle];
      }

    };

    var text_label = function (label_text) {
      return createTextStyle(label_text, {
        text: 'normal',
        align: 'center',
        baseline: 'middle',
        rotation: '0',
        font: 'Arial',
        weight: 'normal',
        placement: 'line',
        maxangle: '0.755',
        overflow: 'true',
        size: '15px',
        offsetX: '0',
        offsetY: '0',
        color: '#111',
        outline: '#eee',
        outlineWidth: '2',
        maxreso: '19200'
      });
    };
    let setStyle = function (feature, text_label) {
      if (angular.isUndefined(feature.properties) && angular.isDefined(feature.getProperties())) {
        feature.properties = feature.getProperties();
      }
      if (angular.isUndefined(feature.properties.stroke) && angular.isDefined(feature.properties.data) && angular.isDefined(feature.properties.data.stroke)) {
        feature.properties = feature.getProperties().data;
      }
      if (angular.isUndefined(feature.properties.stroke)) {
        feature.properties['stroke'] = { color: '#000000', opacity: '0.6', width: 5 };
        feature.properties['fill'] = { color: '#ffffff', opacity: '0.3' };
      }

      return [
        new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: hexToRgbA(feature.properties.stroke.color, feature.properties.stroke.opacity),
            width: feature.properties.stroke.weight,
          }),
          fill: new ol.style.Fill({
            color: hexToRgbA(feature.properties.fill.color, feature.properties.fill.opacity)
          }),
          text: text_label,
          visibility: false
        }),
      ];
    }


    let createTextStyle = function (text, dom) {
      var align = dom.align.value;
      var baseline = dom.baseline.value;
      var size = dom.size.value;
      var offsetX = parseInt(dom.offsetX.value, 10);
      var offsetY = parseInt(dom.offsetY.value, 10);
      var weight = dom.weight.value;
      var placement = dom.placement ? dom.placement.value : undefined;
      var maxAngle = dom.maxangle ? parseFloat(dom.maxangle.value) : undefined;
      var overflow = dom.overflow ? (dom.overflow.value == 'true') : undefined;
      var rotation = parseFloat(dom.rotation.value);
      if (dom.font.value == '\'Open Sans\'' && !openSansAdded) {
        var openSans = document.createElement('link');
        openSans.href = 'https://fonts.googleapis.com/css?family=Open+Sans';
        openSans.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(openSans);
        openSansAdded = true;
      }
      var font = weight + ' ' + size + ' ' + dom.font.value;
      var fillColor = dom.color;
      var outlineColor = dom.outline;
      var outlineWidth = parseInt(dom.outlineWidth, 10);
      return new ol.style.Text({
        //textAlign: align == '' ? undefined : align,
        //textBaseline: baseline,
        font: font,
        text: text,
        fill: new ol.style.Fill({ color: fillColor }),
        stroke: new ol.style.Stroke({ color: outlineColor, width: outlineWidth }),
        //offsetX: offsetX,
        //offsetY: offsetY,
        //placement: placement,
        //maxAngle: maxAngle,
        //overflow: overflow,
        //rotation: rotation
      });
    };

    return {
      'hexToRgbA': function (hex, opacity) {
        return hexToRgbA(hex, opacity);
      },
      'setStyle': function (feature, label) {
        return setStyle(feature, label);
      },
      'createTextStyle': function (text, dom) {
        return createTextStyle(text, dom);
      },
      'modifierStyle': function (feature) {
        return modifierStyle(feature);
      },
      'text_label': function (text) {
        return text_label(text);
      }
    }
  }
  ]);
