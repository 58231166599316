angular.module('mineXplore')
  .controller('PrjMonitoredAreasController', [
    '$scope',
    '$state',
    'Project',
    'AreaProject',
    'SweetAlert',
    'Notification',
    function ($scope, $state, Project, AreaProject, SweetAlert, Notification) {
      console.log('PrjMonitoredAreasController')
      //load data
      $scope.project = { id: $state.params.id, processes: [{ id: '', processo: '' }] };
      $scope.projects = [];
      $scope.processes = [];
      $scope.project_id = $state.params.id;
      Project.get({ id: $state.params.id },
        function (data) {
          console.log('Project Get ')
          console.log(data);
          $scope.project = data.data;
          $scope.firstLoading = false;
        },
        function (error) {
          console.log('falha ao carregar ');
        }
      );
      AreaProject.get({ project_id: $state.params.id }, function (response) {
        console.log('area project');
        $scope.monitored_areas = response.data;
        console.log(response);
      }, function (error) {
        console.log('error load areas projects');
        console.log(error);
      });

      $scope.destroy = function (monitored_area) {

        SweetAlert.swal({
          title: "Confirmação",
          text: "Deseja realmente parar de receber alertas e informações sobre este processo?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
          cancelButtonText: "Não",
          closeOnConfirm: false,
          closeOnCancel: false
        },
          function (isConfirm) {
            if (isConfirm) {
              AreaProject.delete({ project_id: $state.params.id, id: monitored_area.id }, function (response) {
                console.log('destroy monitored area');

                Notification.success("Você não receberá mais alertas desta área.");
                $scope.monitored_areas.splice($scope.monitored_areas.findIndex(dt => dt.id == monitored_area.id), 1)
                console.log(response);
              }, function (error) {
                console.log('error destroying');
                console.log(error);
                var txt_error = error.data.message;
                Notification.error(txt_error);
              });
            } else {
              console.log('cancelado');
              Notification.info("Este processo continuará enviando alertas para você");
            }
          });

      }

    }]);