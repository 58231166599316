'use strict';
angular.module('mineXplore')
.controller('PaymentGatewayPlansController', ['$scope', 'Plan', 'Subscription', function ($scope, Plan, Subscription) {
  console.log('PaymentGatewayPlansController');
  Plan.fetch({},function(response){
    response.$promise.then(function(response){
      $scope.plans = response.data;
    });
  });
  Subscription.fetch({}, function(response){
    response.$promise.then(function(result){
      $scope.subscriptions = result.data;
    });
  });
  $scope.get_plan = function(id){
    return angular.forEach($scope.plans,
      function(item){
        if(item.id==id){return(item)}
    })
  };

  $scope.extract_payload = function(payload){
    return JSON.parse(payload);
  }

  $scope.interval_as_months = function(interval){
    switch(interval){
        case 'weekly':
            return 0.25;
        case 'monthly':
            return 1;
        case 'trimonthly':
          return 3;
        case 'yearly':
          return 12;
    }
  }


}]);
