'use strict';
angular.module('mineXplore')
  .controller('SearchController', ['$rootScope', '$scope', '$state', '$uibModal' ,'Process', '$window', 'CpfCnpj', 'Notification',
    function ($rootScope, $scope, $state, $uibModal, Process, $window, CpfCnpj, Notification) {
    console.log('SearchController');
    $scope.search_params = "";
    var modalInstance;
        $scope.cancel = function(){
          console.log('close quickSearch modal');
           modalInstance.close();
        }

    $scope.quickSearch = function () {
      console.log('SearchController.quickSearch(): ' + $scope.search_params);
      //quick search on server
      if ($scope.search_params.length == 0) {
        // no search
        // console.log('quick search sem parâmetro');
        Notification.info("Por favor digite o que deseja encontrar na busca rápida");
        return false;
      } else if (CpfCnpj.validate($scope.search_params)) {
        // cpf/cnpj
        $rootScope.searchService = { cpf_cnpj: $scope.search_params }
      } else if ($scope.search_params.match(/((\d{6}|\d{3}\.\d{3})\/\d{4})+/gm)) {
        //process number
        $rootScope.searchService = { parameters: { "process": { "process_number": $scope.search_params.match(/((\d{6}|\d{3}\.\d{3})\/\d{4})+/gm) } } };
      }
      else {
        // other searches
        $rootScope.searchService = { searchText: $scope.search_params };
      }


      if(!$state.is("map")){
        console.log('abrindo modal mapa') ;
        modalInstance = $uibModal.open({
          templateUrl: 'dashboard/common/map-modal.html',
          scope: $scope
        });
      }

    };


    $scope.enterPressed = function (keyEvent) {
      if (keyEvent.which === 13)
        $scope.quickSearch();
    };

  }]);
