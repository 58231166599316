angular.module('mineXplore')
.factory("Project", ['$resource', 'config', function($resource, config) {
  var Project = $resource(config.apiUrl + config.baseUrl + "/user/projects/:id/",
    { id: "@id" },
    {
      update: { method: "PUT" },
      delete: { method: "DELETE" },
      save: {method: 'POST'},
      create:{ method: "POST"},
      query: {method: 'GET', isArray: false },
      fetch: {method: 'GET', isArray: false }
    }
    );

  Project.prototype.destroy = function(cb) {
    return Project.remove({
      id: this.id
    }, cb);
  };
    return Project;
  }
]);
