'use strict';
angular.module('mineXplore')
  .service('MapLabels',  [ function () {
    var createMapLabel = function(p, gCenter, map){
      new MapLabel({
        text: p.properties.processo,
        position: gCenter,
        map: map.control.getGMap(),
        fontSize: 11,
        fontColor: 'white',
        strokeWeight: '0',
        strokeColor: 'black',
        align: 'center'
      })
    }
    var getBounds = function (p) {
      var bounds = new google.maps.LatLngBounds();
      // get polygon center
      angular.forEach(p.geometry.coordinates, function (c, j) {
        angular.forEach(c, function (m, l) {
          point = new google.maps.LatLng(m[1], m[0]);
          bounds.extend(point);
        });
      });
      return bounds;
    }


    return {
      'show': function (state, map, labels, polygons) {
        console.log('show labels' + state);
        if (!map.control.getPlurals) {
          return;
        }
        if (state) {
          // ensure to clean old labels before add new
          angular.forEach(labels, function (l, k) { l.setMap(null); });
          labels = [];
          // add label for each polygon
          angular.forEach(polygons, function (p, z) {
            var bounds = getBounds(p);
            var gCenter = bounds.getCenter();
            var mapLabel = createMapLabel(p, gCenter, map);
            labels.push(mapLabel);
            console.log('label added')
            //$scope.showLabel = false;
          });
          return labels;
        }
        else {
          angular.forEach(labels, function (l, k) { l.setMap(null); });
          labels = [];
        }

      }

    }

  }
  ]);

