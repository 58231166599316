angular.module('mineXplore')
.filter('line_break', function () {
        return function (text) {
            if (!text) {
                return text;
            }
      return text.replace("\\", '\n');
        };
})
.filter('capitalize', function() {
  return function(string) {
    if(!string){
      return string;
    }
    return string.split(' ').map(function(string) {
      string = string.trim();
      return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
    }).join(' ');
  };
})
.filter('remove_slash', function () {
        return function (text) {
            if (!text) {
                return text;
            }
      return text.replace("/", '-');
        };
});