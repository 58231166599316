angular.module('mineXplore')

  .config(function ($provide, $stateProvider, $urlRouterProvider, $authProvider) {

    function authenticateRoute($rootScope, $auth, $uibModal, Privileges) {
      console.log('authenticateRoute');
      return $auth.validateUser()
        .then(function (response) {
          if ($rootScope.modalLoginInstance != undefined)
            $rootScope.modalLoginInstance.close();
          $rootScope.user = response.attributes;
          Privileges.set($rootScope.user);
          return true;
        })
        .catch(function (res) {
          $rootScope.modalLoginInstance = $uibModal.open({
            templateUrl: 'loginPopup.html',
            controller: 'AuthController',
          });
        });
    }

    // Now set up the states
    $stateProvider
      .state('login', {
        pageTrack: 'login',
        templateUrl: 'home.html',
        controller: 'AuthController',
        url: '/login'
      })
      .state('root', {
        pageTrack: '/home',
        templateUrl: 'home.html',
        controller: 'AuthController',
        params: {
          confirmed: null,
          reset_password_token: null,
          referal: null,
          account_confirmation_success: null,
          token: null,
          code: null,
          payment_confirmation_success: null,
          invitation_token: null,
        },
        url: '/?reset_password_token&confirmed&referal&account_confirmation_success&token&code&payment_confirmation_success&invitation_token',
      })
      .state('register', {
        pageTrack: '/register',
        templateUrl: 'register.html',
        controller: 'AuthController',
        url: '/register',
      })
      .state('resetLogin', {
        pageTrack: '/resetLogin',
        templateUrl: 'resetLogin.html',
        controller: 'AuthController',
        url: '/resetLogin',
      })
      .state('map', {
        pageTrack: '/map',
        url: '/map/:number/:year?search&process',
        params: {
          number: null,
          year: null
        },
        templateUrl: 'map/main_map.html',
        resolve: {
          auth: authenticateRoute
        }
      })

      // States for registration
      .state('processes', {
        pageTrack: '/processos',
        url: '/processes',
        templateUrl: 'process/index.html',
        controller: 'ProcessController',
        resolve: {
          auth: authenticateRoute
        }
      })
      .state('ProcessesShow', {
        pageTrack: '/processo/:id/detalhe',
        url: '/processo/:id/detalhe',
        params: {
          id: ''
        },
        templateUrl: 'process/show.html',
        controller: 'ProcessController',
        resolve: {
          auth: authenticateRoute
        }
      })

      //ProjectController
      .state('editProject', {
        pageTrack: '/project/:id/edit',
        url: '/projects/:id/edit',
        params: {
          id: ''
        },
        templateUrl: 'project/edit.html',
        controller: 'ProjectController',
        resolve: {
          auth: authenticateRoute
        }
      })
      .state('showProject', {
        pageTrack: '/projects/:id/show',
        url: '/projects/:id/show',
        params: {
          id: ''
        },
        templateUrl: 'project/show.html',
        controller: 'ProjectController',
        resolve: {
          auth: authenticateRoute
        }
      })
      .state('newProject', {
        pageTrack: '/projects/new',
        url: '/projects/new',
        templateUrl: 'project/new.html',
        controller: 'ProjectController',
        resolve: {
          auth: authenticateRoute
        }
      })
      .state('plans', {
        pageTrack: '/plans',
        url: '/plans',
        templateUrl: 'payment_gateway/plans.html',
        controller: 'PaymentGatewayPlansController',
        resolve: {
          auth: authenticateRoute
        }
      })
      .state('subscriptions', {
        pageTrack: '/subscriptions',
        url: '/subscriptions',
        templateUrl: 'payment_gateway/subscriptions.html',
        controller: 'PaymentGatewayPlansController',
        resolve: {
          auth: authenticateRoute
        }
      })
      .state('profile', {
        pageTrack: '/profile',
        url: '/profile',
        templateUrl: 'profile/profile.html',
        controller: 'ProfileController',
        resolve: { auth: authenticateRoute }
      })
      .state('global_dashboard', { url: '/global_dashboard', templateUrl: 'dashboard/global/global-dashboard.html', controller: 'DashboardController', resolve: { auth: authenticateRoute } })
      .state('global_project_list', { url: '/project_list', templateUrl: 'dashboard/global/global-project-list.html', controller: 'DashboardProjectsController', resolve: { auth: authenticateRoute } })
      .state('global_dou', { url: '/global_dou', templateUrl: 'dashboard/global/global-dou.html', controller: 'DashboardDouController', resolve: { auth: authenticateRoute } })
      .state('global_prazos', { url: '/global_prazos', templateUrl: 'dashboard/global/global-prazos.html', controller: 'DashboardPrazosController', resolve: { auth: authenticateRoute } })
      .state('global_eventos', { url: '/global_eventos', templateUrl: 'dashboard/global/global-eventos.html', controller: 'DashboardEventosController', resolve: { auth: authenticateRoute } })
      .state('global_movimentacoes', { url: '/global_movimentacoes', templateUrl: 'dashboard/global/global-movimentacoes.html', controller: 'DashboardMovimentacoesController', resolve: { auth: authenticateRoute } })
      .state('global_documentos', { url: '/global_documentos', templateUrl: 'dashboard/global/global-documentos.html', controller: 'DashboardDocumentosController', resolve: { auth: authenticateRoute } })

      .state('prj_dashboard', { url: '/projects/:id/dashboard', templateUrl: 'dashboard/project/prj-dashboard.html', controller: 'PrjDashboardController', params: { id: '' }, resolve: { auth: authenticateRoute } })
      .state('prj_process_list', { url: '/projects/:id/process_list', templateUrl: 'dashboard/project/prj-process-list.html', controller: 'PrjMonitoredAreasController', params: { id: '' }, resolve: { auth: authenticateRoute } })
      .state('prj_map_view', { url: '/projects/:id/map', templateUrl: 'dashboard/project/prj-map-view.html', controller: 'PrjMapViewController', params: { id: '' }, resolve: { auth: authenticateRoute } })
      .state('prj_dou', { url: '/projects/:id/dou', templateUrl: 'dashboard/project/prj-dou.html', controller: 'PrjDouController', params: { id: '' }, resolve: { auth: authenticateRoute } })
      .state('prj_prazos', { url: '/projects/:id/terms', templateUrl: 'dashboard/project/prj-prazos.html', controller: 'PrjPrazosController', params: { id: '' }, resolve: { auth: authenticateRoute } })
      .state('prj_eventos', { url: '/projects/:id/scm', templateUrl: 'dashboard/project/prj-eventos.html', controller: 'PrjEventosController', params: { id: '' }, resolve: { auth: authenticateRoute } })
      .state('prj_movimentacoes', { url: '/projects/:id/sicop', templateUrl: 'dashboard/project/prj-movimentacoes.html', controller: 'PrjMovimentacoesController', params: { id: '' }, resolve: { auth: authenticateRoute } })
      .state('prj_documentos', { url: '/projects/:id/documents', templateUrl: 'dashboard/project/prj-documentos.html', controller: 'PrjDocumentosController', params: { id: '' }, resolve: { auth: authenticateRoute } })

      .state('process_dashboard', { url: '/processo/:number/:year/dashboard', templateUrl: 'dashboard/process/process-dashboard.html', controller: 'ProcessDashboardController', params: { number: '', year: '' }, resolve: { auth: authenticateRoute } })

      .state('user_assinatura', {
        url: '/user_subscription',
        templateUrl: 'user/user-assinatura.html',
        controller: 'UserSubscription',
        resolve: { auth: authenticateRoute }
      })
      .state('user_profile', {
        url: '/user_profile',
        templateUrl: 'user/user-profile.html',
        controller: 'ProfileController',
        resolve: { auth: authenticateRoute }
      });
    $urlRouterProvider.otherwise('/');

  })

  //#make mobile-angular-ui works with ui.router
  .run(function ($rootScope) {
    $rootScope.$on('$stateChangeStart', function () {
      $rootScope.broadcast('$routeChangeStart');
    });
    $rootScope.normalizeSRC = function (src) {
      try {
        if (typeof (window.cordova) != 'undefined' && window.cordova.platformId.toLowerCase() === "android") {
          if (!src.startsWith('/')) src = '/' + src;
          var new_src = "/android_asset/www" + src;
          return new_src
        }else if( window.cordova &&  window.cordova.platformId.toLowerCase() === 'ios'){
          console.log(window.cordova.file.applicationDirectory );
          return (window.cordova.file.applicationDirectory + src);

        }
      } catch (e) {
        console.log(e)
      }
      return src;
    };
  });

