angular.module('mineXplore')
.factory("Privileges", [ function(){
  var user;
  return{
    set: function(current_user){
      user = current_user;
    },
    check: function(class_name, action){
      console.log('privileges check: ', action);
      if(user==undefined || user.privileges == undefined){
        return false;
      }
      var found = false;
      angular.forEach(user.privileges, function(privilege){
        if(privilege['class_name'] == class_name ){
          if(privilege['action'] == action){
            found =  true;
          }
        }
      });
      return found;
    }
  }
}]);