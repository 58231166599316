angular.module('mineXplore')
    .factory('httpLoading', [
        '$q', '$rootScope', '$state', '$injector', 'usSpinnerService', 'SweetAlert',
        function ($q, $rootScope, $state, $injector, usSpinnerService, SweetAlert) {
            var activeRequests = 0;
            var sw_login_enabled = false;
            var popup_visible = false;
            return {
                // optional method
                'request': function (config) {
                    //console.log('spinner request');
                    //console.log($q);
                    usSpinnerService.spin('spinner-1');
                    activeRequests++;
                    return config;
                },
                // optional method
                'requestError': function (rejection) {
                    console.log('spinner requestError', rejection);
                    activeRequests--;
                    usSpinnerService.stop('spinner-1');
                    // if (canRecover(rejection)) {
                    //   return responseOrNewPromise
                    // }
                    return $q.reject(rejection);
                },
                'response': function (response) {
                    //console.log('spinner response');
                    activeRequests--;
                    usSpinnerService.stop('spinner-1');
                    return response;
                },
                'responseError': function (rejection) {
                    usSpinnerService.stop('spinner-1');
                    activeRequests--;

                    if (rejection.status == 401 && $rootScope.user && !isEmpty($rootScope.user)) {
                        console.log('entrou na sessao expirada?', $rootScope.user)

                    } if (rejection.status == 401 && sw_login_enabled == false){

                    }

                    else if (rejection.status == 401 && sw_login_enabled == true && !popup_visible) {
                        SweetAlert.swal({
                            title: 'Autenticação',
                            html: '<input type="text" id="email" class="swal2-input" placeholder="e-mail"></input>' +
                                '<input type="password" id="senha" class="swal2-input" placeholder="password"></input>',
                            confirmButtonText: 'Login',
                            preConfirm: function () {
                                return new Promise(function (resolve, reject) {
                                    console.log('asdf');
                                    let email = document.getElementById('email').value
                                    let password = document.getElementById('senha').value
                                    if (email === '' || password === '') {
                                        reject('Preecha corretamente o e-mail e senha antes de continuar')
                                    } else {
                                        $injector.get('$auth').submitLogin({
                                            email: email,
                                            password: password
                                        })
                                        resolve();
                                        $state.reload();
                                    }
                                    //return { email: email, password: password }
                                })
                            }
                        })
                    }
                    // if (canRecover(rejection)) {
                    //   return responseOrNewPromise
                    // }
                    return $q.reject(rejection);
                }
            };
        }
    ]);