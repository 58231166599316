/**
  User Seaches - Manage Seach parameters
*/
angular.module('mineXplore')
.factory("DouModal", ['$uibModal', 'Dou', 'Notification', function($uibModal, Dou, Notification) {
  console.log('DouModal initialized');

  return {
    open: function($scope, dt_dou){
                $scope.cancel = function(){
                  modal_dou.close();
                }
                Dou.get({ id: dt_dou },
                    function (result) {
                      console.log('entrou', dt_dou, result);
                      $scope.dou_url = result.url;
                      modal_dou = $uibModal.open({
                        templateUrl: 'common/dou_view_modal.html',
                        scope: $scope,
                      });
                      modal_dou.result.then(
                        function (result) {
                          console.log('DOU OK')
                        },
                        function (error) {
                          console.log('DOU error' + error)
                        });
                    },
                    function (error) {
                        Notification.error(error.data.message)
                        console.log('Error while get DOU link');
                        console.log(error);
                    }
                )
    },
  }
}
]);