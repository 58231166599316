angular.module('mineXplore')
.factory("Substance", ['$resource', 'config', function($resource, config) {

  var items = [];
  var last_request_failed = true;
  var promise = undefined;

  var Substance;
  Substance = $resource(config.apiUrl + config.baseUrl  + "/common/substances/:id.json",
    { id: "@id" },
    {
      update: { method: "PUT" },
      destroy: { method: "DELETE" },
      //save: {method: 'POST'},
      //create:{ method: "POST"},
      query: {method: 'GET', isArray: false, cache: true },
      fetch: {method: 'GET', isArray: false, cache: true }
    }
    );

  Substance.prototype.destroy = function(cb) {
    return Substance.remove({
      id: this.id
    }, cb);
  };
  Substance.prototype.getColor = function(list, substance){

  };
  Substance.prototype.setFillColor = function(data, substance){

  };
  Substance.cache = function(){
    if(items.length == 0 ){
       return Substance.query(
        function (result) {
          console.log('substance api data returned');
          items = result;
        }
        );
    }else{
      console.log('substance data cached returned');
      return items;
    }


  }
  return Substance;
}]);
