'use strict';
angular.module('mineXplore')
.controller('DashboardEventosController',
  ['$scope', 'EnterpriseHeader', 'GlobalScm', 'CalendarHelper', function ($scope, Header, GlobalScm, cal) {
    Header.get({id: 'header'}, function(response){
      $scope.company = response.data.attributes;
    });
    var load_data = function(){
      var dt_param = { dt_ini: cal.dt_ini.get(), dt_end: cal.dt_end.get() };
      GlobalScm.query(dt_param, function(response){
        console.log(response);
        $scope.data = response.data;
      }, function(error){
        console.log(error);
      });
    }
    $scope.load_data = load_data;
    $scope.has_data = function(){
      dt = []
      if($scope.data==undefined){ return false; }
      $scope.data.map(function(m){ dt.push(m.attributes.processes) })
      if(dt.length > 0 && dt[0].length > 0 ){ return true; }
    }
  }
  ]
  )
;
