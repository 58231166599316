(function () {
  var cors_api_host = 'cors-anywhere.herokuapp.com';
  var cors_api_url = 'https://' + cors_api_host + '/';
  var slice = [].slice;
  var origin = window.location.protocol + '//' + window.location.host;
  var open = XMLHttpRequest.prototype.open;

  XMLHttpRequest.prototype.open = function () {
    var args = slice.call(arguments);
    var targetOrigin = /^https?:\/\/([^\/]+)/i.exec(args[1]);
    //console.log(targetOrigin);
    if (targetOrigin && targetOrigin[0].toLowerCase() !== origin &&
      targetOrigin[1] !== cors_api_host) {
      if (args[1].indexOf('http:') == 0) {
        //console.log(targetOrigin);
        //console.log(targetOrigin[1]);
        //console.log(args[1]);
        args[1] = cors_api_url + args[1];
      }
    }
    return open.apply(this, args);
  };
})();