angular.module('mineXplore')
  .controller('MapController',
    ['$scope',
      '$rootScope',
      '$window',
      '$uibModal',
      '$state',
      'Process',
      'Substance',
      'SubstanceCategory',
      'SweetAlert',
      'uiGmapIsReady',
      '$timeout',
      'exportCsv',
      'MapLabels',
      'gMapHelper',
      'olHelper',
      'mxHelper',
      'olDraw',
      'gridHelper',
      'olMeasure',
      'olContextMenu',
      'Notification',
      'GeoIp',

      function ($scope, $rootScope, $window, $uibModal, $state,
        Process, Substance, SubstanceCategory,
        SweetAlert, uiGmapIsReady, $timeout,
        exportCsv, MapLabels, gMapHelper, olHelper, mxHelper,
        olDraw, gridHelper, olMeasure, olContextMenu, Notification, GeoIp) {
        console.log('MapController');
        $scope.center = { lat: -15.3804, lon: -55.5124, zoom: 4 };
        $scope.olmap = new ol.Map({
          target: 'map',
          layers: olHelper.static_layers(),
          view: new ol.View({
            center: ol.proj.fromLonLat([$scope.center.lon, $scope.center.lat]),
            zoom: $scope.center.zoom,
            enableRotation: false
          }),
          controls: olHelper.mapControls(),
          loadTilesWhileAnimating: true,
        });
        olHelper.addLayers($scope.olmap);
        olContextMenu.init($scope.olmap);
        olHelper.enable_import($scope.olmap);
        $scope.mapMenuEnabled = true;
        $scope.autocenter;
        $rootScope.advanced_search_enabled = true;
        $scope.maximizeGrid = function (status) {
          gridHelper.animate($scope, $rootScope);
          $scope.showProcessInfo = true;
        };

        let add_click_info_interation = function () {
          let click_info_callback = function (e) {
            if (e.target.getFeatures().getLength() == 0)
              return;
            console.log('click_info Callbacks')
            let processo = e.selected[0].getProperties();//$scope.polygons.find(function (o) { return o.properties.processo == e.selected[0].get('data').processo })
            highlight_selected(e)
            $scope.showMore(processo, true)
            $scope.showDetails(processo.processo_id);
            $scope.$apply();
          };
          olHelper.click_info($scope.olmap, click_info_callback);
        };

        // highlight selected feature
        // change line stroke when it is seleced by click
        let highlight_selected = function(e){
          let selected_feature = e.selected[0];
          if(e.selected.length == 1 ){
            let deselected = e.deselected[0];
            var estilo = selected_feature.getStyle()[0];
            var stroke = estilo.getStroke();
            var color = estilo.getFill().getColor();
            var new_color = color.replace(/[\d\.]+\)$/g, '0.6)');
            stroke.setWidth(4);
            estilo.getFill().setColor(new_color);
          }
        }

        let disable_info = function () {
          olHelper.disable_info($scope.olmap);
        };
        // highlight feature strokes on mouse over ol.feature
        let add_pointer_move_interation = function () {
          let callback = function (e) {
            if (e.target.getFeatures().getLength() == 0)
              return;
            if(e.selected.length > 0 )
              highlight_hover(e);
            if(e.deselected.length > 0)
              tonedown_hover(e)
          };
          olHelper.pointer_move($scope.olmap, callback);
        };

        // emphasize feature stroke when mouse over
        let highlight_hover = function(e){
          var selected_feature = e.selected[0];
          var selected_stroke = selected_feature.getStyle()[0].getStroke();
          selected_stroke.setWidth(5);
        }
        // tone down the feature stroke after mouse out
        let tonedown_hover = function(e){
          var deselected_feature = e.deselected[0];
          var deselected_stroke = deselected_feature.getStyle()[0].getStroke();
          deselected_stroke.setWidth(1);
        }

        let disable_pointer_move = function () {
          olHelper.disable_pointer_move($scope.olmap);
        };
        /* EOOL */
        $scope.stages = [];
        $scope.categories = [];
        $scope.mapLabels = [];
        $scope.query = '';
        //Map Params
        $scope.searchEnabled;
        $scope.filterEnabled;
        $scope.quickSearchText;

        $scope.drawingEnabled;

        $scope.map_labels = {};
        $scope.map_labels.enabled = false;
        $scope.monitoringEnabled;
        $scope.legendEnabled;
        $scope.lengendVisible = false;
        $scope.show_window_info = false;
        $scope.showProcessInfo = false;
        $rootScope.advanced_search_enabled = false;
        //error messages
        let msg_err_proc_detail = {
          title: "Ops!", type: "error",
          text: "Ocorreu uma falha ao obter os detalhes deste processo"
        };
        /*
        * Enable or disable Grid view Over the Map
        */
        $scope.dismissResult = function () {
          if ($scope.stateQueMuda) {
            $("#map_mal").fadeIn(1000);
            $scope.enableGrid(false);
          } else {
            $scope.enableGrid(false);
          }
        };
        /**
         * Control Process view - check if details are expanded
         * @param {*} item
         */
        $scope.isExpanded = function (item) {
          if (item != undefined) {
            if (item.expanded)
              return true;
          }
          return false;
        };
        /**
         * Togle Processo View: Expanded or Colapsed
         * @param {*} item
         */
        $scope.showMore = function (item, expandedStatus) {
          if (expandedStatus == undefined) {
            if (item.expanded != undefined)
              item.expanded = !item.expanded;
            else
              item.expanded = true;
          } else {
            item.expanded = expandedStatus;
          }
        };

        $scope.gridCollapsed = false;

        $scope.enableGrid = function (state) {
          $scope.grid_enabled = state;
          $rootScope.grid_enabled = state;

        };
        $scope.showLegend = function (state) {
          $scope.lengendVisible = state;
          if (!angular.isUndefined($scope.apply)) {
            $scope.apply();
          }
          //gridHelper.animate($scope, $rootScope);
        };
        let refresh_query = function (event) {
          console.log('refresh_query: ', event)
          if (event.type == google.maps.drawing.OverlayType.CIRCLE) {
            params = { circle: { radius: event.overlay.getRadius(), center: event.overlay.getCenter() } }
            $scope.fnSearch(null, params);
          }
          else if (event.type == google.maps.drawing.OverlayType.RECTANGLE) {
            $scope.fnSearch(null, { rectangle: { bounds: event.overlay.getBounds() } })
          }
          else if (event.type == google.maps.drawing.OverlayType.POLYGON) {
            $scope.fnSearch(null, { polygon: { paths: event.overlay.getPath().getArray() } })
          } else if (event.type == 'drawend') {
            let feature = event.feature;
            let src = 'EPSG:3857', dest = 'EPSG:4326';
            let geometry = feature.getGeometry().transform(src, dest);
            if (feature.getGeometry().getType() == 'Circle') {
              $scope.fnSearch(null, circleParam(geometry));
            }
            else if (feature.getGeometry().getType() == 'Polygon') {
              $scope.fnSearch(null, polyParam(geometry))
            }
          }
          //$scope.showLabels()
        };
        let drawPolygon = function (mapType) {
          if (mapType == 'gm')
            $scope.drawingManagerControl.getDrawingManager().setDrawingMode(google.maps.drawing.OverlayType.POLYGON)
          else
            olDraw.draw($scope.olmap, 'Polygon', refresh_query)
        };

        let drawBox = function (mapType) {
          if (mapType == 'gm')
            $scope.drawingManagerControl.getDrawingManager().setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE);
          else
            olDraw.draw($scope.olmap, 'Box', refresh_query);
        };

        let drawCircle = function (mapType) {
          if (mapType == 'gm')
            $scope.drawingManagerControl.getDrawingManager().setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
          else
            olDraw.draw($scope.olmap, 'Circle', refresh_query)
        };

        let drawLineString = function (mapType) {
          if (mapType == 'gm')
            $scope.drawingManagerControl.getDrawingManager().setDrawingMode(google.maps.drawing.OverlayType.POLYLINE);
          else
            olDraw.draw($scope.olmap, 'LineString', refresh_query);
        };

        let stopDrawing = function (mapType) {
          if (mapType == 'gm')
            $("div[title='Stop drawing']").children().trigger("click");
          else
            olDraw.stop($scope.olmap);
        };
        let enableInfoClick = function () {
          disable_info();
          stopDrawing();
          olMeasure.stop();
          add_click_info_interation();
          add_pointer_move_interation();
        };

        let enableWmsInfoClick = function () {
          disable_info();
          stopDrawing();
          olMeasure.stop();

          $scope.olmap.on('singleclick', function (evt) {
            olHelper.featureInfo(evt, $scope.olmap);
          });

        };

        $scope.menuAction = function (action) {
          console.log('menuAction triggered');
          disable_info();
          if (action == 'draw.line') {
            olMeasure.stop();
            drawLineString($scope.map.type);
          } else if (action == 'draw.polygon') {
            drawPolygon($scope.map.type);
          } else if (action == 'draw.circle') {
            olMeasure.stop();
            drawCircle($scope.map.type);
          } else if (action == 'draw.box') {
            olMeasure.stop();
            drawBox($scope.map.type);
          } else if (action == 'setmap.ol') {
            $scope.map.type = 'ol';
          } else if (action == 'setmap.gm') {
            $scope.map.type = 'gm';
          } else if (action == 'draw.stop') {
            stopDrawing();
          } else if (action == 'feature.info') {
            olMeasure.stop();
            enableInfoClick();
          } else if (action == 'feature.wmsinfo') {
            enableWmsInfoClick();
            olMeasure.stop();
          } else if (action == 'measure.rule') {
            stopDrawing();
            olMeasure.start($scope.olmap, 'length');
          } else if (action == 'measure.area') {
            stopDrawing();
            olMeasure.start($scope.olmap, 'area');
          } else if (action == 'show.process') {
            $scope.showProcessInfo = !$scope.showProcessInfo;
            if ($scope.showProcessInfo) {
              $rootScope.advanced_search_enabled = false;
              $scope.enableGrid(false);
            }
          } else if (action == 'show.filter') {
            $rootScope.advanced_search_enabled = !$rootScope.advanced_search_enabled;

            if ($rootScope.advanced_search_enabled) {
              $scope.showProcessInfo = false;
              $scope.enableGrid(false);
            }
          } else if (action == 'show.legend') {
            $scope.lengendVisible = !$scope.lengendVisible;
          } else if (action == 'show.processList') {
            $scope.enableGrid(!$rootScope.grid_enabled);
            if ($rootScope.grid_enabled) {
              $rootScope.advanced_search_enabled = false;
              $scope.showProcessInfo = false;
            }
          } else {
            // console.log('MenuAction Action is not mapped', action);
          }

        };
        $scope.isMap = function () {
          return $state.is("map") || $state.is("map2");
        };

        /*
        * Poligon Window Properties & Events
        */
        $scope.polywindow = {
          model: {},
          closeClick: function () { $scope.polywindow.show = false; },
          coords: { latitude: 53, longitude: 20 },
          show: false,
          options: { zIndex: 1 }
        };
        /*
        * Initial Map Properties
        */
        $scope.map = {
          type: 'ol',
          center: { latitude: -20.3804, longitude: -43.5124 },
          zoom: 4,
          tilt: 45,
          bounds: {},
          control: {},
          window: {
            model: {},
            show: false,
            options: {
              pixelOffset: { width: -1, height: -20 }
            }
          }
        };
        /**
         *  Show Process Details Window
         * @param {*} processo_id
         */
        $scope.showDetails = function (processo_id) {

            $scope.details = null;
            Process.details({ id: processo_id }, function (result) {
              $scope.polywindow.model.details = result;
              $scope.details = result.data;
              $scope.polywindow.show = $scope.show_window_info;
              $scope.polywindow.model.selected = true;
              $scope.showProcessInfo = true;
              $rootScope.advanced_search_enabled = false;
              $scope.enableGrid(false);
              if ($scope.$parent.showListProcess != undefined) {
                $scope.$parent.showListProcess();
              }
              auto_update_details(result);
            }, function (error) {
              console.log(error);
              if (error.data != undefined && error.data.message != undefined)
                msg_err_proc_detail['text'] = error.data.message
              Notification.error(msg_err_proc_detail['text']);
            });
        };

        let auto_update_details = function(obj){
          updated_at = moment(obj.data.attributes['updated-at'])
          moment_threshould = moment().subtract(1, 'hour'); //#.format("YYYY-MM-DD");
          if (moment_threshould.isAfter(updated_at) ) {
            $timeout(function () {
              Process.details({ id: obj.data.id }, function (result) {
                $scope.details = result.data;
              });
            }, 15000);
          } else {
             // date is future
             console.log('area is fresh new. auto_update_details skiped');
          }
        }

        /*
        * Polygon Events
        */
        $scope.polygons = [];
        //Evento click polygono
        $scope.events = gMapHelper.gmaps_events($scope, exportCsv);

        /*
        * Zoom to fit polygon
        */
        $scope.mapMenuEnabled = false;
        $scope.processEnabled = false;

        $scope.$watch(
          function () {
            return $rootScope.advanced_search_enabled;
          }, function () {
            $scope.showFilters = $rootScope.advanced_search_enabled;
          });
        $scope.$watch(
          function () {
            return $rootScope.filterService;
          },
          function () {
            console.log('watcher filterService: ' + $rootScope.filterService);
            if ($rootScope.filterService.filters === true)
              $scope.processEnabled = !$scope.processEnabled;
            if ($rootScope.filterService.enabled === true)
              enableSearch(!$scope.mapMenuEnabled);
          });

        let enableSearch = function (state) {
          $scope.mapMenuEnabled = state;
        };

        $scope.zoom = function (i) {
            $rootScope.filterService = { process_id: i };
            $scope.showDetails(i);
            $('#map_menu_show').fadeIn();
            if ($scope.map.type == 'gm') {
              let gPoly = $scope.map.control.getPlurals().get(i);
              gMapHelper.zoom(gPoly, $window);
            } else if ($scope.map.type == 'ol') {
              olHelper.zoomToFeatureId($scope.olmap, i);
            }
        };
        /**
         * Show Process Code Label on Each Polygon
         */
        $scope.showLabels = function () {
          $scope.mapLabels = MapLabels.show($scope.map_labels.enabled, $scope.map, $scope.mapLabels, $scope.polygons);
        };

        /*
        * Aplica filtro em polygon por fases de processo
        */
        $scope.filterStage = function (item) {
          mxHelper.filterStage($scope.olmap, $scope.polygons, item);
        };

        /*
        * Aplica filtro em polygon por categorias de substancia do processo
        */
        $scope.filterCategories = function (item) {
          mxHelper.filterCategories($scope.olmap, $scope.polygons, item);
        };

        /*
        * Map Initialization
        */
        $scope.init = function () {
          console.log('map init()');
          Substance.cache().$promise.then(function(result){
            $scope.substances = result.data;
            paint();
          }, function(){
            Notification.info('infelizmente não consegui carregar a lista de cores de Substâncias');
          })

          SubstanceCategory.cache().$promise.then(function (result) { $scope.substance_categories = result.data; });
        };

        /*
        * Map Default Options
        */
        $scope.options = gMapHelper.gmaps_options();

        /*
         * Drawing Options - For polygon, circle & rectangle
         */
        let drawOptions = gMapHelper.gmaps_draw_options();

        /**
         * Drawing Manager Default Options
         */
        $scope.drawingManagerOptions = gMapHelper.gmaps_drawing_manger_options(drawOptions);
        $scope.ControlDrawer = function () {
          $scope.drawingManagerControl.getDrawingManager().getMap() == null ? $scope.drawingManagerControl.getDrawingManager().setMap($scope.map.control.getGMap()) : $scope.drawingManagerControl.getDrawingManager().setMap(null)
        };

        /**
         * Paint Map Polygons according with substance of Area
         */
        let paint = function () {
          if ($scope.processes && $scope.processes.features) {
            $scope.processes = $scope.setFillColor($scope.processes);
            $scope.polygons = $scope.processes.features;
          }
        };

        //Atribui propriedades de cores a cada poligono se acordo com a Cor da Categoria das Substancias
        //TO-DO: e atribuir opçao de cor  do poligono de acordo com as fase do processo
        $scope.setFillColor = function (data) {
          if (!(data && data.features)) { return; }
          stages = [];
          categories = [];
          angular.forEach(data.features, function (feature, idx) {
            stages, categories = mxHelper.fillElement(data, idx, $scope.substances);
          });
          //acc by stage
          $scope.stages = mxHelper.acc_stages(stages);
          //acc by category
          $scope.categories = mxHelper.acc_categories(categories);
          return data;
        };
        /**
         * Mark all process as selected
         */
        $scope.allSelected = false;
        $scope.selectAll = function () {

          $scope.allSelected = !$scope.allSelected;
          angular.forEach($scope.processes.features, function (v, l) {
            if ($scope.allSelected) {
              console.log(v);
              if (v.visible) {
                v.selected = true
              } else {
                v.selected = false
              }
            } else {
              v.selected = false
            }
          });
        };
        /**
         * Open Modal for Project Process Association
         */
        $scope.modal_monitorar = function (p) {
          let selectedProcesses = [];
          angular.forEach($scope.processes.features, function (v, k) {
            if (v.selected == true) {
              selectedProcesses.push(v.properties.processo);
            }
          });
          if (p)
            selectedProcesses.push(p.attributes != undefined ? p.attributes.processo : p.processo);

          if (selectedProcesses.length > 0) {
            selectedProcesses.filter(function (value, index, self) {
              return self.indexOf(value) === index;
            });
            let modalInstance = $uibModal.open({
              templateUrl: 'project/modal_project_add.html',
              controller: 'ProjectModalController',
              resolve: {
                data: function () { return selectedProcesses; }
              }
            });
          } else {
            Notification.info("Você deve selecionar ao menos 1 processo.")
          }
        };



        $scope.fnSearch = function (params, keys) {
          if (keys == null || keys == undefined) {
            console.log('fnSearch. skip for null reason');
            return false;
          }
          console.log('fnSearch', keys);
          $scope.allSelected = false;
          if (keys && keys.hide_filter) {
            enableSearch(!$scope.mapMenuEnabled);
            delete keys.hide_filter;
          }
          if (!($state.params.number && $state.params.year)) {

            Process.search(keys, function (data) {
              $scope.processes = data;
              paint();
              $rootScope.searchService = null;
              $scope.enableGrid(true);
              olHelper.doIt($scope.olmap, $scope.processes);
              // $scope.lengendVisible = true;
              $scope.showProcessInfo = false;
              $rootScope.advanced_search_enabled = false;
              if ($scope.$parent.showListProcess != undefined) {
                $scope.$parent.showListProcess();
              }
              speed_view();
              enableInfoClick();

            },
              function (error) {
                console.log('erro ao fazer busca');
                console.log(error)
                Notification.error({ message: error.data.message, delay: 2000, positionX: 'left' });
                $scope.showProcessInfo = false;
                olHelper.doIt($scope.olmap, { fea });
              });
          } else if ($state.params.number && $state.params.year) {
            Process.query({ searchText: $state.params.number + '/' + $state.params.year }, function (data) {
              olHelper.doIt($scope.olmap, data);
              speed_view();
            });
          } else {
            console.log('unrecognized params for search on map controller');
          }
        };

        let speed_view = function () {
          if ($scope.processes.features.length == 1) {
            $scope.showDetails($scope.processes.features[0].id);
          }
        }

        if ($state.params.search || ($state.params.number && $state.params.year)) {
          $scope.fnSearch(null, { searchText: $state.params.search })
        }
        if ($state.params.process) {
          $scope.zoom($state.params.process)
        }

        $scope.$watch('data', function () {
          if ($scope.data == undefined || $scope.data.features == undefined || $scope.data.features.length == 0) {
            olHelper.doIt($scope.olmap, [$scope.processes]);
            return
          }
          $rootScope.searchService = null;
          console.log('+++++data changed++++', $scope.data);

          $scope.processes = $scope.data;
          paint();
          olHelper.doIt($scope.olmap, $scope.processes);
          enableInfoClick();
        })
        $scope.$watch(
          function () {
            return $rootScope.searchService;
          },
          function () {
            console.log('watcher searchService: ' + $rootScope.searchService);
            if ($rootScope.searchService != undefined && $rootScope.searchService != null && $rootScope.searchService != {}) {
              console.log('check ', $rootScope.searchService);
              $scope.fnSearch(null, $rootScope.searchService);
            }

          });

        $scope.$watch(
          function () {
            return $rootScope.sideMenuService;
          },
          function () {
            console.log('watcher sideMenuService: ' + $rootScope.sideMenuService);
            if ($rootScope.sideMenuService != null)
              $scope.menuAction($rootScope.sideMenuService);
            $rootScope.sideMenuService = null;
          });

        $scope.drawingManagerControl = {};

        /*
        Callbacks of Drawing Manager
        */
        $scope.search_overlay = null;
        gMapHelper.gmaps_init(uiGmapIsReady, $scope, refresh_query);
        /*
        Google Maps or OpenLayers Refresh Query
        */

        function polyParam(geometry) {
          let path = geometry.getCoordinates()[0];
          let path_legacy = [];
          for (i = 0; i < path.length; i++) { path_legacy.push({ lat: path[i][1], lng: path[i][0] }) };
          return { polygon: { paths: path_legacy } };
        }

        function circleParam(geometry) {
          let circleCenter = { lat: geometry.getCenter()[1], lng: geometry.getCenter()[0] };
          let radiusUnits = geometry.getRadius() * 100000;
          return { circle: { radius: (radiusUnits), center: circleCenter } };
        }


        /**
         * Map Actions to fire after change map Bounds
         */
        $scope.$watch(function () { return $scope.map.bounds; },
          function (nv, ov) {
            //add events to drawingManager
            $scope.center = { lat: $scope.map.center.latitude, lon: $scope.map.center.longitude, zoom: $scope.map.zoom };
          }, true);
        /**
         * Extend Google maps to work with GeoJson objects
         */

        google.maps.Map.prototype.getGeoJson = gMapHelper.extendGeoJsonObject;
        google.maps.Polygon.prototype.enableCoordinatesChangedEvent = gMapHelper.coordinatesChangedEvent;
        google.maps.Polyline.prototype.enableCoordinatesChangedEvent = gMapHelper.coordinatesChangedEvent;
        google.maps.Circle.prototype.enableCoordinatesChangedEvent = gMapHelper.coordinatesChangedEvent;
        google.maps.Rectangle.prototype.enableCoordinatesChangedEvent = gMapHelper.coordinatesChangedEvent;
        //
        let locate_by_ip = function () {
          GeoIp.get({},
            function (result) {
              let coords = result.loc.split(',');
              let lon = parseFloat(coords[0]);
              let lat = parseFloat(coords[1]);
              center_map(lat, lon, 12);
            },
            function (error) { console.log(error) }
          );
        };


        let center_map = function (lat, lon, zoom) {
          //angular gmaps center
          $scope.map.center.latitude = lat;
          $scope.map.center.longitude = lon;
          $scope.map.center.zoom = zoom;
          //openlayer center
          $scope.olmap.getView().animate({
            center: ol.proj.transform([lat, lon], 'EPSG:4326', 'EPSG:3857'),
            duration: 3000,
            zoom: zoom
          });
        };
        //
        if ($scope.autocenter) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
              center_map(position.coords.longitude, position.coords.latitude, 12);
            },
              function (error) {
                console.log(error);
                locate_by_ip();
              }
            );
          } else {
            locate_by_ip();
          }
        }


      }]);
