'use strict';
angular.module('mineXplore')
.controller('DashboardDocumentosController',
  ['$scope', 'EnterpriseHeader', 'GlobalDocument', 'CalendarHelper', function ($scope, Header, Document, cal) {
    Header.get({id: 'header'}, function(response){
      $scope.company = response.data.attributes;
    });

    var load_data = function(){
      var dt_param = { dt_ini: cal.dt_ini.get(), dt_end: cal.dt_end.get() };
      Document.get(dt_param,
        function(response){
          console.log(response);
          $scope.data = response.data;
        },
        function(error){
          console.log(error);
      });
    }
    $scope.load_data = load_data;
    $scope.has_data = function(){
      dt = []
      if($scope.data==undefined){ return false; }
      $scope.data.map(function(m){
        m.attributes.areas.map(function(d){
          dt.push(d.documents);
        })
      })
      if(dt.length > 0 && dt[0] != undefined && dt[0].length > 0 ){
        return true;
      }
    }
    $scope.project_documents = function(project_id){
      documents = [];
      angular.forEach($scope.data, function(project, key){
        if(project.id == project_id && project.attributes != undefined){
          angular.forEach(project.attributes.areas, function(area){
            angular.forEach(area.documents, function(doc, key){
              doc['area'] = area.area;
              documents.push(doc)
            })
          })
        }
      })
      return documents;
    }
  }
  ]
  )
;
