'use strict';
angular.module('mineXplore')
    .service('mxHelper', ['olStyles', function (olStyles) {
        function filter_by_attribute(map, field, item, olStyles) {
            var lg = map.getLayers().getArray().filter(group => group.getProperties().name == 'minexplore_search');
            l1 = lg[0].getLayers().getArray().forEach(function (l1) {
                l1.getSource().getFeatures().forEach(function (ft) {
                    if (ft.getProperties()['data'][field] == item.name) {
                        if (!item.selected) {
                            console.log('unset style');
                            ft.setStyle([]);
                        }
                        else {
                            console.log('set style');
                            var lbl = olStyles.text_label(ft.getProperties()['data'].processo);
                            ft.setStyle(olStyles.setStyle(ft, lbl));
                        }
                    }
                });
            });
        };

        return {
            'acc_categories': function (categories) {
                var tmp = [];
                angular.forEach(categories, function (v, i) {
                    cat = _.find(tmp, { name: v.name });
                    cat ? cat.count += 1 : tmp.push(angular.merge(v, { 'count': 1, 'selected': true }));
                });
                return tmp;
            },
            'acc_stages': function (stages) {
                {
                    var tmp = [];
                    var count = {};
                    angular.forEach(stages, function (i) { count[i] = (count[i] || 0) + 1; });
                    Object.keys(count).forEach(function (key, val) { tmp.push({ 'name': key, 'count': val + 1, 'selected': true }); });
                    return tmp;
                }
            },
            /*
            * Aplica filtro em polygon por fases de processo
            */
            'filterStage': function (map, findPolys, item) {

                angular.forEach(findPolys, function (feature, d) {
                    if (angular.isDefined(findPolys[d])) {
                        if (findPolys[d].properties.fase == item.name) {
                            findPolys[d].visible = item.selected;
                        }
                    }

                });
                filter_by_attribute(map, 'fase', item, olStyles);
            },
            /*
            * Aplica filtro em polygon por categorias de substancia do processo
            */
            'filterCategories': function (map, findPolys, item) {
                angular.forEach(findPolys, function (feature, d) {
                    if (findPolys[d].properties.category == item.name) {
                        findPolys[d].visible = item.selected;
                    }
                });
                filter_by_attribute(map, 'category', item, olStyles);
            },
            'fillElement': function (data, d, substances) {
                data.features[d].visible = true;
                angular.forEach(substances, function (subs) {
                    if (data.features[d].properties['subs'] == subs.attributes.name) {
                        data.features[d].properties['category'] = subs.attributes.group.category.name;
                        data.features[d].properties['fill'] = { color: subs.attributes.group.category.color, opacity: 0.1 };
                        data.features[d].properties['stroke'] = { color: subs.attributes.group.category.color, opacity:1, weight: 2 };
                        stages.push(data.features[d].properties.fase); //acc
                        categories.push(subs.attributes.group.category);
                    }
                });
                return stages, categories
            }
        }
    }
    ]);
