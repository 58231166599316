angular.module('mineXplore')
.factory("RelatedPeople", ['$resource', 'config', function($resource, config) {
  	var Obj;
  	Obj = $resource(config.apiUrl  + config.baseUrl + "/common/people/:id",
    	{ id: "@id", name: "@name", relation: "@relation" },
    	{
      		query: {method: 'GET',cache: false, isArray: true },
          get: {method: 'GET',cache: false },
      		update: {method: 'PUT'},
    	});
  	return Obj;
}]);
