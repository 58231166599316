'use strict';
angular.module('mineXplore')
    .controller('DouController', ['$scope', 'DouModal', function ($scope, Dou) {
        $scope.dt = new Date();
        $scope.showDOU = false;
        //$scope.DOU_date = new Date();
        let aux = 0;
        $scope.$watch('DOU_date', function (dou_date) {
            let value = dou_date;
            if (value == null || value == 'Invalid Date') {
                aux = 0;
            }

            if (aux > 0 && $scope.DOU_date) {
                console.log('DOU_date tezste: ', $scope.DOU_date, dou_date, aux);
                let dt = value;
                Dou.open($scope, dt);
            }
            aux++;
            $scope.showDOU = false;


        });
        $scope.maxDate = new String(new Date());
        $scope.minDate = new String(new Date(2000, 1, 1));
    }]);
