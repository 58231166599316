/**
  User Seaches - Manage Seach parameters
*/
angular.module('mineXplore')
.factory("DocViewModal", ['$uibModal', 'Dou', function($uibModal, Dou) {
  console.log('DocViewModal initialized');

  return {
    open: function($scope, link){
           $scope.dou_url = link;
           var modal_dou = $uibModal.open({
             templateUrl: 'common/doc_view_modal.html',
             scope: $scope,
           });
           modal_dou.result.then(
             function (result) {
               console.log('DOU OK')
             },
             function (error) {
               console.log('DOU error' + error)
             });
    },
  }
}
]);