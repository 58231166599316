'use strict';
angular.module('mineXplore')
  .service('olExporter', [function () {
    function serialize(formatter, features, contentType, filename) {
      console.log(formatter, features, contentType, filename);
      var str_exported = formatter.writeFeatures(features, { featureProjection: 'EPSG:3857' });
      console.log(str_exported);
      console.log(JSON.stringify(str_exported));
      let gpxContent = contentType; // "data:text/xml;charset=utf-8,"
      gpxContent += str_exported;
      var encodedUri = encodeURI(gpxContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", filename);
      link.innerHTML = "Download Feature";
      document.body.appendChild(link); // Required for FF
      link.click();
    }

    return {
      'export': function (formatter, features, contentType, filename) {
        serialize(formatter, features, contentType, filename)
      }
    };
  }
  ]);
