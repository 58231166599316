angular.module('mineXplore')
  .controller('PrjMapViewController', [
    '$scope',
    '$state',
    '$uibModal',
    'Project',
    'AreaProject',
    'Area',
    'Process',
    'SweetAlert',
    'Notification',
    function ($scope, $state, $uibModal, Project, AreaProject, Area, Process, SweetAlert, Notification) {
      console.log('PrjMapViewController')
      //load data
      $scope.project = { id: $state.params.id ,  processes: [{ id: '', processo: '' }] };
      $scope.projects = [];
      $scope.processes = [];

      Project.get({ id: $state.params.id },
        function (data) {
          console.log('Project Get ')
          console.log(data);
          $scope.project = data.data;
          $scope.firstLoading = false;
        },
        function (error) {
          console.log('falha ao carregar ');
          Notification.error('Erro ao carregar dados do projeto. '+ error.data.message);
        }
      );
      Area.get({ project_id: $state.params.id }, function (response) {
        console.log('area project');
        $scope.processes = response;
        console.log(response);
      }, function (error) {
        console.log('error load areas projects');
        console.log(error);
        Notification.error('Ocorreu uma falha ao carregar as poligonais do projeto.' + error.data.message);
      });


    }]);