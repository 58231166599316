'use strict';
angular.module('mineXplore')
  .controller('PrjDocumentosController',
    ['$scope', 'Project', 'ProjectDocument', '$state', 'CalendarHelper', function ($scope, Project, Document, $state, cal) {
      console.log('PrjDocumentosController');
      $scope.project_id = $state.params.id;
      Project.get({ id: $state.params.id },
        function (response) {
          console.log(response);
          $scope.project = response.data;
        },
        function (error) {
          console.log(error);
        });

      var load_data = function () {
        $scope.project = { id: $state.params.id };
        var params = { project_id: $state.params.id, dt_ini: cal.dt_ini.get(), dt_end: cal.dt_end.get() };
        Document.get(params,
          function (response) {
            console.log(response);
            $scope.data = response.data;
          },
          function (error) {
            console.log(error);
          });
      }
      $scope.load_data = load_data;

    }
    ]
  )
  ;
