/**
  User Buffers - Manage Seach parameters
*/
angular.module('mineXplore')
.factory("Members", ['$resource', 'config', function($resource, config) {
  var resource;
  resource = $resource(config.apiUrl + config.baseUrl + "/user/enterprise/members/:id.json",
    { id: "@id" },
    {
      update: {method: "PUT"},
      destroy: {method: "DELETE", params: {id: '@id'}},
      save: {method: 'PUT'},
      create:{method: "POST"},
      fetch: {method: 'GET', isArray: true },
      get: {method: 'GET', isArray: false }
    }
    );
  return resource;
}
]);
