angular.module('mineXplore')
  .factory("Area", ['$resource', 'config', function ($resource, config) {
    var obj = $resource(config.apiUrl + config.baseUrl + "/user/projects/:project_id/dashboard/areas/:id.json",
      { project_id: "@project_id", id: "@id" },
      {
        get: { method: 'GET', isArray: false },
        query: { method: 'GET', isArray: true },
      }
    );

    obj.prototype.destroy = function (cb) {
      return Process.remove({
        id: this.id
      }, cb);
    };


    return obj;
  }
  ]);
