angular.module('mineXplore')
    .controller('ProjectController', [
        '$scope',
        '$state',
        '$stateParams',
        '$location',
        '$uibModal',
        'Project',
        'Process',
        'SweetAlert',
        'Notification',
        function ($scope, $state, $stateParams, $location, $uibModal, Project, Process, SweetAlert, Notification) {
            //load data
            $scope.project = {processes: [{id: '', processo: ''}]};
            $scope.projects = [];
            $scope.processes = [];
            $scope.loading = false;
            $scope.firstLoading = true;
            $scope.selectedProcess = 0;
            if ($stateParams.id) {
                Project.get({id: $stateParams.id},
                    function (data) {
                        $scope.project = data.data;
                        $scope.processes = $scope.project.attributes.processes;
                        $scope.firstLoading = false;
                    },
                    function (error) {
                        console.log('falha ao carregar ');
                    }
                );
            } else {
                Project.query(function (data) {
                    $scope.projects = data.data;
                    $scope.firstLoading = false;
                });
            }
            //destroy with confirmation
            $scope.destroy = function (id) {
                console.log('destroy');
                var original;
                $scope.loading = true;
                if (confirm("Tem certeza que deseja excluir este projeto?")) {
                    original = $scope.projects.find(x => x.id === id);
                    Project.delete({id: id},
                        function (result) {
                            $scope.projects = _.without($scope.projects, original);
                            $scope.loading = false;
                        },
                        function (error) {
                            console.log('error');
                            console.log(error);
                            Notification.error({message: "Ocorreu uma falha ao excluir o processo.", delay: 2000, positionY: 'left'});
                            $scope.loading = false;
                        })
                }
            };
            $scope.viewOnMap = function (process) {
                console.log(process);
                $state.go('map', {number: process.numero, year: process.ano});
            };
            $scope.processesOrder, $scope.eventsOrder, $scope.actionsOrder = 'none';

            $scope.sortProcesses = function () {
                if ($scope.processesOrder != 'asc') {
                    $scope.processes.sort((a, b) => (a.processo.localeCompare(b.processo)));
                    $scope.processesOrder = 'asc';
                } else {
                    $scope.processes.sort((a, b) => (b.processo.localeCompare(a.processo)));
                    $scope.processesOrder = 'desc';
                }
            };
            $scope.selectProcess = function (id) {
                let index = $scope.project.attributes.processes.findIndex(x => x.id == id);
                $scope.process = $scope.project.attributes.processes[index];
                $scope.selectedProcess = id;
            };
            $scope.removeProcess = function (id) {
            };
            $scope.sortEvents = function () {
                if ($scope.eventsOrder != 'asc') {
                    $scope.process.events.sort((a, b) => (new Date(a.date) - new Date(b.date)));
                    $scope.eventsOrder = 'asc';
                } else {
                    $scope.process.events.sort((a, b) => (new Date(b.date) - new Date(a.date)));
                    $scope.eventsOrder = 'desc';
                }
            };
            $scope.sortActions = function () {
                if ($scope.actionsOrder != 'asc') {
                    $scope.process.actions.sort((a, b) => (new Date(a['due_date']) - new Date(b['due_date'])));
                    $scope.actionsOrder = 'asc';
                } else {
                    $scope.process.actions.sort((a, b) => (new Date(b['due_date']) - new Date(a['due_date'])));
                    $scope.actionsOrder = 'desc';
                }
            };

            $scope.removeArea = function (area) {
                if (confirm("Deseja realmente remover o processo ", area.processo)) {
                    let index = $scope.project.attributes.processes.indexOf(area);
                    $scope.project.attributes.processes[index]._destroy = true;
                    Project.update({id: $scope.project.id}, $scope.project,
                        function (project) {
                            $scope.project.attributes.processes.splice(index, 1);
                            console.log($scope.project, project);
                            Notification.success({message: "Processo removido", delay: 2000, positionY: 'left'});
                        }, function (error) {
                            console.log(error);
                            Notification.error({
                                message: "Ocorreu uma falha ao atualizar o projeto." + error,
                                delay: 2000,
                                positionY: 'left'
                            });
                        });
                }

            };
            $scope.createProject = function () {
                if (!$scope.loading) {
                    $scope.loading = true;
                    let modalInstance = $uibModal.open({
                        templateUrl: 'dashboard/project/prj-modal-cover.html',
                        controller: 'NewProjectModalController',
                        resolve: {
                            data: function () {
                                return;
                            }
                        }
                    });
                    modalInstance.result.then(function (newProject) {
                        $scope.loading = false;
                        if (newProject) $scope.projects.push(newProject);
                    }, function (err) {
                        $scope.loading = false;
                        console.log(err);
                    });
                }
            };
            $scope.showProject = function (id) {
                $state.go('showProject', {id: id});
            };

        }]);