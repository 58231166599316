angular.module("mineXplore")
  .directive('iframeDirective', ['$sce', function ($sce) {
    return {
      restrict: 'E',
      scope: {
        url: "@url"
      },
      template: '<iframe src="{{ trustedUrl }}" frameborder="0" allowfullscreen style="width:100%; height:100%"></iframe>',
      link: function (scope) {
        scope.trustedUrl = $sce.trustAsResourceUrl(scope.url);
      }
    }
  }]);