'use strict';
angular.module('mineXplore')
  .controller('PrjModalCoverController',
    ['$scope',
      '$uibModal',
      'fileReader',
      'Project',
      '$state',
      'SweetAlert',
      'Notification',
      function ($scope, $uibModal, fileReader, Project, $state, SweetAlert, Notification) {
        // console.log('PrjModalCoverController...');
        if($scope.project_id == undefined){
          $scope.project_id = $state.params.id;
        }
        $scope.modalInstance;
        $scope.open_modal = function () {
          Project.get({ id: $scope.project_id },
            function (response) {
              console.log(response);
              $scope.project = response;
            },
            function (error) {
              console.log(error);
          });
          $scope.modalInstance = $uibModal.open({
            animation: true,
            backdrop: false,
            templateUrl: "dashboard/project/prj-modal-cover.html",
            scope: $scope
          })
          $scope.modalInstance.result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
              console.log(res);
              throw res;
            }
          }
          );
        }
        $scope.cancel = function () {
          $scope.modalInstance.close();
          $scope.modalInstancen = null;
        }
        // file upload preview
        // http://next.plnkr.co/edit/y5n16v?p=preview&preview
        //console.log(fileReader)
        $scope.getFile = function (file) {
          $scope.file = file;
          $scope.progress = 0;
          fileReader.readAsDataUrl($scope.file, $scope)
            .then(function (result) {
              img = { image_data: result, urls: { thumb: result, original: result } }
              $scope.project.data.attributes['cover-image'] = img;
            });
        };

        $scope.$on("fileProgress", function (e, progress) {
          //console.log("fileProgress");
          $scope.progress = progress.loaded / progress.total;
        });

        $scope.save = function () {
          promisse = $scope.project.$update({ id: $scope.project.data.id });
          promisse.then(function (response) {
            Notification.success("Capa do projeto foi atualizada.")
            console.log(response);
            $scope.$parent.project = $scope.project.data;
            $scope.cancel();
          }, function (error) {
            Notification.error(error.data.message);
            console.log(error);
          })
        }
      }
    ]
  )
  ;
