angular.module('mineXplore')
.factory("AccountQuota", ['$resource', 'config', function($resource, config) {
  	var Profile;
  	Profile = $resource(config.apiUrl  + config.baseUrl + "/user/quotas/:id",
    	{ id: "@id" },
    	{
      		fetch: {method: 'GET',cache: true },
      		update: {method: 'PUT'},

    	});
  	return Profile;
}]);
