// Creating a simple Modal Directive
angular.module("mineXplore")
  .directive('modalUserProfile', ['$uibModal',
    function ($uibModal) {
      return {
        restrict: 'AE',
        controller:
        [ '$scope',
          '$uibModal',
          'Notification',
          function ($scope, $uibModal, Notification) {
            // console.log('ModalDocumentViewController...');
            $scope.data = {};
            var modalInstance;
            $scope.open_modal = function (url) {
              console.log(url);
              modalInstance = $uibModal.open({
                animation: true,
                backdrop: false,
                templateUrl: "user/user-profile-modal.html",
                controller: 'ProfileController',
                scope: $scope
              });
              modalInstance.result.catch(function (res) {
                  if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                    console.log(res);
                    throw res;
                  }
                }
                );
            }
            $scope.cancel = function () {
              //modalInstance.cancel();
              modalInstance.close()
            }
            //
          }
        ],

        scope: {
          url: "@url",
          title: "@title",
        },
        link: function (scope, element, attributes) {
          element.bind('click', function (e) {
            console.log('click e');
            console.log(e);
            scope.open_modal(scope.url);
          });
        }
      }
    }
  ])
//   Restrict allowed values
// E for Element name
// A for Attribute
// C for Class
// M for Comment

// ‘@’ – Text binding / one - way binding
// ‘=’ – Direct model binding / two - way binding
// ‘&’ – Behavior binding / Method binding

