angular.module('mineXplore')
    .controller('AdvancedSearchController',
        [
            "$scope",
            "$rootScope",
            "$uibModal",
            "Notification",
            "SweetAlert",
            "SubstanceGroup",
            "SubstanceCategory",
            "Search",
            "Substance",
            "Stage",
            "RelatedPeople",
            "Event",
            "Cities",
            function (
                $scope,
                $rootScope,
                $uibModal,
                Notification,
                SweetAlert,
                SubstanceGroup,
                SubstanceCategory,
                Search,
                Substance,
                Stage,
                RelatedPeople,
                Event,
                Cities
            ) {
                $scope.oneAtATime = true;
                $scope.process_state = [];
                $scope.state_list = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"];
                $scope.groups = [];
                $scope.filters = {
                    people: {
                        owner: null,
                        techinical: null,
                        legal: null
                    }
                };

                $scope.items = ['Item 1', 'Item 2', 'Item 3'];

                // Toggle Advanced Searchs
                $scope.showFilter = function () {
                    $rootScope.advanced_search_enabled = !$rootScope.advanced_search_enabled;
                }

                $scope.removeEmptyArrays = function (data) {
                    for (let i = 0; i <= 1; i++) {
                        for (var key in data) {
                            var item = data[key];
                            // see if this item is an array
                            if (Array.isArray(item)) {
                                // see if the array is empty
                                if (item.length == 0) {
                                    // remove this item from the parent object
                                    delete data[key];
                                }
                                // if this item is an object, then recurse into it
                                // to remove empty arrays in it too
                            } else if (item == undefined) {
                                delete data[key];
                            } else if (typeof item == "object") {
                                if (Object.keys(item).length == 0) {
                                    delete data[key]
                                }
                                $scope.removeEmptyArrays(item);
                            }
                        }
                    }
                }

                $scope.mountFilters = function (name_input) {
                    console.log('mountFilters');
                    let substance_filter = [];
                    let auxV = [];
                    let s_cat = [];
                    let p_s = [];
                    let s_group = [];
                    let data_new = {};
                    data_new.parameters = {
                        substances: {
                            substance_categories: [],
                            substance_groups: [],
                            substances: []
                        },
                        process: {
                            process_number: [],
                            stages: []
                        },
                        location: {
                            federations: [],
                            cities: [],
                        },
                        people: {
                            owner: {},
                            legal: {},
                            techinical: {}
                        },
                        status: {
                            event_ids: [],
                            event_date: {
                                initial_date: null,
                                final_date: null
                            }
                        }
                    };
                    // Substance Categories Filter
                    if ($scope.filters.process_substance_cat) {
                        angular.forEach($scope.filters.process_substance_cat, function (elem) {
                            data_new.parameters.substances.substance_categories.push(elem.id)
                        })
                    }
                    // Substance Group Filter
                    if ($scope.filters.substance_group) {
                        angular.forEach($scope.filters.substance_group, function (elem) {
                            data_new.parameters.substances.substance_groups.push(elem.id);
                        })
                    }
                    //Substance Filter
                    if ($scope.filters.process_substance) {
                        angular.forEach($scope.filters.process_substance, function (elem) {
                            data_new.parameters.substances.substances.push(elem.attributes.name)
                        })
                    }
                    // Location - Federation Filter
                    if ($scope.filters.federations) {
                        data_new.parameters.location = { federations: $scope.filters.federations };
                    }
                    // Location - Cities
                    if ($scope.filters.cities) {
                        angular.forEach($scope.filters.cities, function (elem) {
                            data_new.parameters.location.cities.push(elem.str_anm)
                        })
                    }
                    // People - Owner
                    if ($scope.filters.people.owner && $scope.filters.people.owner.length > 0) {
                        data_new.parameters.people.owner = $scope.filters.people.owner[0].nome;
                    }
                    // People Technical
                    if ($scope.filters.people.techinical && $scope.filters.people.techinical.length > 0) {
                        data_new.parameters.people.techinical = $scope.filters.people.techinical[0].nome;
                    }
                    // People - Legal
                    if ($scope.filters.people.legal && $scope.filters.people.legal.length > 0) {
                        data_new.parameters.people.legal = $scope.filters.people.legal[0].nome;
                    }
                    // Process - Number
                    if ($scope.filters.process_number_input) {
                        processos = $scope.filters.process_number_input.match(/((\d{6}|\d{3}\.\d{3})\/\d{4})+/gm);
                        data_new.parameters.process = { process_number: processos };
                    }
                    // Process - stage
                    if ($scope.filters.process_stages) {
                        if ($scope.filters.process_stages.length > 0) {
                            data_new.parameters.process.stages = [];
                            angular.forEach($scope.filters.process_stages, function (elem) {
                                data_new.parameters.process.stages.push(elem.id);
                            })
                        }

                    }
                    // Event - event_id
                    if ($scope.filters.events) {
                        angular.forEach($scope.filters.events, function (elem) {
                            data_new.parameters.status.event_ids.push(elem.id)
                        })
                    }
                    // Event - Date interval
                    if ($scope.filters.dt_ini && $scope.filters.dt_end) {
                        data_new.parameters.status.event_date.initial_date = format_date($scope.filters.dt_ini);
                        data_new.parameters.status.event_date.final_date = format_date($scope.filters.dt_end);
                    }

                    $scope.removeEmptyArrays(data_new.parameters);
                    return data_new;
                }

                var format_date = function (dt) {
                    return dt.getUTCFullYear() + "-" + (dt.getUTCMonth() + 1) + "-" + dt.getUTCDate();
                }
                $scope.eraseUserFilter = function (filter) {
                    SweetAlert.swal({
                        title: "Confirmação",
                        text: "Deseja excluir o Filtro '" + filter.name + "' ?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                        cancelButtonText: "Não",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    },
                        function (isConfirm) {
                            if (isConfirm) {

                                filter.$delete().then(
                                    function (result) {
                                        Notification.success('O Filtro ' + filter.name + ' foi excluído');
                                        $scope.searches_user.splice($scope.searches_user.findIndex(dt => dt.id == filter.id), 1);
                                    }
                                    , function (error) {
                                        Notification.error('Erro ao excluir o filtro ' + filter.name)
                                    }
                                )

                            } else {
                                console.log('cancelado');
                                Notification.info("Este processo continuará enviando alertas para você");
                            }
                        });


                }

                // Salve current input parameters into user filters
                $scope.saveFilters = function () {
                    var fn_error = function (error) {
                        console.log("error from save search ====", error);
                        var txt_error = error.data || "Erro ao salvar o filtro";
                        Notification.error(txt_error);
                        reject(txt_error);
                    }
                    SweetAlert.swal({
                        title: 'Nome do Novo Filtro:',
                        input: 'text',
                        showCancelButton: true,
                        confirmButtonText: 'Salvar',
                        cancelButtonText: 'Cancelar',
                        showLoaderOnConfirm: true,
                        preConfirm: function (name) {
                            return new Promise(function (resolve, reject) {
                                if (name === '') {
                                    reject('O Nome não pode ficar em branco')
                                    return false;
                                }
                                var data = $scope.mountFilters();
                                data.name = name;
                                Search.create(data, function (result) {
                                    result.$promise.then(
                                        function (response) {
                                            Notification.success('Filtro Inserido.');
                                            console.log('PARA PARA PARA');
                                            console.log(response);
                                            $scope.searches_user.push(response)
                                        },
                                        fn_error
                                    )
                                    resolve();
                                }, fn_error);
                            })
                        },
                        allowOutsideClick: false
                    })
                }
                $scope.load_user_filters = function () {
                    console.log('load user filters')
                    Search.query({},
                        function (data) { $scope.searches_user = data; },
                        function (error) {
                            console.log("\n\nerror from save search ====", error, "\n\n");
                        });
                }
                $scope.load_user_filters();
                $scope.applyFilters = function (data) {
                    console.log('applyFilters');
                    var data_new = (typeof data === "number") ? { parameters: $scope.searches_user[data].parameters } : $scope.mountFilters();
                    data_new.hide_filter = true;
                    $scope.$parent.fnSearch(null, data_new)
                }
                $scope.addItem = function () {
                    var newItemNo = $scope.items.length + 1;
                    $scope.items.push('Item ' + newItemNo);
                };

                // load filter parameters
                $scope.getDataFilter = function () {
                    console.log('getDataFilter')

                    SubstanceGroup.query({}, function (result) { $scope.substanceGroup = result.data; });

                    Substance.cache().$promise.then(function (result) { $scope.substances_list = result.data; })

                    SubstanceCategory.cache().$promise.then(function (result) { $scope.substance_categories_list = result.data; });

                    Stage.query({ page_size: 100 }, function (result) { $scope.stage_list = result.data; })

                    Event.query({ per_page: 100 }, function (result) { $scope.event_list = result.data; })
                }

                $scope.complete_owner = function (str) {
                    $scope.people_owner_list = RelatedPeople.query({ per_page: 25, name: str, relation: 'Titular\\\\Requerente' });
                }
                $scope.complete_technical = function (str) {
                    $scope.people_technical_list = RelatedPeople.query({ per_page: 25, name: str, relation: 'Responsável Técnico' });
                }
                $scope.complete_legal = function (str) {
                    $scope.people_legal_list = RelatedPeople.query({ per_page: 25, name: str, relation: 'Representante Legal' });
                }

                $scope.complete_event = function (str) {
                    Event.query({ per_page: 20, name: str }, function (result) { $scope.event_list = result.data });
                }
                $scope.complete_cities = function (str) {
                    Cities.query({ per_page: 10, name: str }, function (result) { $scope.city_list = result });
                }

                $scope.getDataFilter();
                $rootScope.$on("filterService", function (params, keys) {
                    $scope.status.showMenu = !$scope.status.showMenu;

                });

                $scope.status = {
                    open: false,
                    isCustomHeaderOpen: true,
                    isUserFilterOpen: false,
                    isFiltersOpen: true,
                    isFirstDisabled: false,
                    showMenu: false,
                    showProcessInfo: true
                };

                // event calendar

                $scope.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                };
                $scope.dt_ini = null;
                $scope.cal_dt_ini_opened = false;
                $scope.dt_end = null;
                $scope.cal_dt_end_opened = false;

                $scope.$watch('filters.dt_ini', function (val) {
                    console.log('breakpoint dt_ini');
                    console.log(val);
                    console.log($scope.cal_dt_ini_opened);
                    $scope.cal_dt_ini_opened = false;
                    //data_new.parameters.status.event_date.initial_date = val;
                });

                $scope.$watch('filters.dt_end', function (val) {
                    console.log('breakpoint dt_end');
                    console.log(val);
                    $scope.cal_dt_end_opened = false;
                    //data_new.parameters.status.event_date.final_date = val;
                });


                //
            }]);