angular.module('mineXplore.controllers.Main', [])

  .controller('MainController', ['$rootScope', '$scope', '$state', '$auth', 'Notification', '$location', 'Privileges', 'CustomMessage', function ($rootScope, $scope, $state, $auth, Notification, $location, Privileges, CustomMessage) {
    console.log('MainController init');

    CustomMessage.display_messages();

    $scope.isMap = function () {
      return $state.is("map");
    };
    $scope.isHome = function () {
      return $state.is("root");
    };
    $scope.isOtherPage = function () {
      return ($state.is("user_assinatura") || $state.is("user_profile") || $state.is("subscriptions"));
    }
    $scope.sidebar = { toggle: ($(window).width() > 450) };
    $scope.user = $rootScope.user;

    $scope.menuAction = function (cursor) {
      console.log('MainController.menuAction');
      if (cursor == 99) {
        $scope.showFilters();
      } else {
        //$rootScope.$emit('sideMenuService', { cursor: cursor });
        console.log('set $rootScope.sideMenuService=' + cursor)
        $rootScope.sideMenuService = cursor;
      }
    };

    // Enable Advanced Search
    $rootScope.filterService = {
      filters: false,
      enabled: false

    };
    $scope.advanced_search_enabled = false;
    $scope.showFilters = function () {
      $scope.advanced_search_enabled = !$scope.advanced_search_enabled;
    };

    $scope.showListProcess = function () {
      $scope.hasProcess = true;
    }
    $scope.$watch(
      function () {
        return $rootScope.grid_enabled;
      }, function () {
        $scope.grid_enabled = $rootScope.grid_enabled;
      });
    $scope.SignOut = function () {
      console.log('wtf?');
      $auth.signOut();
    }
    $scope.$on('auth:logout-success', function (ev, data) {
      console.log('signOut success - redirecting to landpage')
      Notification.success('Sessão encerrada com sucesso!');
      $state.go('root');
    });

    $scope.$on('auth:logout-error', function (ev, data) {
      console.log('signOut error - what can i do?')
      Notification.error("Falha ao efetuar logout")
    });

    $scope.$on('auth:login-success', function (ev, response) {
      console.log('auth:login-success - set $rootScope.user');
      $rootScope.user = response.attributes;
      if ($location != undefined && $location.url() != undefined && $location.url() != '/' && $location.url() != '/login') {
        console.log('going to ' + $location.url())
        $state.reload();
      } else {
        if (Privileges.check('minexplore.frontend.dashboard.enterprise', 'read')) {
          $state.go('global_dashboard');
        } else {
          $state.go('map');
        }
      }

    });
  }])

