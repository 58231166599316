angular.module('mineXplore')
  .factory("Dou", ['$resource', 'config', function ($resource, config) {
    var Dou = $resource(config.apiUrl + config.baseUrl + "/common/dou/:id.json",
      { id: "@id" },
      {
        get: { method: 'GET', isArray: false },
      }
    );
    return Dou;
  }
  ]);
