'use strict';
angular.module('mineXplore')
  .service('exportKML', ['olExporter' ,'Privileges', 'Notification', function (olExporter, Privileges, Notification) {
    function serialize(feature, filename) {
      var formatter = new ol.format.KML({
        'maxDepth': 10,
        'extractStyles': true,
      });
      if(Privileges.check('minexplore.frontend.map', 'exportKML')){
        olExporter.export(formatter, [feature], "data:text/xml;charset=utf-8,", filename)
      }else{
        Notification.info('Acesso negado. Seu plano não possui o privilégio para exportar KML');
      }

    }

    return {
      'export': function (feature, filename) {
        serialize(feature, filename)
      }
    };
  }
  ]);
