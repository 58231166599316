angular.module('mineXplore')
.factory("User", ['$resource', 'config', function($resource, config) {
  var User;
  var loged_user = null;
  User = $resource(config.apiUrl + config.baseUrl + "/users/:id.json", 
    { id: "@id" }, 
    {
      update: { method: "PUT" },
      destroy: { method: "DELETE" }, 
      save: {method: 'PATCH'},
      //create:{ method: "POST"},
      query: {method: 'GET', isArray: false }      
    }
    );
  User.prototype.getUser = function(){
    return loged_user;
  };
  User.prototype.setUser = function(u){
    loged_user = u;
    return true;
  }
  // User.prototype.fetchUser = function(u){
  //   User.query()
  // }
  return User;
}
]);
