/**
  User Seaches - Manage Seach parameters
*/
angular.module('mineXplore')
.factory("GlobalDou", ['$resource', 'config', function($resource, config) {
  var layers;
  layers = $resource(config.apiUrl + config.baseUrl + "/dashboard/global/dou/:id",
    { id: "@id" },
    {
      get: {method: 'GET', isArray: true, cache: true },
      query: {method: 'GET', isArray: false, cache: true }
    }
    );
  return layers;
}
]);
