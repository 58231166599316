angular.module("mineXplore")
    .directive('mapWidget', [function () {
        return {
            restrict: 'EA',
            scope: {
                processes: "@",
                data: "=",
                polygons: "=",
                searchEnabled: "=",
                legendEnabled: "@",
                filterEnabled: "=",
                gridEnabled: "=",
                drawingEnabled: "=",
                monitoringEnabled: "=",
                detailInfoBox: "=",
                autocenter: "=",
            },
            templateUrl: 'map/map.html',
            controller: 'MapController'
        };
    }]);
