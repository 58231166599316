'use strict';
angular.module('mineXplore')
 .controller('CalendarRange',
  ['$scope','$timeout', 'CalendarHelper', function($scope, $setTimeout, cal) {
   // calendar methods
   console.log('CalendarRange - Controller initialized');
   $scope.dateOptions = cal.calendarOpts;

   $scope.init = function(){
     // console.log('initilize calendar controller');
     $scope.cal_dt_end_opened = false;
     $scope.cal_dt_ini_opened = false;

     if(cal.dt_ini.get() == undefined){
        $scope.init_date =  new Date(moment().subtract(7, 'days').format("YYYY-MM-DD"));
        cal.dt_ini.set(new Date($scope.init_date));
     }else{
       $scope.init_date = cal.dt_ini.get()
     }
     if(cal.dt_end.get() == undefined){
        $scope.end_date = new Date(moment().add(1, 'year').format("YYYY-MM-DD"));
        cal.dt_end.set(new Date($scope.end_date));
     }else{
       $scope.end_date = cal.dt_end.get();
     }

     $scope.dt_ini = $scope.init_date;
     $scope.dt_end = $scope.end_date;
   }
   $scope.init();


   $scope.$watch('dt_ini', function (val) {
     cal.dt_ini.set(val);
     console.log('CalendarRange Controller dt_ini changed detected: ', val);
     $scope.$parent.load_data();
     $scope.cal_dt_ini_opened = false;
   });

   $scope.$watch('dt_end', function (val) {
     cal.dt_end.set(val);
     console.log('CalendarRange Controller dt_end changed dt_end: ', val);
     $scope.$parent.load_data();
     $scope.cal_dt_end_opened = false;
   });
   // end of calendar methods
  }]
 );