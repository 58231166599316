'use strict';
angular.module('mineXplore')
  .service('exportGPX', ['$resource', 'olExporter', function ($resource, olExporter) {
    function serialize(feature, filename) {
      var formatter = new ol.format.GPX();
      olExporter.export(formatter, [feature], "data:text/xml;charset=utf-8,", filename)
    }

    return {
      'export': function (feature, filename) {
        serialize(feature, filename)
      }
    };
  }
  ]);
