'use strict';
angular.module('mineXplore')
  .controller('ProcessDashboardController',
    ['$scope', '$uibModal', 'DashboardProcess', 'Process', 'ProcessDocument', 'Notification', function ($scope, $uibModal, Area, Process, Document, Notification) {
      //console.log('ProcessDashboardController');

      var load_data = function () {
        console.log($scope.number);
        console.log($scope.year);
        console.log($scope.title);
        console.log($scope.project_id);
        var prj_id = $scope.project_id || 0;
        Area.get({ project_id: prj_id, number: $scope.number, year: $scope.year, monitored_area_id: 0 },
          function (response) {
            console.log(response);
            console.log('--------------');
            $scope.details = response.data;

            Document.get({monitored_area_id: 0, project_id: prj_id, number: $scope.number, year: $scope.year}, function(response){
              console.log('monitored area documents loaded', response)
              $scope.documents = response.data;
            }, function(error){
              console.log('area project document load error:', error)
              Notification.error('area project document load error:', error.data.message);
            })

            Process.get({ id: response.data.id, project_id: prj_id },
              function (data) {
                console.log('Process GeoJson:')
                console.log(data);
                $scope.geojson = data;
              }, function (error) {
                console.log(error);
              }
            );
          },
          function (error) {
            console.log(error);
          })
      }
      var modalInstance;
      $scope.open_modal = function (url) {
        console.log(url);
        load_data()
        modalInstance = $uibModal.open({
          animation: true,
          backdrop: false,
          templateUrl: "dashboard/process/process-dashboard.html",
          scope: $scope
        });
        modalInstance.result.catch(function (res) {
          if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
            console.log(res);
            throw res;
          }
        }
        );
      }
      $scope.cancel = function () {
        modalInstance.close();
      }

    }
    ]
  )
  ;
