angular.module('mineXplore')
  .controller('NewProjectModalController', function ($scope, $uibModal, $uibModalInstance, data, Project, Process, SweetAlert, Notification) {
    console.log('iniciate Controller: ');
    $scope.projects = [{}];
    $scope.project = {};
    $scope.model = {};
    $scope.list = ($(window).width() >= 600 && $(window).width() < 1200);
    $scope.mobile = ($(window).width() < 1200);
    $scope.loading = false;

    $(window).resize(function () {
      $scope.$apply(function () {
        $scope.mobile = ($(window).width() < 1200);
      });
      $scope.$apply(function () {
        $scope.list = ($(window).width() >= 600 && $(window).width() < 1200);
      });
    });

    if (data) {
      $scope.project = data;
    }

    var msg_success = {
      title: "Sucesso!",
      text: "Seu projeto foi criado com sucesso, e você já pode adicionar processos",
      type: "success"
    }
    var msg_error_monitoring = {
      title: "Ops!",
      text: "Ocorreu um erro ao tentar criar um projeto",
      type: "error"
    }
    $scope.uploadImage = function (event) {
      if (event.files && event.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $('#image-preview').attr('src', e.target.result);
        }
        reader.readAsDataURL(event.files[0]);
        $scope.project.picture = reader.result;
      }
    };
    $scope.createProject = function () {
      console.log('addProject');
      if ($scope.project.data.attributes.name === undefined) {
        Notification.info("Nome do projeto nao pode ficar em branco!");
      } else {
        return Project.save($scope.project.data,
          function (result) {
            Notification.success("Projeto criado com sucesso");
            $scope.project.data.attributes.name = "";
            $scope.project.data.attributes.description = "";
            $uibModalInstance.close(result.data);

          },
          function (error) {
            msg_error = error.data.message || "Erro ao criar projeto"
            Notification.error(msg_error);
          });
      }
    };
    $scope.cancel = function () {
      $uibModalInstance.close();
    };
    $scope.update_data = function () {
      console.log('update project');
      Project.update({ id: this.project.id }, $scope.project,
        function (project) {
          console.log(project);
        }, function (error) {
          console.log('update error');
          console.log(error);
          msg_error = error.data.message || "Ocorreu uma falha ao atualizar o projeto.";
          Notification.error(msg_error);
        }
      );
    };
    $scope.destroyProcess = function (index) {
      console.log('removeArea');

      var area = $scope.project.attributes.processes[index];
      console.log(area, index)
      if (area.id) {
        $scope.project.attributes.processes.splice(index, 1);
        area._destroy = true;
        console.log(area, index)
      } else {
        $scope.project.attributes.processes.splice(index, 1);
      }
      $scope.update_data();
    }

    $scope.openProcess = function (id) {
      $scope.loading = true;
      var modalInstance = $uibModal.open({
        templateUrl: 'process/modal.html',
        controller: 'ProcessController',
        resolve: {
          data: function () { return id; }
        }
      });
      modalInstance.result.then(function () {
      })
    };
    $scope.save = function () {
      $scope.createProject();
    }
  });