/**
  User Buffers - Manage Seach parameters
*/
angular.module('mineXplore')
.factory("ProjectMembers", ['$resource', 'config', function($resource, config) {
  var resource;
  resource = $resource(config.apiUrl + config.baseUrl + "/user/projects/:project_id/members/:id.json",
    { id: "@id", project_id: "@project_id" },
    {
      update: {method: "PUT"},
      destroy: {method: "DELETE"},
      //save: {method: 'PUT'},
      create:{method: "POST"},
      fetch: {method: 'GET', isArray: true },
      get: {method: 'GET', isArray: false },
      availables: {
        method: 'GET', isArray: false,
        url: config.apiUrl + config.baseUrl + "/user/projects/:project_id/availables_members"
      }
    }
    );
  return resource;
}
]);
