angular.module("mineXplore")
  .directive('cardDocument', [function () {
    return {
      restrict: 'EA',
      //scope: true,
      scope: {
        document: "=",
      },
      //bindToController: true,
      templateUrl: 'dashboard/common/card-document.html',
      controller: ['$scope',
        function ($scope) {

        }
      ]
    };
  }]);

//   Restrict allowed values
// E for Element name
// A for Attribute
// C for Class
// M for Comment

// = is two - way binding
// @ simply reads the value(one - way binding)
// & is used to bind functions